import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Input, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { components, paths } from '../../api/schema';
import { getUsersClient } from '../../api/userClient';
import DataTable, {
  ColumnsType,
  DataTableFilterCallback,
  DataTableOrderCallback,
  serializeFilters,
  serializeOrders,
} from '../../shared/dataTable/DataTable';
import useDebounce from '../../shared/hooks/useDebounce';
import PanelSkeleton from '../../shared/skeletons/PanelSkeleton';
import CreateUserClient from './CreateUserClient';
import UserClientDetail from './UserClientDetail';
import { DeleteUserClient, EditUserClient, ResetPasswordClient } from './userPanelActions';

const usersTableColumns: ColumnsType<components['schemas']['UserClientCondensed']> = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    columnType: 'text',
  },
  {
    title: 'Correo',
    dataIndex: 'email',
    key: 'email',
    columnType: 'text',
  },
  {
    title: 'Cliente',
    dataIndex: 'client',
    key: 'client',
    columnType: 'text',
  },
];
const PAGE_SIZE = 50;

export default function UserClientPanel() {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>();
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/user-client/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { isLoading, isError, data, isFetching } = useQuery({
    queryKey: ['getUsersClient', params],
    queryFn: () => getUsersClient(params),
  });

  useEffect(() => {
    setParams((state) => ({ ...state, search: debouncedSearch }));
  }, [debouncedSearch]);

  const handleFiltering = (filter: DataTableFilterCallback) => {
    setParams((state) => ({ ...state, filter: serializeFilters(params.filter, filter) }));
  };

  const handleOrdering = (order: DataTableOrderCallback) => {
    setParams((state) => ({ ...state, order: serializeOrders(params.order, order) }));
  };

  const handlePageChange = (page: number) => {
    setParams((state) => ({ ...state, page }));
  };

  return (
    <Row>
      <Col span={24}>
        <Space size={'middle'}>
          <Button type="primary" onClick={() => setCreateModalOpen(true)}>
            Crear usuario cliente
          </Button>
          <Input
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Space>
      </Col>
      {isLoading || isError || isFetching ? (
        <PanelSkeleton />
      ) : (
        <>
          <Col span={24} style={{ marginTop: '10px' }}>
            <DataTable<components['schemas']['UserClientCondensed']>
              handleFilters={handleFiltering}
              handleOrder={handleOrdering}
              handlePageChange={handlePageChange}
              columns={usersTableColumns}
              results={data.results}
              activeFilters={params.filter}
              activeOrders={params.order}
              paginationData={{
                pageSize: PAGE_SIZE,
                count: data.count,
                currentPage: data.currentPage,
              }}
              actions={[EditUserClient, DeleteUserClient, ResetPasswordClient]}
              onRowClick={(record) => {
                setSelectedUserId(record.id);
                setDetailModalOpen(true);
              }}
            />
          </Col>

          <UserClientDetail userId={selectedUserId} open={detailModalOpen} onCancel={() => setDetailModalOpen(false)} />
          <CreateUserClient
            open={createModalOpen}
            onCancel={() => setCreateModalOpen(false)}
            onCreate={() => setCreateModalOpen(false)}
          />
        </>
      )}
    </Row>
  );
}
