import { useQuery } from '@tanstack/react-query';
import { Select, Skeleton } from 'antd';
import { getZones } from '../../api';
import { useState } from 'react';
import { paths } from '../../api/schema';

interface MultiZoneSelectProps {
  value?: string;
  onChange?: (value: string) => void;
  onChangeByLabel?: (label: string) => void;
}
const PAGE_SIZE = 100;
export default function MultiZoneSelect({ value, onChange, onChangeByLabel }: MultiZoneSelectProps) {
  const [paramsZone] = useState<paths['/zone/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { isLoading, isError, data } = useQuery({
    queryKey: ['getZones', paramsZone],
    queryFn: () => getZones(paramsZone),
    staleTime: 10000, // We won't be changing roles that often so we can make this stale until a while after to prevent extra queries
  });

  const localOnChange = (
    value: string,
    option: { value: string; label: string } | Array<{ value: string; label: string }>,
  ) => {
    onChange?.(value);
    // if (Array.isArray(option)) {
    //   onChangeByLabel?.(option);
    // }
  };

  if (isLoading) return <Skeleton.Input active size="small" />;
  if (isError) return <Select status="error" style={{ width: '100%' }} />;

  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      options={data.results.map((zone) => ({ value: zone.id, label: zone.name }))}
      onChange={localOnChange}
      value={value}
    />
  );
}
