import { useMutation } from '@tanstack/react-query';
import { App, Button, Col, Drawer, Form, Input, Row, Space } from 'antd';
import { createContact } from '../../api';
import { components } from '../../api/schema';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';

interface CreateContactProps {
  clientId?: string;
  open: boolean;
  onCreate?: (contact: components['schemas']['Contact']) => void;
  onCancel: () => void;
}

export default function CreateContactDrawer({ open, clientId, onCreate, onCancel }: CreateContactProps) {
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreateClientContact']) => createContact(clientId ?? '', body),
    onSuccess: (data) => {
      // queryClient.invalidateQueries({ queryKey: ['getClient'] });
      message.success('Contacto creado correctamente');
      onCreate?.(data);
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  const [form] = Form.useForm<components['schemas']['CreateClientContact']>();

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate(values);
    } catch (e) {}
  };

  return (
    <Drawer
      open={open}
      title="Crear contacto"
      style={{ top: 20 }}
      width={800}
      destroyOnClose
      maskClosable={false}
      closable={false}
      extra={
        <Space>
          <Button
            key="back"
            onClick={() => {
              onCancel();
            }}
          >
            Cancelar
          </Button>
          <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
            Guardar
          </Button>
        </Space>
      }
    >
      <Form form={form} layout="vertical" name="create-contact-form" scrollToFirstError autoComplete="off">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={'name'}
              label="Nombre"
              rules={[{ required: true, message: 'Ingresa el nombre del contacto' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={'phoneNumber'}
              label="Teléfono"
              rules={[{ required: true, message: 'Ingresa el teléfono' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
