import { Skeleton } from 'antd';

export default function PanelSkeleton() {
  return (
    <>
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </>
  );
}
