import axios, { purgeQueryParams } from './axios';
import { components, paths } from './schema';

export const getUsers = async (
  params: paths['/user/list']['get']['parameters']['query'],
): Promise<components['schemas']['UserListResponse']['data']> => {
  const data = await axios.get<components['schemas']['UserListResponse']>(
    `/user/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const createUser = async (
  body: components['schemas']['CreateUserRequest'],
): Promise<components['schemas']['CreateUserResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateUserResponse']>(`/user`, body);
  return data.data.data;
};

export const deleteUser = async (userId: string): Promise<components['schemas']['DeleteUserResponse']['data']> => {
  const data = await axios.delete<components['schemas']['DeleteUserResponse']>(`/user/${userId}`);
  return data.data.data;
};

export const getUser = async (userId?: string): Promise<components['schemas']['GetUserResponse']['data']> => {
  if (!userId) return await Promise.reject(new Error());
  const data = await axios.get<components['schemas']['GetUserResponse']>(`/user/${userId}`);
  return data.data.data;
};

export const editUser = async (
  userId: string,
  body: components['schemas']['EditUserRequest'],
): Promise<components['schemas']['EditUserResponse']['data']> => {
  const data = await axios.put<components['schemas']['EditUserResponse']>(`/user/${userId}`, body);
  return data.data.data;
};

export const getAreas = async (): Promise<components['schemas']['GetAreasResponse']['data']> => {
  const data = await axios.get<components['schemas']['GetAreasResponse']>(`/areas`);
  return data.data.data;
};

export const getRoles = async (): Promise<components['schemas']['GetRolesResponse']['data']> => {
  const data = await axios.get<components['schemas']['GetRolesResponse']>(`/roles`);
  return data.data.data;
};

export const resetPassword = async (
  userId: string,
): Promise<components['schemas']['ResetPasswordResponse']['data']> => {
  const data = await axios.post<components['schemas']['ResetPasswordResponse']>(`/user/${userId}/reset-password`);
  return data.data.data;
};
