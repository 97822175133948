import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Input, InputNumber, Modal, Radio } from 'antd';
import { createZone } from '../../api';
import { components } from '../../api/schema';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';

interface CreateZoneProps {
  open: boolean;
  onCreate?: () => void;
  onCancel: () => void;
}
export default function CreateZone({ open, onCreate, onCancel }: CreateZoneProps) {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreateZoneRequest']) => createZone(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getZones'] });
      message.success('Zona creada correctamente');
      onCreate?.();
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });
  const [form] = Form.useForm<components['schemas']['CreateZoneRequest']>();

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate(values);
    } catch (e) {}
  };

  return (
    <Modal
      open={open}
      title="Crear zona"
      style={{ top: 20 }}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
          Guardar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="create-zone-form" scrollToFirstError>
        <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre de la zona' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="zoneType"
          label="Tipo de zona"
          rules={[{ required: true, message: 'Selecciona el tipo de zona' }]}
        >
          <Radio.Group>
            <Radio value="FOREIGN">Foránea</Radio>
            <Radio value="LOCAL">Local</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="distance"
          label="Distancia máxima en km"
          rules={[{ required: true, message: 'Ingresa la distancia máxima en km', type: 'number' }]}
        >
          <InputNumber style={{ width: '100%' }} min={0} />
        </Form.Item>
        <Form.Item
          name="pickupDeliveryPrice"
          label="Precio recoleccion o entrega"
          rules={[{ required: true, message: 'Ingresa el precio de recoleccion o entrega', type: 'number' }]}
        >
          <InputNumber style={{ width: '100%' }} min={0} />
        </Form.Item>
        <Form.Item
          name="pickupBefore"
          label="Hora máxima para recoger (formato 0-23 horas)"
          rules={[
            { required: false, message: 'Ingresa la hora máxima para recoger (formato 24 horas)', type: 'number' },
          ]}
        >
          <InputNumber style={{ width: '100%' }} min={0} max={23} />
        </Form.Item>
        <Form.Item
          name="daysAvailable"
          label="Leyenda a mostrar"
          rules={[
            {
              required: false,
              message: 'Ingresa la leyenda a mostrar cuando seleccionen un c.p. con esta zona',
              type: 'string',
            },
          ]}
        >
          <Input placeholder="l m m j v s" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
