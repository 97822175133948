import { Checkbox, Space, Typography } from 'antd';

interface ReadonlyCheckboxProps {
  label: string;
  checked: boolean;
  checkboxText: string;
  removeBoldLabel?: boolean;
}
export default function ReadonlyCheckbox({ label, checked, removeBoldLabel, checkboxText }: ReadonlyCheckboxProps) {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Text strong={!removeBoldLabel}>{label}</Typography.Text>
      <Checkbox
        className="readonly-checkbox"
        checked={checked}
        disabled
        style={{ background: 'transparent', color: '#000' }}
      >
        {checkboxText}
      </Checkbox>
    </Space>
  );
}
