import { useQuery } from '@tanstack/react-query';
import { getRoles } from '../../api';
import { Select, Skeleton } from 'antd';

interface SelectRoleProps {
  value?: string;
  onChange?: (value: string) => void;
  onChangeByLabel?: (label: string) => void;
}
export default function SelectRole({ value, onChange, onChangeByLabel }: SelectRoleProps) {
  const { isLoading, isError, data } = useQuery({
    queryKey: ['getRoles'],
    queryFn: () => getRoles(),
    staleTime: 10000, // We won't be changing roles that often so we can make this stale until a while after to prevent extra queries
  });

  const localOnChange = (
    value: string,
    option: { value: string; label: string } | Array<{ value: string; label: string }>,
  ) => {
    onChange?.(value);
    if (!Array.isArray(option)) {
      onChangeByLabel?.(option.label);
    }
  };

  if (isLoading) return <Skeleton.Input active size="small" />;
  if (isError) return <Select status="error" style={{ width: '100%' }} />;

  return (
    <Select
      style={{ width: '100%' }}
      options={data.map((role) => ({ value: role.id, label: role.role }))}
      onChange={localOnChange}
      value={value}
    />
  );
}
