import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Col, Input, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { getVehiclesAtService } from '../../api';
import { components, paths } from '../../api/schema';
import DataTable, {
  ColumnsType,
  DataTableFilterCallback,
  DataTableOrderCallback,
  serializeFilters,
  serializeOrders,
} from '../../shared/dataTable/DataTable';
import useDebounce from '../../shared/hooks/useDebounce';
import PanelSkeleton from '../../shared/skeletons/PanelSkeleton';
import EditVehicleAtService from './EditVehicleAtService';

const vehiclesInServiceTableColumns: ColumnsType<components['schemas']['VehicleAtServiceCondensed']> = [
  {
    title: 'Conductor',
    dataIndex: 'driver',
    key: 'driver',
    columnType: 'text',
  },
  {
    title: 'Vehículo',
    dataIndex: 'vehicle',
    key: 'vehicle',
    columnType: 'text',
  },
];
const PAGE_SIZE = 50;

export default function VehicleAtServicePanel() {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/vehicle-at-service/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
    showOnlyWithVehicle: false,
  });
  const { isLoading, isError, data, isFetching } = useQuery({
    queryKey: ['getVehiclesAtService', params],
    queryFn: () => getVehiclesAtService(params),
  });

  useEffect(() => {
    setParams((state) => ({ ...state, search: debouncedSearch }));
  }, [debouncedSearch]);

  const handleFiltering = (filter: DataTableFilterCallback) => {
    setParams((state) => ({ ...state, filter: serializeFilters(params.filter, filter) }));
  };

  const handleOrdering = (order: DataTableOrderCallback) => {
    setParams((state) => ({ ...state, order: serializeOrders(params.order, order) }));
  };

  const handlePageChange = (page: number) => {
    setParams((state) => ({ ...state, page }));
  };

  return (
    <Row>
      <Col span={24}>
        <Space size={'middle'}>
          <Input
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Space>
      </Col>
      {isLoading || isError || isFetching ? (
        <PanelSkeleton />
      ) : (
        <>
          <Col span={24} style={{ marginTop: '10px' }}>
            <DataTable<components['schemas']['VehicleAtServiceCondensed']>
              handleFilters={handleFiltering}
              handleOrder={handleOrdering}
              handlePageChange={handlePageChange}
              columns={vehiclesInServiceTableColumns}
              results={data.results}
              activeFilters={params.filter}
              activeOrders={params.order}
              paginationData={{
                pageSize: PAGE_SIZE,
                count: data.count,
                currentPage: data.currentPage,
              }}
              actions={[EditVehicleAtService]}
            />
          </Col>
        </>
      )}
    </Row>
  );
}
