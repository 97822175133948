import { useQuery } from '@tanstack/react-query';
import { Button, Col, Divider, Modal, Row, Spin } from 'antd';
import { getVehicle } from '../../api';
import ReadonlyInput from '../../shared/form/ReadonlyInput';

interface VehicleDetailProps {
  vehicleId?: string | undefined;
  open: boolean;
  onCancel: () => void;
}

export default function VehicleDetail({ vehicleId, open, onCancel }: VehicleDetailProps) {
  const { data, remove } = useQuery({
    queryKey: ['getVehicle', vehicleId],
    queryFn: () => getVehicle(vehicleId),
    enabled: vehicleId !== undefined && open,
  });

  const handleCancel = () => {
    remove();
    onCancel();
  };

  return (
    <Modal
      open={open}
      title="Vista del vehículo"
      style={{ top: 20 }}
      destroyOnClose
      width={800}
      maskClosable
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
      ]}
    >
      {!data ? (
        <Spin size="large" />
      ) : (
        <>
          <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
            <Col span={12}>
              <ReadonlyInput value={data.name} label="Nombre del vehículo" />
            </Col>
            <Col span={12}>
              <ReadonlyInput value={data.vehicleConfig.description} label="Configuración vehícular" />
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
            <Col span={8}>
              <ReadonlyInput value={data.plate} label="Placa" />
            </Col>
            <Col span={8}>
              <ReadonlyInput value={data.year} label="Año" />
            </Col>
            <Col span={8}>
              <ReadonlyInput value={data.pesoBruto.toString()} label="Peso Bruto (toneladas)" />
            </Col>
          </Row>
          <Divider>Responsabilidad Civíl</Divider>
          <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
            <Col span={12}>
              <ReadonlyInput value={data.respCivilInsurer} label="Nombre de la aseguradora" />
            </Col>
            <Col span={12}>
              <ReadonlyInput value={data.respCivilPolicyNumber} label="Número de póliza" />
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
}
