import axios, { purgeQueryParams } from './axios';
import { components, paths } from './schema';

export const getClients = async (
  params: paths['/client/list']['get']['parameters']['query'],
): Promise<components['schemas']['ClientListResponse']['data']> => {
  const data = await axios.get<components['schemas']['ClientListResponse']>(
    `/client/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const createClient = async (
  body: components['schemas']['CreateClientRequest'],
): Promise<components['schemas']['CreateClientResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateClientResponse']>(`/client`, body);
  return data.data.data;
};

export const getClient = async (clientId?: string): Promise<components['schemas']['GetClientResponse']['data']> => {
  if (!clientId) return await Promise.reject(new Error());
  const data = await axios.get<components['schemas']['GetClientResponse']>(`/client/${clientId}`);
  return data.data.data;
};

export const getDefaultsPubGeneral = async (): Promise<
  components['schemas']['GetDefaultsPublicoGeneralResponse']['data']
> => {
  const data = await axios.get<components['schemas']['GetDefaultsPublicoGeneralResponse']>(
    `/client/defaults-for-publico-general`,
  );
  return data.data.data;
};

export const editClient = async (
  clientId: string,
  body: components['schemas']['EditClientRequest'],
): Promise<components['schemas']['EditClientResponse']['data']> => {
  const data = await axios.put<components['schemas']['EditClientResponse']>(`/client/${clientId}`, body);
  return data.data.data;
};

export const createAddress = async (
  clientId: string,
  body: components['schemas']['CreateAddress'],
): Promise<components['schemas']['CreateAddressResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateAddressResponse']>(
    `/client/${clientId}/create-address`,
    body,
  );
  return data.data.data;
};

export const createContact = async (
  clientId: string,
  body: components['schemas']['CreateClientContact'],
): Promise<components['schemas']['CreateContactResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateContactResponse']>(
    `/client/${clientId}/create-contact`,
    body,
  );
  return data.data.data;
};

export const deleteClient = async (
  clientId: string,
): Promise<components['schemas']['DeleteClientResponse']['data']> => {
  const data = await axios.delete<components['schemas']['DeleteClientResponse']>(`/client/${clientId}`);
  return data.data.data;
};
