import { ClockCircleOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { updateScheduledDate } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';
import { DatePicker } from '../../../shared/dateComponents';

export default function EditPickupDateModal({
  record,
}: DataTableActionProps<components['schemas']['PickupCondensed']>) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['UpdateScheduledDateRequest']) => updateScheduledDate(record.id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getShipmentPickupList'] });
      message.success('Fecha de recolección cambiada correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  const handleButtonClick = async () => {
    try {
      const { scheduledPickupDate } = await form.validateFields();
      mutation.mutate({
        scheduledPickupDate,
      });
    } catch (e) {}
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        title="Editar fecha recolección"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button type="primary" key="submit" loading={mutation.isLoading} onClick={handleButtonClick}>
            Guardar
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          name="edit-pickup-date"
          scrollToFirstError
          initialValues={{
            scheduledPickupDate: moment(record.scheduledPickupDate, 'DD/MM/YYYY'),
          }}
        >
          <Form.Item
            name="scheduledPickupDate"
            label="Fecha programada de recolección"
            rules={[{ required: true, message: 'Selecciona una fecha' }]}
          >
            <DatePicker
              allowClear={false}
              format="DD/MM/YYYY"
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, 'day')) || current.weekday() === 0 || current.weekday() === 6
              }
            />
          </Form.Item>
        </Form>
      </Modal>

      {record.status === 'Programado' && <ClockCircleOutlined disabled={true} onClick={() => setOpen(true)} />}
    </React.Fragment>
  );
}
