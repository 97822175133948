import axios, { purgeQueryParams } from './axios';
import { components, paths } from './schema';

export const createShipment = async (
  body: components['schemas']['CreateShipmentRequest'],
): Promise<components['schemas']['CreateShipmentResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateShipmentResponse']>(`/shipment`, body);
  return data.data.data;
};

export const getShipmentPickupList = async (
  params: paths['/shipment/pickup/list']['get']['parameters']['query'],
): Promise<components['schemas']['PickupListResponse']['data']> => {
  const data = await axios.get<components['schemas']['PickupListResponse']>(
    `/shipment/pickup/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const getShipingLabel = async (
  shipmentId: string,
): Promise<components['schemas']['GetShipmentLabelResponse']['data']> => {
  const data = await axios.get<components['schemas']['GetShipmentLabelResponse']>(
    `/shipment/${shipmentId}/create-label`,
  );
  return data.data.data;
};

export const getWarehouseList = async (
  params: paths['/shipment/warehouse/list']['get']['parameters']['query'],
): Promise<components['schemas']['WarehouseListResponse']['data']> => {
  const data = await axios.get<components['schemas']['WarehouseListResponse']>(
    `/shipment/warehouse/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const changeZone = async (
  shipmentPickupId: string,
  body: components['schemas']['ChangeZoneRequest'],
): Promise<components['schemas']['ChangeZoneResponse']['data']> => {
  const data = await axios.put<components['schemas']['ChangeZoneResponse']>(
    `/shipment/pickup/${shipmentPickupId}/change-zone`,
    body,
  );
  return data.data.data;
};

export const changePrice = async (
  shipmentId: string,
  body: components['schemas']['ChangePriceRequest'],
): Promise<components['schemas']['ChangePriceResponse']['data']> => {
  const data = await axios.put<components['schemas']['ChangePriceResponse']>(
    `/shipment/${shipmentId}/change-price`,
    body,
  );
  return data.data.data;
};

export const changeReadyToDeliver = async (
  shipmentWarehouseId: string,
): Promise<components['schemas']['ChangeReadyToDeliverResponse']['data']> => {
  const data = await axios.put<components['schemas']['ChangeReadyToDeliverResponse']>(
    `/shipment/warehouse/${shipmentWarehouseId}/change-status-ready-to-deliver`,
  );
  return data.data.data;
};

export const changeNotReadyToDeliver = async (
  shipmentWarehouseId: string,
  body: components['schemas']['ChangeNotReadyToDeliverRequest'],
): Promise<components['schemas']['ChangeReadyToDeliverResponse']['data']> => {
  const data = await axios.put<components['schemas']['ChangeReadyToDeliverResponse']>(
    `/shipment/warehouse/${shipmentWarehouseId}/change-status-not-ready-to-deliver`,
    body,
  );
  return data.data.data;
};

export const getShipmentInTransitList = async (
  params: paths['/shipment/in-transit/list']['get']['parameters']['query'],
): Promise<components['schemas']['InTransitResponse']['data']> => {
  const data = await axios.get<components['schemas']['InTransitResponse']>(
    `/shipment/in-transit/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const getShipmentDeliveredList = async (
  params: paths['/shipment/delivered/list']['get']['parameters']['query'],
): Promise<components['schemas']['DeliveredResponse']['data']> => {
  const data = await axios.get<components['schemas']['DeliveredResponse']>(
    `/shipment/delivered/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const getShipmentDetails = async (
  trackingNumber?: string,
): Promise<components['schemas']['GetShipmentDetailsResponse']['data']> => {
  if (!trackingNumber) return await Promise.reject(new Error());
  const data = await axios.get<components['schemas']['GetShipmentDetailsResponse']>(
    `/shipment/${trackingNumber}/details`,
  );
  return data.data.data;
};

export const getDefaultsSAT = async (): Promise<components['schemas']['GetDefaultsSATResponse']['data']> => {
  const data = await axios.get<components['schemas']['GetDefaultsSATResponse']>(`/shipment/defaults-for-sat`);
  return data.data.data;
};

export const returnStatus = async (
  shipmentInTransitId: string,
): Promise<components['schemas']['ReturnStatusResponse']['data']> => {
  const data = await axios.put<components['schemas']['ReturnStatusResponse']>(
    `/shipment/in-transit/${shipmentInTransitId}/return-status`,
  );
  return data.data.data;
};

export const deleteService = async (
  shipmenId: string,
): Promise<components['schemas']['DeleteShipmentResponse']['data']> => {
  const data = await axios.delete<components['schemas']['DeleteShipmentResponse']>(`/shipment/${shipmenId}`);
  return data.data.data;
};

export const updateScheduledDate = async (
  shipmentPickupId: string,
  body: components['schemas']['UpdateScheduledDateRequest'],
): Promise<components['schemas']['UpdateScheduledDateResponse']['data']> => {
  const data = await axios.put<components['schemas']['UpdateScheduledDateResponse']>(
    `/shipment/pickup/${shipmentPickupId}/update-scheduled-date`,
    body,
  );
  return data.data.data;
};
