import axios, { purgeQueryParams } from './axios';
import { components, paths } from './schema';

export const createVehicle = async (
  body: components['schemas']['CreateVehicleRequest'],
): Promise<components['schemas']['CreateVehicleResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateVehicleResponse']>(`/vehicle`, body);
  return data.data.data;
};

export const getVehicle = async (vehicleId?: string): Promise<components['schemas']['GetVehicleResponse']['data']> => {
  if (!vehicleId) return await Promise.reject(new Error());
  const data = await axios.get<components['schemas']['GetVehicleResponse']>(`/vehicle/${vehicleId}`);
  return data.data.data;
};

export const deleteVehicle = async (
  vehicleId: string,
): Promise<components['schemas']['DeleteVehicleResponse']['data']> => {
  const data = await axios.delete<components['schemas']['DeleteVehicleResponse']>(`/vehicle/${vehicleId}`);
  return data.data.data;
};

export const editVehicle = async (
  vehicleId: string,
  body: components['schemas']['EditVehicleRequest'],
): Promise<components['schemas']['EditVehicleResponse']['data']> => {
  const data = await axios.put<components['schemas']['EditVehicleResponse']>(`/vehicle/${vehicleId}`, body);
  return data.data.data;
};

export const getVehicles = async (
  params: paths['/vehicle/list']['get']['parameters']['query'],
): Promise<components['schemas']['VehicleListResponse']['data']> => {
  const data = await axios.get<components['schemas']['VehicleListResponse']>(
    `/vehicle/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const getAvailableVehicles = async (): Promise<
  components['schemas']['GetAvailableVehiclesResponse']['data']
> => {
  const data = await axios.get<components['schemas']['GetAvailableVehiclesResponse']>(
    `/vehicle-at-service/available-vehicle/list`,
  );
  return data.data.data;
};

export const reactivateVehicle = async (
  vehicleId: string,
): Promise<components['schemas']['ReactivateVehicleResponse']['data']> => {
  const data = await axios.post<components['schemas']['ReactivateVehicleResponse']>(`/vehicle/${vehicleId}/reactivate`);
  return data.data.data;
};

export const editVehicleAtService = async (
  body: components['schemas']['EditVehicleAtServiceRequest'],
): Promise<components['schemas']['EditVehicleAtServiceResponse']['data']> => {
  const data = await axios.put<components['schemas']['EditVehicleAtServiceResponse']>(`/vehicle-at-service`, body);
  return data.data.data;
};

export const getVehiclesAtService = async (
  params: paths['/vehicle-at-service/list']['get']['parameters']['query'],
): Promise<components['schemas']['GetVehicleAtServiceResponse']['data']> => {
  const data = await axios.get<components['schemas']['GetVehicleAtServiceResponse']>(
    `/vehicle-at-service/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const getDriverList = async (): Promise<components['schemas']['GetDriversResponse']['data']> => {
  const data = await axios.get<components['schemas']['GetDriversResponse']>(`/vehicle-at-service/driver/list`);
  return data.data.data;
};
