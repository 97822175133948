import { useQuery } from '@tanstack/react-query';
import { Button, Col, Modal, Row, Spin } from 'antd';
import { getPrepaid } from '../../api';
import ReadonlyInput from '../../shared/form/ReadonlyInput';

interface PrepaidDetailProps {
  prepaidId?: string | undefined;
  open: boolean;
  onCancel: () => void;
}

export default function PrepaidDetail({ prepaidId, open, onCancel }: PrepaidDetailProps) {
  const { data, remove } = useQuery({
    queryKey: ['getPrepaid', prepaidId],
    queryFn: () => getPrepaid(prepaidId),
    enabled: prepaidId !== undefined && open,
  });

  const handleCancel = () => {
    remove();
    onCancel();
  };

  return (
    <Modal
      open={open}
      title="Vista de guias prepagadas"
      style={{ top: 20 }}
      destroyOnClose
      width={800}
      maskClosable
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
      ]}
    >
      {!data ? (
        <Spin size="large" />
      ) : (
        <>
          <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
            <Col span={12}>
              <ReadonlyInput value={data.id} label="ID" />
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
}
