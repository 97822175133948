import { InteractionOutlined } from '@ant-design/icons';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Modal, Row, Select, Spin } from 'antd';
import React, { useState } from 'react';
import { changeZone, getZones } from '../../../api';
import { components, paths } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';

const PAGE_SIZE = 100;
export default function EditZoneModal({ record }: DataTableActionProps<components['schemas']['PickupCondensed']>) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const [paramsZone] = useState<paths['/zone/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { data, remove } = useQuery({
    queryKey: ['getZones'],
    queryFn: () => getZones(paramsZone),
    enabled: record.id !== undefined && open,
  });
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['ChangeZoneRequest']) => changeZone(record.id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getShipmentPickupList'] });
      message.success('Zona cambiada correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  const handleButtonClick = async () => {
    try {
      const { zoneId } = await form.validateFields();
      mutation.mutate({
        zoneId,
      });
    } catch (e) {}
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
    remove();
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        title="Editar zona"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button type="primary" key="submit" loading={mutation.isLoading} onClick={handleButtonClick}>
            Guardar
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
        ]}
      >
        {data ? (
          <Form
            form={form}
            layout="vertical"
            name="edit-zone"
            scrollToFirstError
            initialValues={{
              zoneId: record.pickupZoneId,
            }}
          >
            <Form.Item name="zoneId" label="Zona" rules={[{ required: true, message: 'Selecciona una zona' }]}>
              <Select
                style={{ width: '100%' }}
                options={data.results.map((zone) => ({ value: zone.id, label: zone.name }))}
              />
            </Form.Item>
          </Form>
        ) : (
          <Row justify="center">
            <Spin />
          </Row>
        )}
      </Modal>
      <InteractionOutlined onClick={() => setOpen(true)} />
    </React.Fragment>
  );
}
