import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Col, Divider, Form, Modal, Row, Tag } from 'antd';
import { createPayoff } from '../../api';
import { components } from '../../api/schema';
import { DatePicker } from '../../shared/dateComponents';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';
import SearchFormaPago from '../../shared/sat/SearchFormaPago';

export interface CreatePayoffRow {
  id: string;
  shipmentTrackingNumber: string;
  price: number;
}
interface CreatePayoffProps {
  open: boolean;
  onCreate?: () => void;
  onCancel: () => void;
  selectedRows?: CreatePayoffRow[];
  clientId: string;
  totalPrice: number;
}

export default function CreatePayoff({
  open,
  onCreate,
  onCancel,
  selectedRows,
  clientId,
  totalPrice,
}: CreatePayoffProps) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreatePayOffRequest']) => createPayoff(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCreditShipments'] });
      message.success('Guías pagadas correctamente');
      onCreate?.();
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate({
        ...values,
        amount: totalPrice,
        clientId,
        shipmentIds: selectedRows?.map((row) => row.id),
      });
    } catch (e) {}
  };

  const formatAsCurrency = (value: number) => {
    return value.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={open}
      title="Pagar guias de crédito"
      style={{ top: 20 }}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
          Guardar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="create-prepaid-form" scrollToFirstError>
        {clientId && selectedRows && (
          <>
            <Row gutter={16}>
              <Divider orientation="center">Números de guía a pagar</Divider>
              <Col span={24}>
                {selectedRows.map((row) => (
                  <Tag key={row.id}>{row.shipmentTrackingNumber}</Tag>
                ))}
              </Col>
              <Divider orientation="center">Precio total</Divider>
              <Col span={24} style={{ textAlign: 'center' }}>
                <h2 style={{ margin: 0 }}>{formatAsCurrency(totalPrice)}</h2>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="paymentDate"
                  label="Fecha de pago"
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona la fecha en la que recibiste el pago',
                    },
                  ]}
                >
                  <DatePicker allowClear={false} format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="formaPagoId"
                  label="Forma de Pago"
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona una forma de pago',
                    },
                  ]}
                >
                  <SearchFormaPago />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
}
