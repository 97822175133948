import { App, Card, Col, Row, Spin } from 'antd';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import BackgroundSvg from './BackgroundSvg';
import ForgotPassword from './ForgotPassword';
import LoginForm from './LoginForm';

type LoginSteps = 'login' | 'forgotPassword';
export default function LoginPage() {
  const location = useLocation();
  const { message } = App.useApp();
  const { user, signIn, loading } = useAuth();
  const [loginStep, setLoginStep] = useState<LoginSteps>('login');

  const from = location.state?.from?.pathname || '/usuarios-internos';

  if (loading) {
    return (
      <Row style={{ minHeight: '100vh', textAlign: 'center' }} justify="center" align="middle">
        <Col span={4} xs={24}>
          <Spin />
        </Col>
      </Row>
    );
  }

  if (user) return <Navigate to={from} />;

  const handleFinish = (user: any) => {
    signIn(user);
  };

  const handleFinishForgot = () => {
    message.success('Inicia sesión con tu nueva contraseña', 3);
    setLoginStep('login');
  };

  return (
    <div>
      <BackgroundSvg />
      <Row style={{ minHeight: '100vh' }} align={'middle'} justify={'center'}>
        <Col span={4} xs={24}>
          <Row align={'middle'} justify={'center'}>
            <Col
              xl={{ span: 6, offset: 12 }}
              lg={{ span: 8, offset: 10 }}
              sm={{ span: 10, offset: 10 }}
              xs={{ span: 22 }}
            >
              <Card style={{ padding: '10px 35px' }}>
                {loginStep === 'login' ? (
                  <LoginForm onFinish={handleFinish} changeStepForgotPw={() => setLoginStep('forgotPassword')} />
                ) : loginStep === 'forgotPassword' ? (
                  <ForgotPassword onFinish={handleFinishForgot} changeStepLogin={() => setLoginStep('login')} />
                ) : null}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
