import * as React from 'react';

interface AuthContextType {
  user: any;
  signIn: (user: any) => void;
  loading: boolean;
  logout: () => void;
}

function useAuth() {
  return React.useContext(AuthContext);
}

const AuthContext = React.createContext<AuthContextType>({
  user: null,
  loading: false,
  signIn: () => {},
  logout: () => {},
});
export { AuthContext, useAuth };
