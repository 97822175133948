import { useQuery } from '@tanstack/react-query';
import { Button, Col, Modal, Row, Spin } from 'antd';
import { getUserClient } from '../../api/userClient';
import ReadonlyInput from '../../shared/form/ReadonlyInput';

interface UserDetailProps {
  userId?: string | undefined;
  open: boolean;
  onCancel: () => void;
}

export default function UserClientDetail({ userId, open, onCancel }: UserDetailProps) {
  const { data, remove } = useQuery({
    queryKey: ['getUserClient', userId],
    queryFn: () => getUserClient(userId),
    enabled: userId !== undefined && open,
  });

  const handleCancel = () => {
    remove();
    onCancel();
  };

  return (
    <Modal
      open={open}
      title="Vista de usuario cliente"
      style={{ top: 20 }}
      destroyOnClose
      maskClosable
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
      ]}
    >
      {!data ? (
        <Spin size="large" />
      ) : (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <ReadonlyInput value={data.name} label="Nombre" />
          </Col>
          <Col span={24}>
            <ReadonlyInput value={data.email} label="Correo electrónico" />
          </Col>
          <Col span={24}>
            <ReadonlyInput value={data.client.name} label="Cliente" />
          </Col>
        </Row>
      )}
    </Modal>
  );
}
