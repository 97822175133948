import axios, { purgeQueryParams } from './axios';
import { components, paths } from './schema';

export const getZipCodes = async (
  params: paths['/zipcode/list']['get']['parameters']['query'],
): Promise<components['schemas']['ZipcodeListResponse']['data']> => {
  const data = await axios.get<components['schemas']['ZipcodeListResponse']>(
    `/zipcode/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const getZipCode = async (zipCodeId?: string): Promise<components['schemas']['GetZipcodeResponse']['data']> => {
  if (!zipCodeId) return await Promise.reject(new Error());
  const data = await axios.get<components['schemas']['GetZipcodeResponse']>(`/zipcode/${zipCodeId}`);
  return data.data.data;
};

export const deleteZipCode = async (
  zipCodeId: string,
): Promise<components['schemas']['DeleteZipcodeResponse']['data']> => {
  const data = await axios.delete<components['schemas']['DeleteZipcodeResponse']>(`/zipcode/${zipCodeId}`);
  return data.data.data;
};

export const editZipCode = async (
  zipCodeId: string,
  body: components['schemas']['CreateZipcodeRequest'],
): Promise<components['schemas']['EditZipcodeResponse']['data']> => {
  const data = await axios.put<components['schemas']['EditZipcodeResponse']>(`/zipcode/${zipCodeId}`, body);
  return data.data.data;
};

export const createZipCode = async (
  body: components['schemas']['CreateZipcodeRequest'],
): Promise<components['schemas']['CreateZipcodeResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateZipcodeResponse']>(`/zipcode`, body);
  return data.data.data;
};

export const getZones = async (
  params: paths['/zone/list']['get']['parameters']['query'],
): Promise<components['schemas']['ZoneListResponse']['data']> => {
  const data = await axios.get<components['schemas']['ZoneListResponse']>(
    `/zone/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const getPrices = async (
  params: paths['/price/list']['get']['parameters']['query'],
): Promise<components['schemas']['PriceListResponse']['data']> => {
  const data = await axios.get<components['schemas']['PriceListResponse']>(
    `/price/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const createPrice = async (
  body: components['schemas']['CreatePriceRequest'],
): Promise<components['schemas']['CreatePriceResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreatePriceResponse']>(`/price`, body);
  return data.data.data;
};

export const editPrice = async (
  priceId: string,
  body: components['schemas']['CreatePriceRequest'],
): Promise<components['schemas']['CreatePriceResponse']['data']> => {
  const data = await axios.put<components['schemas']['CreatePriceResponse']>(`/price/${priceId}`, body);
  return data.data.data;
};

export const deletePrice = async (priceId: string): Promise<components['schemas']['DeletePriceResponse']['data']> => {
  const data = await axios.delete<components['schemas']['DeletePriceResponse']>(`/price/${priceId}`);
  return data.data.data;
};

export const createZone = async (
  body: components['schemas']['CreateZoneRequest'],
): Promise<components['schemas']['CreateZoneResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateZoneResponse']>(`/zone`, body);
  return data.data.data;
};

export const deleteZone = async (zoneId: string): Promise<components['schemas']['DeleteZoneResponse']['data']> => {
  const data = await axios.delete<components['schemas']['DeleteZoneResponse']>(`/zone/${zoneId}`);
  return data.data.data;
};
