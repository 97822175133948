import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Input, InputNumber, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { editPrice } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';
import useResetFormOnCloseModal from '../../../shared/hooks/useResetFormModalOnClose';

export const EditPrice = ({ record }: DataTableActionProps<components['schemas']['PriceCondensed']>) => {
  const [form] = Form.useForm<components['schemas']['CreatePriceRequest']>();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreatePriceRequest']) => editPrice(record.id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getPrices'] });
      message.success('Precio editado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record.name,
        price: record.price,
      });
    }
  }, [record]);

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate(values);
    } catch (e) {}
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        title="Editar precio"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
            Guardar
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="edit-zipcode-form" scrollToFirstError>
          <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre del precio' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="price" label="Precio" rules={[{ required: true, message: 'Ingresa el precio' }]}>
            <InputNumber
              formatter={(value) => `$ ${value!}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '100%' }}
              // min={0}
              precision={2}
            />
          </Form.Item>
        </Form>
      </Modal>
      <EditOutlined onClick={() => setOpen(true)} />
    </>
  );
};
