import { useQuery } from '@tanstack/react-query';
import { Button, Col, Modal, Row, Spin } from 'antd';
import { getUser } from '../../api';
import ReadonlyInput from '../../shared/form/ReadonlyInput';

interface UserDetailProps {
  userId?: string | undefined;
  open: boolean;
  onCancel: () => void;
}

export default function UserDetail({ userId, open, onCancel }: UserDetailProps) {
  const { data, remove } = useQuery({
    queryKey: ['getUser', userId],
    queryFn: () => getUser(userId),
    enabled: userId !== undefined && open,
  });

  const handleCancel = () => {
    remove();
    onCancel();
  };

  return (
    <Modal
      open={open}
      title="Vista de usuario"
      style={{ top: 20 }}
      destroyOnClose
      maskClosable
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
      ]}
    >
      {!data ? (
        <Spin size="large" />
      ) : (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <ReadonlyInput value={data.firstName} label="Nombres" />
          </Col>
          <Col span={24}>
            <ReadonlyInput value={data.lastName} label="Apellidos" />
          </Col>
          <Col span={24}>
            <ReadonlyInput value={data.email} label="Correo electrónico" />
          </Col>
          <Col span={24}>
            <ReadonlyInput value={data.area.area} label="Área" />
          </Col>
          <Col span={24}>
            <ReadonlyInput value={data.role.role} label="Rol" />
          </Col>
          {data.rfc && (
            <Col span={24}>
              <ReadonlyInput value={data.role.role} label="RFC" />
            </Col>
          )}
          {data.driversLicense && (
            <Col span={24}>
              <ReadonlyInput value={data.driversLicense} label="Licencia de conducir" />
            </Col>
          )}
          {data.zones && data.zones.length > 0 && (
            <Col span={24}>
              <ReadonlyInput value={data.zones.map((z) => z.zone.name).join(', ')} label="Zonas" />
            </Col>
          )}
        </Row>
      )}
    </Modal>
  );
}
