import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 960 540"
    preserveAspectRatio="none"
    style={{
      position: "absolute",
      zIndex: "-1",
      height: "100%",
      width: "100%",
    }}
    {...props}
  >
    <path fill="#001220" d="M0 0h960v540H0z" />
    <path
      fill="#715df2"
      d="m309 0 12 18c12 18 36 54 30.3 90-5.6 36-41 72-60 108s-21.6 72-23.8 108c-2.2 36-3.8 72 7.7 108s36.1 72 48.5 90l12.3 18H0V0Z"
    />
    <path
      fill="#9982e6"
      d="m264 0 7.2 18c7.1 18 21.5 54 12.5 90s-41.4 72-40.9 108 33.9 72 41.9 108-9.4 72-30.2 108c-20.8 36-45.2 72-57.3 90L185 540H0V0Z"
    />
    <path
      fill="#b5a7da"
      d="m133 0 7.5 18c7.5 18 22.5 54 26.7 90 4.1 36-2.5 72-2.4 108 .2 36 7.2 72 19.2 108s29 72 29 108-17 72-25.5 90l-8.5 18H0V0Z"
    />
    <path
      fill="#ccc"
      d="m138 0-6.3 18c-6.4 18-19 54-31.9 90C87 144 74 180 77 216s22 72 29.2 108c7.1 36 2.5 72 4 108s9.1 72 13 90l3.8 18H0V0Z"
    />
  </svg>
);
export default SvgComponent;
