import { CopyOutlined, ReloadOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Col, Form, Input, Modal, Row, Tooltip } from 'antd';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { components } from '../../api/schema';
import { createUserClient } from '../../api/userClient';
import SearchClient from '../../shared/client/SearchClient';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';

interface CreateUserClientProps {
  open: boolean;
  onCreate?: () => void;
  onCancel: () => void;
}

export default function CreateUserClient({ open, onCreate, onCancel }: CreateUserClientProps) {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tempPassword, setTempPassword] = useState<string>('');
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreateUserClientRequest']) => createUserClient(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsersClient'] });
      message.success('Usuario cliente creado correctamente');
      onCreate?.();
    },
    onError: (e: AxiosError<components['schemas']['BaseError400']>) => {
      if (
        e.response?.status === 400 &&
        e.response?.data.httpStatus === 400 &&
        e.response?.data.error === 'User already exists'
      ) {
        message.error('El usuario cliente ya existe, intenta con otro correo electrónico');
      } else {
        message.error('Ocurrio un error, vuelve a intentarlo más tarde');
      }
    },
  });
  const [form] = Form.useForm<components['schemas']['CreateUserClientRequest']>();

  useResetFormOnCloseModal({ form, open });

  // Function to copy the temporary password to the clipboard
  const copyPassword = () => {
    navigator.clipboard.writeText(tempPassword);
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 2000);
  };

  const generatePass = (): string => {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const allChars = uppercaseChars + lowercaseChars + numberChars;

    let password = '';
    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;

    // Generate password with at least one uppercase, one lowercase, and one number
    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * uppercaseChars.length);
      password += uppercaseChars[randomIndex];
      hasUppercase = true;
    }

    for (let i = 0; i < 2; i++) {
      const randomIndex = Math.floor(Math.random() * lowercaseChars.length);
      password += lowercaseChars[randomIndex];
      hasLowercase = true;
    }

    for (let i = 0; i < 1; i++) {
      const randomIndex = Math.floor(Math.random() * numberChars.length);
      password += numberChars[randomIndex];
      hasNumber = true;
    }

    // Fill the rest of the password with random characters
    for (let i = 0; i < 8 - 5 - 2 - 1; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      password += allChars[randomIndex];
    }

    // Shuffle the password to ensure randomness
    password = password
      .split('')
      .sort(() => Math.random() - 0.5)
      .join('');

    // If any requirement is not fulfilled, regenerate the password
    if (!hasUppercase || !hasLowercase || !hasNumber) {
      return generatePass();
    }

    return password;
  };

  const generateRandomPassword = () => {
    const randomPassword = generatePass();
    setTempPassword(randomPassword);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate({ ...values, tempPassword });
    } catch (e) {}
  };

  const handleSelectClient = (selectedClient: components['schemas']['ClientCondensed']) => {
    form.setFieldsValue({ clientId: selectedClient.id });
  };

  useEffect(() => {
    generateRandomPassword();
  }, []);

  return (
    <Modal
      open={open}
      title="Crear usuario"
      style={{ top: 20 }}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
          Guardar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="create-user-client-form" scrollToFirstError>
        <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Correo electrónico"
          name="email"
          rules={[
            {
              required: true,
              message: 'Ingresa un correo electrónico',
              type: 'email',
            },
          ]}
        >
          <Input placeholder="ejemplo@correo.com" />
        </Form.Item>
        <Form.Item name="clientId" label="Cliente" rules={[{ required: true, message: 'Selecciona un cliente' }]}>
          <SearchClient onSelected={(selectedClient) => handleSelectClient(selectedClient)} />
        </Form.Item>
        <Row>
          <Col span={24}>
            <div className="ant-form-item-label">
              <label className="ant-form-item-required">Contraseña temporal</label>
            </div>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col
            span={8}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: '12px',
            }}
          >
            <h2 id="temp-password">{tempPassword}</h2>
          </Col>

          <Col
            style={{
              display: 'flex',
              justifyContent: 'baseline',
              alignItems: 'center',
              marginBottom: '12px',
            }}
          >
            <ReloadOutlined className="icon-button" style={{ fontSize: '18px' }} onClick={generateRandomPassword} />
          </Col>

          <Col
            style={{
              display: 'flex',
              justifyContent: 'baseline',
              alignItems: 'end',
              marginBottom: '12px',
            }}
          >
            <Tooltip color="blue" open={tooltipVisible} title="!Copiado!">
              <Button
                icon={<CopyOutlined />}
                className="btn-add_new"
                style={{ height: '48px' }}
                onClick={copyPassword}
                type="primary"
                key="1"
              >
                Copiar al portapapeles
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
