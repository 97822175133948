import { ReadOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Avatar, Button, Col, Form, Input, List, Modal, Row, Spin, Tooltip } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { createComment, getComments } from '../../api';
import { components } from '../../api/schema';
import useResetFormOnCloseModal from '../hooks/useResetFormModalOnClose';

export enum AvailableEntity {
  SHIPMENT = 'SHIPMENT'
}

export interface CommentsLoggerProps {
  entityId: string;
  entity: AvailableEntity;
  queryKeyToInvalidate: string;
  hasComments: boolean;
}
export default function CommentsLogger({ entityId, entity, queryKeyToInvalidate, hasComments }: CommentsLoggerProps) {
  const [form] = Form.useForm<components['schemas']['CommentLogPutRequest']>();
  const [open, setOpen] = useState(false);
  const [totalComments, setTotalComments] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [localData, setLocalData] = useState<
    Array<{ author: string; avatar: React.ReactElement; comment: string; datetime: React.ReactElement }>
  >([]);
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { data, remove, refetch } = useQuery({
    queryKey: ['getComments'],
    queryFn: () => getComments(entityId),
    enabled: entityId !== undefined && open,
  });
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CommentLogPutRequest']) => createComment(entityId, body),
    onSuccess: () => {
      refetch();
      form.resetFields();
      setSubmitted(true);
      message.success('Comentario agregado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  useEffect(() => {
    if (data) {
      setLocalData(formatData(data));
    }
  }, [data]);

  const formatData = (items: Array<components['schemas']['CommentLog']>) => {
    setTotalComments(items.length);
    return items.map((i) => ({
      author: i.creator,
      avatar: <Avatar style={{ backgroundColor: '#0772df' }}>{i.creator[0].toUpperCase()}</Avatar>,
      comment: i.comment,
      datetime: (
        <Tooltip title={moment(i.timestamp).format('DD MMMM, YYYY, hh:mm A')}>
          <span>{moment(i.timestamp).fromNow()}</span>
        </Tooltip>
      ),
    }));
  };

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate({ ...values, entity, hasComments });
    } catch (e) {}
  };

  const handleCancel = () => {
    setOpen(false);
    if (submitted) queryClient.invalidateQueries({ queryKey: [queryKeyToInvalidate] });
    remove();
    setSubmitted(true);
  };

  return (
    <>
      <Modal
        open={open}
        title="Bitácora de comentarios"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
        ]}
      >
        {!data ? (
          <Spin size="large" />
        ) : (
          <>
            <Form form={form} layout="vertical" name="comments-form" scrollToFirstError onFinish={handleSubmit}>
              <Row>
                <Col span={24}>
                  <Form.Item name="comment" rules={[{ required: true, message: 'Ingresa tus comentarios' }]}>
                    <Input.TextArea rows={4} placeholder="Agrega tus comentarios" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ float: 'right' }} loading={mutation.isLoading}>
                      Guardar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col span={24}>
                <List
                  header={`${totalComments} comentarios`}
                  itemLayout="horizontal"
                  pagination={{
                    total: totalComments,
                    defaultCurrent: 1,
                    hideOnSinglePage: true,
                    pageSize: 5,
                  }}
                  dataSource={localData}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta avatar={item.avatar} description={item.datetime} title={item.author} />
                      {item.comment}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal>
      <ReadOutlined style={{ color: hasComments ? '#faad14' : '#000' }} onClick={() => setOpen(true)} />
    </>
  );
}
