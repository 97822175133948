export default function formatMoneyToMXN(value: number): string {
  try {
    // Use the Intl.NumberFormat object to format the number to MXN currency
    const formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    });

    // Format the number and return the result
    return formatter.format(value);
  } catch (error) {
    console.error('Error formatting money:', error);
    return '';
  }
}
