import { DeleteTwoTone, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  App,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Steps,
  Table,
  Timeline,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { MapPin, Package, Truck } from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import { createShipment, getClient } from '../../api';
import { components } from '../../api/schema';
import SearchClient from '../../shared/client/SearchClient';
import { formatAddressWithName } from '../../shared/client/formatAddress';
import { DatePicker } from '../../shared/dateComponents';
import ReadonlyCheckbox from '../../shared/form/ReadonlyCheckbox';
import ReadonlyInput from '../../shared/form/ReadonlyInput';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';
import CreateAddressDrawer from '../client/CreateAddressDrawer';
import CreateClientDrawer from '../client/CreateClientDrawer';
import CreateContactDrawer from '../client/CreateContactDrawer';
import AddPackageDrawer, { NewPackageItem } from './AddPackageDrawer';
import formatMoneyToMXN from '../../shared/shipment/formatMoney';
import SearchFormaPago from '../../shared/sat/SearchFormaPago';
import SearchRegimenFiscal from '../../shared/sat/SearchRegimenFiscal';
interface CreateUserProps {
  open: boolean;
  onCreate?: () => void;
  onCancel: () => void;
}

interface MerchItem {
  key: string;
  count: number;
  productKey: string;
  productName: string;
  unitKey: string;
  amount: number;
  weight: number;
  merchDesc: string;
}
interface PackageItem {
  key: string;
  weight: number;
  dimensionsW: number;
  dimensionsH: number;
  dimensionsL: number;
  merchItems: MerchItem[];
  basePrice: number;
  extraPrice: number;
  totalPrice: number;
}

export default function CreateService({ open, onCreate, onCancel }: CreateUserProps) {
  const [clientId, setClientId] = useState<string | undefined>();
  const [newlyCreatedClient, setNewlyCreatedClient] = useState<components['schemas']['Client'] | undefined>();
  const [newlyCreatedPickupAddressId, setNewlyCreatedPickupAddressId] = useState<string | undefined>();
  const [newlyCreatedOriginAddressId, setNewlyCreatedOriginAddressId] = useState<string | undefined>();
  const [newlyCreatedDeliveryAddressId, setNewlyCreatedDeliveryAddressId] = useState<string | undefined>();
  const [newlyCreatedPickupContactId, setNewlyCreatedPickupContactId] = useState<string | undefined>();
  const [newlyCreatedDeliveryContactId, setNewlyCreatedDeliveryContactId] = useState<string | undefined>();
  const [createClientDrawerOpen, setCreateClientDrawerOpen] = useState<boolean>(false);
  const [createOriginAddressDrawerOpen, setCreateOriginAddressDrawerOpen] = useState<boolean>(false);
  const [createPickupAddressDrawerOpen, setCreatePickupAddressDrawerOpen] = useState<boolean>(false);
  const [createDeliveryAddressDrawerOpen, setCreateDeliveryAddressDrawerOpen] = useState<boolean>(false);
  const [createPickupContactDrawerOpen, setCreatePickupContactDrawerOpen] = useState<boolean>(false);
  const [createDeliveryContactDrawerOpen, setCreateDeliveryContactDrawerOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [packageItems, setPackageItems] = useState<PackageItem[]>([]);
  const [preselectedPackage, setPreselectedPackage] = useState<PackageItem | undefined>();
  const [shipmentType, setShipmentType] =
    useState<components['schemas']['CreateShipmentRequest']['shipmentType']>('LOCAL_LOCAL');
  const [addPackageDrawerOpen, setAddPackageDrawerOpen] = useState(false);
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const {
    data: client,
    isError,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: ['getClient', clientId],
    queryFn: () => getClient(clientId),
    enabled: clientId !== undefined,
  });
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreateShipmentRequest']) => createShipment(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getShipmentPickupList'] });
      message.success('Envío creado correctamente');
      onCreate?.();
      handleCancel();
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });
  const [form] = Form.useForm();
  const [formSecondStep] = Form.useForm();
  const [formThirdStep] = Form.useForm();
  const needsPickupCheckbox = Form.useWatch('needsPickup', form);
  const needsInsuranceCheckbox = Form.useWatch('needsInsurance', formSecondStep);
  const merchValueForInsurance = Form.useWatch('merchValueForInsurance', formSecondStep);
  const paymentType = Form.useWatch('paymentType', formThirdStep);
  const payLaterNeedsInvoice = Form.useWatch('payLaterNeedsInvoice', formThirdStep);
  const rfcWatch = Form.useWatch('payLaterRfc', formThirdStep);
  const originAddressId = Form.useWatch('originAddressId', form);
  const pickupAddressId = Form.useWatch('pickupAddressId', form);
  const pickupAddressContactId = Form.useWatch('pickupAddressContactId', form);
  const deliveryAddressId = Form.useWatch('deliveryAddressId', form);
  const deliveryAddressContactId = Form.useWatch('deliveryAddressContactId', form);
  const clientIsContactForPickupAddress = Form.useWatch('clientIsContactForPickupAddress', form);
  useResetFormOnCloseModal({ form, open });
  useResetFormOnCloseModal({ form: formSecondStep, open });

  const next = async () => {
    try {
      if (currentStep === 0) {
        await form.validateFields();
        setCurrentStep(currentStep + 1);
      } else if (currentStep === 1) {
        await formSecondStep.validateFields();
        if (!packageItems.length) {
          message.warning('Ingresa al menos un paquete');
        } else {
          setCurrentStep(currentStep + 1);
        }
      }
    } catch (e) {}
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    if (pickupAddressId) {
      setNewlyCreatedPickupAddressId(pickupAddressId);
    }
  }, [pickupAddressId]);

  useEffect(() => {
    if (originAddressId) {
      setNewlyCreatedOriginAddressId(originAddressId);
    }
  }, [originAddressId]);

  useEffect(() => {
    if (pickupAddressContactId) {
      setNewlyCreatedPickupContactId(pickupAddressContactId);
    }
  }, [pickupAddressContactId]);

  useEffect(() => {
    if (deliveryAddressId) {
      setNewlyCreatedDeliveryAddressId(deliveryAddressId);
    }
  }, [deliveryAddressId]);

  useEffect(() => {
    if (deliveryAddressContactId) {
      setNewlyCreatedDeliveryContactId(deliveryAddressContactId);
    }
  }, [deliveryAddressContactId]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const valuesSecondStep = await formSecondStep.validateFields();
      const valuesThirdStep = await formThirdStep.validateFields();

      let cfdiOperation: components['schemas']['CreateShipmentRequest']['shipmentCfdiOperation'];

      if (shipmentType === 'LOCAL_LOCAL') {
        // LOCAL_LOCAL type needs to be treated as a normal cfdi operation regardless of any of the other conditions
        cfdiOperation = valuesThirdStep?.paymentType === 'CASH' ? 'CONTADO' : 'POR_COBRAR';
      } else {
        cfdiOperation = client?.credit ? 'CREDITO' : valuesThirdStep?.paymentType === 'CASH' ? 'CONTADO' : 'POR_COBRAR';
      }

      const data: components['schemas']['CreateShipmentRequest'] = {
        clientId: values.clientId,
        scheduledPickupDate: values.scheduledPickupDate.startOf('day').toISOString(),
        isClientContact: !!values.clientIsContactForPickupAddress,
        originAddressId: values.originAddressId,
        pickupAddressId: values.pickupAddressId,
        pickupContactId: values.pickupAddressContactId,
        deliveryAddressId: values.deliveryAddressId,
        deliveryContactId: values.deliveryAddressContactId,
        needsPickup: values.needsPickup,
        isFragile: valuesSecondStep.isFragile,
        needsPriceRevision: valuesSecondStep.needsPriceRevision,
        needsInsurance: valuesSecondStep.needsInsurance,
        merchValueForInsurance: valuesSecondStep.merchValueForInsurance,
        notes: valuesSecondStep.notes,
        packageItems: packageItems.map((item) => ({
          basePrice: item.basePrice,
          extraPrice: item.extraPrice,
          totalPrice: item.totalPrice,
          dimensionsW: item.dimensionsW,
          dimensionsH: item.dimensionsH,
          dimensionsL: item.dimensionsL,
          totalWeightInKg: item.weight,
          merchItems: item.merchItems.map((merchItem) => ({
            satProductId: merchItem.productKey,
            satUnitId: merchItem.unitKey,
            amount: merchItem.amount,
            description: merchItem.merchDesc,
            weightInKg: merchItem.weight,
          })),
        })),
        shipmentType,
        paymentType: valuesThirdStep.paymentType,
        formaPagoId: valuesThirdStep.formaPagoId,
        basePrice: packageItems.reduce((acc, item) => acc + item.basePrice, 0),
        extraPrice: packageItems.reduce((acc, item) => acc + item.extraPrice, 0),
        totalPrice:
          packageItems.reduce((acc, item) => acc + item.basePrice, 0) +
          packageItems.reduce((acc, item) => acc + item.extraPrice, 0),
        shipmentCfdiOperation: cfdiOperation,
        payLater:
          valuesThirdStep.paymentType === 'PAY_LATER'
            ? {
                needsInvoice: valuesThirdStep.payLaterNeedsInvoice,
                name: valuesThirdStep.payLaterNeedsInvoice ? valuesThirdStep.payLaterName : undefined,
                rfc: valuesThirdStep.payLaterNeedsInvoice ? valuesThirdStep.payLaterRfc : undefined,
                zipCode: valuesThirdStep.payLaterNeedsInvoice ? valuesThirdStep.payLaterZipCode : undefined,
                regimenFiscalId: valuesThirdStep.payLaterNeedsInvoice
                  ? valuesThirdStep.payLaterRegimenFiscalId
                  : undefined,
              }
            : undefined,
      };
      mutation.mutate(data);
    } catch (e) {}
  };

  const handleCancel = () => {
    resetNecessaryFields();
    setClientId(undefined);
    setNewlyCreatedClient(undefined);
    setPackageItems([]);
    setPreselectedPackage(undefined);
    setCurrentStep(0);
    onCancel();
  };

  const handleClientCreate = async (client: components['schemas']['Client']) => {
    setCreateClientDrawerOpen(false);
    setNewlyCreatedClient(client);
    resetNecessaryFields();
    try {
      await form.validateFields(['clientId']);
    } catch (e) {}
  };

  const handleSelectClient = (client: components['schemas']['ClientCondensed']) => {
    queryClient.invalidateQueries({ queryKey: ['getClient'] });
    setClientId(client.id);
    resetNecessaryFields();
  };

  const resetNecessaryFields = () => {
    setNewlyCreatedOriginAddressId(undefined);
    setNewlyCreatedPickupAddressId(undefined);
    setNewlyCreatedDeliveryAddressId(undefined);
    setNewlyCreatedPickupContactId(undefined);
    setNewlyCreatedDeliveryContactId(undefined);
    form.setFields([
      { name: 'originAddressId', value: undefined },
      { name: 'pickupAddressId', value: undefined },
      { name: 'pickupAddressContactId', value: undefined },
      { name: 'deliveryAddressId', value: undefined },
      { name: 'deliveryAddressContactId', value: undefined },
      { name: 'needsPickup', value: true },
      { name: 'clientIsContactForPickupAddress', value: false },
    ]);
  };

  const handlePickupAddressCreate = async ({ id }: components['schemas']['Address']) => {
    await queryClient.invalidateQueries({ queryKey: ['getClient'] });
    setNewlyCreatedPickupAddressId(id);
    form.setFieldValue('pickupAddressId', id);
    setCreatePickupAddressDrawerOpen(false);
  };

  const handleOriginAddressCreate = async ({ id }: components['schemas']['Address']) => {
    await queryClient.invalidateQueries({ queryKey: ['getClient'] });
    setNewlyCreatedOriginAddressId(id);
    form.setFieldValue('originAddressId', id);
    setCreateOriginAddressDrawerOpen(false);
  };

  const handleDeliveryAddressCreate = async ({ id }: components['schemas']['Address']) => {
    await queryClient.invalidateQueries({ queryKey: ['getClient'] });
    setNewlyCreatedDeliveryAddressId(id);
    form.setFieldValue('deliveryAddressId', id);
    setCreateDeliveryAddressDrawerOpen(false);
  };

  const handlePickupContactCreate = async ({ id }: components['schemas']['Contact']) => {
    await queryClient.invalidateQueries({ queryKey: ['getClient'] });
    setNewlyCreatedPickupContactId(id);
    form.setFieldValue('pickupAddressContactId', id);
    setCreatePickupContactDrawerOpen(false);
  };

  const handleDeliveryContactCreate = async ({ id }: components['schemas']['Contact']) => {
    await queryClient.invalidateQueries({ queryKey: ['getClient'] });
    setNewlyCreatedDeliveryContactId(id);
    form.setFieldValue('deliveryAddressContactId', id);
    setCreateDeliveryContactDrawerOpen(false);
  };

  const needsToDisplayDaysAvailable = (type: 'origin' | 'pickup' | 'delivery') => {
    let address;
    if (type === 'origin') {
      address = client?.addresses.find((a) => a.id === originAddressId);
    } else if (type === 'pickup') {
      address = client?.addresses.find((a) => a.id === pickupAddressId);
    } else {
      address = client?.addresses.find((a) => a.id === deliveryAddressId);
    }

    return address?.zipCode?.zone?.daysAvailable;
  };

  const handleClickRFCPublico = () => {
    console.log('xdd');
    if (rfcWatch === 'XAXX010101000') {
      formThirdStep.setFieldValue('payLaterRfc', '');
      formThirdStep.validateFields(['payLaterRfc']);
    } else {
      console.log('wtrfff');
      formThirdStep.setFieldValue('payLaterRfc', 'XAXX010101000');
      formThirdStep.validateFields(['payLaterRfc']);
    }
  };

  /**
   * Removes a shipment package from the list of package items and updates the state accordingly.
   * @param shipmentPackage - The shipment package to remove.
   */
  const handlePackageRemove = (shipmentPackage: PackageItem) => {
    const newItems = packageItems
      .filter((item) => item.key !== shipmentPackage.key)
      .map((item, k) => ({ ...item, count: k + 1 }));
    setPackageItems(newItems);
  };

  /**
   * Adds a new shipment package to the list of package items or updates an existing one and updates the state accordingly.
   * @param shipmentPackage - The shipment package to add or update.
   */
  const handlePackageAdd = (shipmentPackage: NewPackageItem) => {
    if (shipmentPackage.key) {
      // Update it
      const newItems = packageItems.map((item, k) =>
        item.key === shipmentPackage.key
          ? { ...shipmentPackage, count: k + 1, key: shipmentPackage.key }
          : { ...item, count: k + 1 },
      );
      setPackageItems(newItems);
      setAddPackageDrawerOpen(false);
      setPreselectedPackage(undefined);
    } else {
      const newPackage = { ...shipmentPackage, key: uuidv4() };
      const newItems = [...packageItems, newPackage].map((item, k) => ({ ...item, count: k + 1 }));
      setPackageItems(newItems);
      setAddPackageDrawerOpen(false);
      setPreselectedPackage(undefined);
    }
  };

  /**
   * Calculates the price of the shipment based on the addresses and the different possible combinations
   * to a total of 7 different cases (see below)
   * @returns The price of the shipment based on the addresses
   */
  const calculatePriceBasedOnAddressess = (): number => {
    // We have the following types of deliveries:
    // 1. Pickup a package Locally and deliver it locally
    // 2. People take their packages to the office and it's delivered locally
    // 3. Pickup a package locally and deliver it foreign
    // 4. People take their packages to the office and it's delivered foreign
    // 5. Pickup a package foreign and deliver it locally
    // 6. Pickup a package foreign and deliver it to the office for pickup
    // 7. Pickup a package foreign and deliver it foreign
    const pickupAddress = needsPickupCheckbox ? client?.addresses.find((a) => a.id === pickupAddressId) : undefined;
    const deliveryAddress = client?.addresses.find((a) => a.id === deliveryAddressId);
    // Check those cases where we do have a pickup address
    if (pickupAddress) {
      // Check if we have a delivery address, the only case where we dont have a delivery address is when we deliver to the office
      if (deliveryAddress) {
        // 1. Pickup a package Locally and deliver it locally
        if (pickupAddress.zipCode.zone.zoneType === 'LOCAL' && deliveryAddress.zipCode.zone.zoneType === 'LOCAL') {
          setShipmentType('LOCAL_LOCAL');
          // The price will be pickup zone pickup price (get price from zone) + delivery zone delivery price (get price from zipCode)
          return pickupAddress.zipCode.zone.pickupDeliveryPrice + deliveryAddress.zipCode.price.price;
        } else if (
          pickupAddress.zipCode.zone.zoneType === 'LOCAL' &&
          deliveryAddress.zipCode.zone.zoneType === 'FOREIGN'
        ) {
          setShipmentType('LOCAL_FOREIGN');
          // 3. Pickup a package locally and deliver it foreign
          // The price will be pickup zone pickup price (get price from zone) + delivery zone delivery price (get price from zipCode)
          return pickupAddress.zipCode.zone.pickupDeliveryPrice + deliveryAddress.zipCode.price.price;
        } else if (
          pickupAddress.zipCode.zone.zoneType === 'FOREIGN' &&
          deliveryAddress.zipCode.zone.zoneType === 'LOCAL'
        ) {
          setShipmentType('FOREIGN_LOCAL');
          // 5. Pickup a package foreign and deliver it locally
          // The price will be pickup delivery price (get price from zipCode) + delivery zone delivery price (get price from zone)
          return pickupAddress.zipCode.price.price + deliveryAddress.zipCode.zone.pickupDeliveryPrice;
        } else if (
          pickupAddress.zipCode.zone.zoneType === 'FOREIGN' &&
          deliveryAddress.zipCode.zone.zoneType === 'FOREIGN'
        ) {
          setShipmentType('FOREIGN_FOREIGN');
          // 7. Pickup a package foreign and deliver it foreign
          // The price will be pickup delivery price (get price from zipCode) + delivery zone delivery price (get price from zipCode)
          return pickupAddress.zipCode.price.price + deliveryAddress.zipCode.price.price;
        }
      } else {
        // 6. Pickup a package foreign and deliver it to the office for pickup
        // The price will be pickup delivery price (get price from zipCode)
        return pickupAddress.zipCode.price.price;
      }
    } else {
      // When we don't have a pickup address, it means that the person takes their package to the office
      if (deliveryAddress) {
        if (deliveryAddress.zipCode.zone.zoneType === 'LOCAL') {
          setShipmentType('LOCAL_LOCAL');
          // 2. People take their packages to the office and it's delivered locally
          // The price will be delivery zone delivery price (get price from zipCode)
          return deliveryAddress.zipCode.price.price;
        } else if (deliveryAddress.zipCode.zone.zoneType === 'FOREIGN') {
          setShipmentType('LOCAL_FOREIGN');
          // 4. People take their packages to the office and it's delivered foreign
          // The price will be delivery zone delivery price (get price from zipCode)
          return deliveryAddress.zipCode.price.price;
        }
      } else {
        // This might be the case where a person takes their package to the office and it's delivered in the same office, so no pickup nor delivery is needed.
        // This was not defined tho
      }
    }
    return 0;
  };

  /**
   * Calculates the price of the insurance based on the value of the merch
   * @returns The price of the insurance based on the value of the merch
   */
  const calculateInsurancePrice = (): number => {
    if (!needsInsuranceCheckbox) return 0;
    const units = Math.round(merchValueForInsurance / 1000);
    return units * 12;
  };

  /**
   * Builds an array of timeline items to display in the UI based on the form values and client data.
   * @returns An array of timeline items.
   */
  const buildTimelineItems = (): Array<{ children: ReactElement; dot: ReactElement; color: string }> => {
    const items: Array<{ children: ReactElement; dot: ReactElement; color: string }> = [];
    const totalPackages = packageItems.length;
    const totalMerch = packageItems.reduce((acc, item) => acc + item.merchItems.length, 0);
    const deliveryAddress = client?.addresses.find((a) => a.id === form.getFieldValue('deliveryAddressId'));
    let formattedDeliveryAddress = '';
    if (deliveryAddress) {
      formattedDeliveryAddress = formatAddressWithName(deliveryAddress);
    }
    if (form.getFieldValue('needsPickup')) {
      const pickupAddress = client?.addresses.find((a) => a.id === form.getFieldValue('pickupAddressId'));
      let formattedAddress = '';
      if (pickupAddress) {
        formattedAddress = formatAddressWithName(pickupAddress);
      }
      items.push({
        dot: <MapPin />,
        color: '#1f1f1f',
        children: (
          <>
            <Space>
              <Row>
                <Col span={24} style={{ fontWeight: 'bold' }}>
                  Recolección
                </Col>
                <Col span={24}>{formattedAddress}</Col>
              </Row>
            </Space>
          </>
        ),
      });
    } else {
      items.push({
        dot: <MapPin />,
        color: '#cf1322',
        children: (
          <>
            <Space>
              <Row>
                <Col span={24} style={{ fontWeight: 'bold', color: '#cf1322' }}>
                  Recolección
                </Col>
                <Col span={24}>No se necesita recolección</Col>
              </Row>
            </Space>
          </>
        ),
      });
    }
    items.push({
      dot: <Package />,
      color: '#1f1f1f',
      children: (
        <>
          <Space>
            <Row>
              <Col span={24} style={{ fontWeight: 'bold' }}>
                Mercancías
              </Col>
              <Col span={24}>
                {totalPackages} paquete{totalPackages > 1 ? 's' : ''} con un total de {totalMerch} mercancía
                {totalMerch > 1 ? 's' : ''}
              </Col>
            </Row>
          </Space>
        </>
      ),
    });

    items.push({
      dot: <Truck />,
      color: '#1f1f1f',
      children: (
        <>
          <Space>
            <Row>
              <Col span={24} style={{ fontWeight: 'bold' }}>
                Entrega
              </Col>
              <Col span={24}>{formattedDeliveryAddress}</Col>
            </Row>
          </Space>
        </>
      ),
    });
    return items;
  };

  const step1 = (
    <>
      <Form
        form={form}
        layout="vertical"
        name="create-service-form"
        scrollToFirstError
        initialValues={{
          needsPickup: true,
          clientIsContactForPickupAddress: false,
          scheduledPickupDate: moment(),
        }}
      >
        <Row gutter={16} align={'middle'}>
          <Col span={12}>
            <Form.Item name="clientId" label="Cliente" rules={[{ required: true, message: 'Selecciona un cliente' }]}>
              <SearchClient
                onSelected={(selectedClient) => handleSelectClient(selectedClient)}
                preselectedClientId={newlyCreatedClient?.id}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={() => setCreateClientDrawerOpen(true)} style={{ marginTop: 5 }}>
              <PlusOutlined />
            </Button>
          </Col>
          <Col span={10}>
            <Form.Item
              name="scheduledPickupDate"
              label="Fecha de recolección"
              rules={[{ required: true, message: 'Selecciona una fecha de recolección' }]}
            >
              <DatePicker
                allowClear={false}
                format="DD/MM/YYYY"
                disabledDate={(current) =>
                  current.isBefore(moment().subtract(1, 'day')) || current.weekday() === 0 || current.weekday() === 6
                }
              />
            </Form.Item>
          </Col>
        </Row>

        {clientId && (
          <>
            {isFetching || isError || isLoading ? (
              <Skeleton active />
            ) : (
              <>
                <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
                  <Col span={6}>
                    <ReadonlyInput removeBoldLabel value={client.name} label="Nombre" />
                  </Col>
                  <Col span={6}>
                    <ReadonlyInput removeBoldLabel value={client.rfc} label="RFC" />
                  </Col>
                  <Col span={6}>
                    <ReadonlyInput removeBoldLabel value={client.comercialName ?? ''} label="Nombre Comercial" />
                  </Col>
                  <Col span={6}>
                    <ReadonlyInput removeBoldLabel value={client.phoneNumber} label="Teléfono" />
                  </Col>
                </Row>
                <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
                  <Col span={8}>
                    <ReadonlyInput removeBoldLabel value={client.emailAddress ?? ''} label="Correo electrónico" />
                  </Col>
                  <Col span={8}>
                    <ReadonlyInput removeBoldLabel value={client.officeHours} label="Horario de oficina" />
                  </Col>
                  <Col span={6}>
                    <ReadonlyInput
                      removeBoldLabel
                      value={client.tipoPersona === 'FISICA' ? 'Física' : 'Moral'}
                      label="Tipo de persona"
                    />
                  </Col>
                  <Col span={2}>
                    <ReadonlyCheckbox removeBoldLabel checked={client.credit} label="Crédito" checkboxText="Sí" />
                  </Col>
                </Row>
                <Divider>Dirección de origen</Divider>
                <Row gutter={16} align={'middle'}>
                  <Col span={21}>
                    <Form.Item
                      name={'originAddressId'}
                      label="Dirección de origen"
                      rules={[{ required: true, message: 'Selecciona una dirección' }]}
                      initialValue={newlyCreatedOriginAddressId}
                    >
                      <Select
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={client?.addresses
                          .filter((address) => address.addressType === 'PICKUP')
                          .map((address) => ({
                            label: formatAddressWithName(address),
                            value: address.id,
                          }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Button
                      type="primary"
                      onClick={() => setCreateOriginAddressDrawerOpen(true)}
                      style={{ marginTop: 5 }}
                    >
                      <PlusOutlined />
                    </Button>
                  </Col>
                </Row>
                {needsToDisplayDaysAvailable('origin') && (
                  <Row gutter={16} align={'middle'}>
                    <Col span={24}>
                      <Alert
                        message={`Días disponibles para esta dirección: ${needsToDisplayDaysAvailable('origin')}`}
                        type="warning"
                        showIcon
                        style={{ marginBottom: 15 }}
                      />
                    </Col>
                  </Row>
                )}
                <Divider>Dirección de recolección</Divider>
                <Row gutter={16} align={'middle'}>
                  <Col span={5}>
                    <Form.Item name="needsPickup" label="Necesita recolección?">
                      <Radio.Group>
                        <Radio value={true}>Sí</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  {needsPickupCheckbox && (
                    <>
                      <Col span={16}>
                        <Form.Item
                          name={'pickupAddressId'}
                          label="Dirección de recolección"
                          rules={[{ required: true, message: 'Selecciona una dirección' }]}
                          initialValue={newlyCreatedPickupAddressId}
                        >
                          <Select
                            style={{ width: '100%' }}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={client?.addresses
                              .filter((address) => address.addressType === 'PICKUP')
                              .map((address) => ({
                                label: formatAddressWithName(address),
                                value: address.id,
                              }))}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Button
                          type="primary"
                          onClick={() => setCreatePickupAddressDrawerOpen(true)}
                          style={{ marginTop: 5 }}
                        >
                          <PlusOutlined />
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
                {needsToDisplayDaysAvailable('pickup') && (
                  <Row gutter={16} align={'middle'}>
                    <Col span={24}>
                      <Alert
                        message={`Días disponibles para esta dirección: ${needsToDisplayDaysAvailable('pickup')}`}
                        type="warning"
                        showIcon
                        style={{ marginBottom: 15 }}
                      />
                    </Col>
                  </Row>
                )}
                {needsPickupCheckbox && pickupAddressId && (
                  <Row gutter={16} align={'middle'}>
                    <Col span={5}>
                      <Form.Item name="clientIsContactForPickupAddress" label="Usar cliente como contacto?">
                        <Radio.Group>
                          <Radio value={true}>Sí</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    {!clientIsContactForPickupAddress && (
                      <>
                        <Col span={16}>
                          <Form.Item
                            name={'pickupAddressContactId'}
                            label="Contacto de recolección"
                            rules={[{ required: true, message: 'Selecciona un contacto' }]}
                            initialValue={newlyCreatedPickupContactId}
                          >
                            <Select
                              style={{ width: '100%' }}
                              showSearch
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={client?.contacts.map((contact) => ({
                                label: `${contact.name} / ${contact.phoneNumber}`,
                                value: contact.id,
                              }))}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Button
                            type="primary"
                            onClick={() => setCreatePickupContactDrawerOpen(true)}
                            style={{ marginTop: 5 }}
                          >
                            <PlusOutlined />
                          </Button>
                        </Col>
                      </>
                    )}
                  </Row>
                )}

                <Divider>Dirección de destino</Divider>
                <Row gutter={16} align={'middle'}>
                  <Col span={21}>
                    <Form.Item
                      name={'deliveryAddressId'}
                      label="Dirección de entrega"
                      rules={[{ required: true, message: 'Selecciona una dirección' }]}
                      initialValue={newlyCreatedDeliveryAddressId}
                    >
                      <Select
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={client?.addresses
                          .filter((address) => address.addressType === 'DELIVERY')
                          .map((address) => ({
                            label: formatAddressWithName(address),
                            value: address.id,
                          }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Button
                      type="primary"
                      onClick={() => setCreateDeliveryAddressDrawerOpen(true)}
                      style={{ marginTop: 5 }}
                    >
                      <PlusOutlined />
                    </Button>
                  </Col>
                </Row>
                {needsToDisplayDaysAvailable('delivery') && (
                  <Row gutter={16} align={'middle'}>
                    <Col span={24}>
                      <Alert
                        message={`Días disponibles para esta dirección: ${needsToDisplayDaysAvailable('delivery')}`}
                        type="warning"
                        showIcon
                        style={{ marginBottom: 15 }}
                      />
                    </Col>
                  </Row>
                )}
                <Row gutter={16} align={'middle'}>
                  <Col span={21}>
                    <Form.Item
                      name={'deliveryAddressContactId'}
                      label="Contacto de entrega"
                      rules={[{ required: true, message: 'Selecciona un contacto' }]}
                      initialValue={newlyCreatedDeliveryContactId}
                    >
                      <Select
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={client?.contacts.map((contact) => ({
                          label: `${contact.name} / ${contact.phoneNumber}`,
                          value: contact.id,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Button
                      type="primary"
                      onClick={() => setCreateDeliveryContactDrawerOpen(true)}
                      style={{ marginTop: 5 }}
                    >
                      <PlusOutlined />
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Form>
    </>
  );

  const columns: ColumnsType<PackageItem> = [
    {
      title: '#',
      dataIndex: 'count',
      align: 'center',
    },
    {
      title: 'Ancho',
      dataIndex: 'dimensionsW',
    },
    {
      title: 'Largo',
      dataIndex: 'dimensionsL',
    },
    {
      title: 'Altura',
      dataIndex: 'dimensionsH',
    },
    {
      title: 'Peso',
      dataIndex: 'weight',
      align: 'right',
    },
    {
      title: 'Precio total',
      dataIndex: 'totalPrice',
      align: 'right',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      align: 'right',
      render: (_, record) => (
        <Space size={'middle'}>
          <EditOutlined
            onClick={() => {
              setPreselectedPackage(record);
              setTimeout(() => {
                setAddPackageDrawerOpen(true);
              }, 10);
            }}
          />
          <DeleteTwoTone twoToneColor={'#cf1322'} onClick={() => handlePackageRemove(record)} />
        </Space>
      ),
    },
  ];

  const step2 = (
    <>
      <>
        <Row>
          <Col span={24}>
            <Table
              rowClassName={() => 'override-row-padding-8'}
              pagination={false}
              columns={columns}
              dataSource={packageItems}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col span={24}>
            <Button type="primary" onClick={() => setAddPackageDrawerOpen(true)} block icon={<PlusOutlined />}>
              Agregar paquete
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form
              form={formSecondStep}
              initialValues={{
                isFragile: false,
                needsPriceRevision: false,
                needsInsurance: false,
              }}
              layout="vertical"
              name="create-service-step-2-form"
              scrollToFirstError
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name={'isFragile'}
                    label="Mercancía frágil?"
                    rules={[
                      {
                        required: true,
                        message: 'Selecciona si el paquete contiene mercancia frágil',
                        type: 'boolean',
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>Sí</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={'needsPriceRevision'}
                    label="Necesita revisión de precio?"
                    rules={[
                      {
                        required: true,
                        message: 'Selecciona si necesita revisión de precio?',
                        type: 'boolean',
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>Sí</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name={'needsInsurance'}
                        label="Necesita seguro?"
                        rules={[
                          {
                            required: true,
                            message: 'Selecciona si el envío necesita seguro',
                            type: 'boolean',
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={true}>Sí</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    {needsInsuranceCheckbox && (
                      <Col span={24}>
                        <Form.Item
                          name={'merchValueForInsurance'}
                          label="Valor de la mercancía declarado"
                          rules={[
                            {
                              required: true,
                              message: 'Ingresa el valor de la mercancía declarado',
                              type: 'number',
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            formatter={(value) => `$ ${value!}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => (value ? value.replace(/\$\s?|(,*)/g, '') : '')}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name={'notes'} label="Notas">
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
      <AddPackageDrawer
        open={addPackageDrawerOpen}
        onCancel={() => {
          setPreselectedPackage(undefined);
          setAddPackageDrawerOpen(false);
        }}
        onCreate={handlePackageAdd}
        preselectedPackage={preselectedPackage}
        calculatePriceBasedOnAddressess={calculatePriceBasedOnAddressess}
      />
    </>
  );

  /**
   poner: 
   - precio total del envio desglodado
   - precio base del envio, es una suma de todos los precios base de todos los Paquetes
   - precio extra del envio, es una suma de todos los precios extra de todos los Paquetes 
   - precio total del envio, es una suma de todos los precios totales de todos los Paquetes
   - precio del seguro, en caso de que se haya seleccionado
   - precio mas iva del envio, es una suma del precio total del envio mas el iva 
   - si es un cliente sin credito ni guias prepagadas, preguntar tipo de pago:
      - Contado: se paga en el momento y mostrar un select de las formas de pago del SATse paga en el momento de la entrega y mostrar un select de las formas de pago del SAT
         * Solo para cuando llevan el paquete ahi mismo
      - Por pagar: 
      ** Que pasa cuando hablan por telefono y es un cliente sin guia prepagada ni credito? como se paga?
   - si es un cliente con credito:
        - Simplemente mostrar los precios y un alert.Info de que se metera a su credito
   - si es un cliente con guias prepagadas:
        - Preguntar si se desea consumir una guia prepagada
          * Si no, se puede con credito? 
          * Si no, se puede pagar en el momento?
   */

  const step3 = (
    <Row style={{ marginTop: 20 }} className="custom-timeline-container">
      <Col span={12}>
        <Timeline items={[...buildTimelineItems()]} />
      </Col>
      <Col span={12} style={{ paddingLeft: 10, borderLeft: '1px solid #f0f0f0' }}>
        {client && (
          <>
            <>
              <Row>
                <Col span={24}>
                  <Row>
                    <Divider>Desgloce de precios</Divider>
                  </Row>
                  <Row>
                    <Col span={20}>Precio base</Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                      {formatMoneyToMXN(packageItems.reduce((acc, item) => acc + item.basePrice, 0))}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>Kilos extra</Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                      {formatMoneyToMXN(packageItems.reduce((acc, item) => acc + item.extraPrice, 0))}
                    </Col>
                  </Row>
                  {needsInsuranceCheckbox && (
                    <Row>
                      <Col span={20}>Seguro</Col>
                      <Col span={4} style={{ textAlign: 'right' }}>
                        {formatMoneyToMXN(calculateInsurancePrice())}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={20}>Subtotal</Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                      {formatMoneyToMXN(
                        packageItems.reduce((acc, item) => acc + item.basePrice, 0) +
                          packageItems.reduce((acc, item) => acc + item.extraPrice, 0) +
                          calculateInsurancePrice(),
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>IVA</Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                      {formatMoneyToMXN(
                        (packageItems.reduce((acc, item) => acc + item.basePrice, 0) +
                          packageItems.reduce((acc, item) => acc + item.extraPrice, 0) +
                          calculateInsurancePrice()) *
                          0.16,
                      )}
                    </Col>
                  </Row>
                  <Row style={{ fontWeight: 'bold' }}>
                    <Col span={20}>Total</Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                      {formatMoneyToMXN(
                        (packageItems.reduce((acc, item) => acc + item.basePrice, 0) +
                          packageItems.reduce((acc, item) => acc + item.extraPrice, 0) +
                          calculateInsurancePrice()) *
                          1.16,
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider />
              {shipmentType === 'LOCAL_LOCAL' ? (
                <Row>
                  <Col span={24}>
                    <Form form={formThirdStep} layout="vertical" name="create-service-step-3-form" scrollToFirstError>
                      <Form.Item
                        name="paymentType"
                        label="Tipo de pago"
                        rules={[{ required: true, message: 'Selecciona un tipo de pago' }]}
                      >
                        <Radio.Group>
                          <Radio value="CASH">Contado</Radio>
                          <Radio value="PAY_LATER">Por cobrar</Radio>
                        </Radio.Group>
                      </Form.Item>

                      {paymentType === 'CASH' && (
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              name="formaPagoId"
                              label="Forma de Pago"
                              rules={[
                                {
                                  required: true,
                                  message: 'Selecciona una forma de pago',
                                },
                              ]}
                            >
                              <SearchFormaPago />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}

                      {paymentType === 'PAY_LATER' && (
                        <>
                          <Row>
                            <Form.Item
                              name="payLaterNeedsInvoice"
                              label="Necesita factura a nombre la persona que va a pagar?"
                              rules={[{ required: true, message: 'Selecciona si necesita factura' }]}
                            >
                              <Radio.Group>
                                <Radio value={true}>Si</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Row>

                          {payLaterNeedsInvoice && (
                            <>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="payLaterName"
                                    label="Nombre"
                                    rules={[{ required: true, message: 'Ingresa el nombre' }]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="payLaterRfc"
                                    label="RFC"
                                    normalize={(value) => (value || '').toUpperCase()}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Ingresa un RFC válido',
                                        pattern:
                                          /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/i,
                                      },
                                    ]}
                                    style={{ marginBottom: 0 }}
                                  >
                                    <Input />
                                  </Form.Item>
                                  <Button type="link" onClick={handleClickRFCPublico}>
                                    RFC público en general.
                                  </Button>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="payLaterZipCode"
                                    label="Código Postal"
                                    rules={[{ required: true, message: 'Ingresa el código postal' }]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="payLaterRegimenFiscalId"
                                    label="Régimen Fiscal"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Selecciona un régimen fiscal',
                                      },
                                    ]}
                                  >
                                    <SearchRegimenFiscal />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </Form>
                  </Col>
                </Row>
              ) : (
                <>
                  {client.credit ? (
                    <Row>
                      <Col span={24}>
                        <Alert message="Se cargará al crédito del cliente" type="info" showIcon />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col span={24}>
                        <Form
                          form={formThirdStep}
                          layout="vertical"
                          name="create-service-step-3-form"
                          scrollToFirstError
                        >
                          <Form.Item
                            name="paymentType"
                            label="Tipo de pago"
                            rules={[{ required: true, message: 'Selecciona un tipo de pago' }]}
                          >
                            <Radio.Group>
                              <Radio value="CASH">Contado</Radio>
                              <Radio value="PAY_LATER">Por cobrar</Radio>
                            </Radio.Group>
                          </Form.Item>

                          {paymentType === 'CASH' && (
                            <Row>
                              <Col span={24}>
                                <Form.Item
                                  name="formaPagoId"
                                  label="Forma de Pago"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Selecciona una forma de pago',
                                    },
                                  ]}
                                >
                                  <SearchFormaPago />
                                </Form.Item>
                              </Col>
                            </Row>
                          )}

                          {paymentType === 'PAY_LATER' && (
                            <>
                              <Row>
                                <Form.Item
                                  name="payLaterNeedsInvoice"
                                  label="Necesita factura a nombre la persona que va a pagar?"
                                  rules={[{ required: true, message: 'Selecciona si necesita factura' }]}
                                >
                                  <Radio.Group>
                                    <Radio value={true}>Si</Radio>
                                    <Radio value={false}>No</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Row>

                              {payLaterNeedsInvoice && (
                                <>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="payLaterName"
                                        label="Nombre"
                                        rules={[{ required: true, message: 'Ingresa el nombre' }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="payLaterRfc"
                                        label="RFC"
                                        normalize={(value) => (value || '').toUpperCase()}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Ingresa un RFC válido',
                                            pattern:
                                              /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/i,
                                          },
                                        ]}
                                        style={{ marginBottom: 0 }}
                                      >
                                        <Input />
                                      </Form.Item>
                                      <Button type="link" onClick={handleClickRFCPublico}>
                                        RFC público en general.
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="payLaterZipCode"
                                        label="Código Postal"
                                        rules={[{ required: true, message: 'Ingresa el código postal' }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="payLaterRegimenFiscalId"
                                        label="Régimen Fiscal"
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Selecciona un régimen fiscal',
                                          },
                                        ]}
                                      >
                                        <SearchRegimenFiscal />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </>
                          )}
                        </Form>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          </>
        )}
      </Col>
    </Row>
  );

  const steps = [
    {
      title: 'Datos generales',
    },
    {
      title: 'Paquetes',
    },
    {
      title: 'Resumen',
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <Modal
      open={open}
      title="Crear servicio"
      style={{ top: 20 }}
      width={800}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="cancelBtn" onClick={handleCancel} style={{ float: 'left' }}>
          Cancelar
        </Button>,
        <div key="btnGroup">
          {currentStep > 0 && (
            <Button key="backBtn" style={{ margin: '0 8px' }} onClick={() => prev()}>
              Anterior
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button key="nextBtn" type="primary" onClick={() => next()}>
              Siguiente
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
              Guardar
            </Button>
          )}
        </div>,
      ]}
    >
      <Steps current={currentStep} items={items} />
      <div>
        <div style={{ display: currentStep === 0 ? 'block' : 'none', marginTop: 10 }}>{step1}</div>
        <div style={{ display: currentStep === 1 ? 'block' : 'none', marginTop: 10 }}>{step2}</div>
        <div style={{ display: currentStep === 2 ? 'block' : 'none', marginTop: 10 }}>{step3}</div>
      </div>

      <CreateAddressDrawer
        open={createPickupAddressDrawerOpen}
        option={{ label: 'Recolección / Origen', value: 'PICKUP' }}
        clientId={clientId}
        onCancel={() => setCreatePickupAddressDrawerOpen(false)}
        onCreate={handlePickupAddressCreate}
        clientRfc={client?.rfc}
      />

      <CreateAddressDrawer
        open={createOriginAddressDrawerOpen}
        option={{ label: 'Recolección / Origen', value: 'PICKUP' }}
        clientId={clientId}
        onCancel={() => setCreateOriginAddressDrawerOpen(false)}
        onCreate={handleOriginAddressCreate}
        clientRfc={client?.rfc}
      />

      <CreateAddressDrawer
        open={createDeliveryAddressDrawerOpen}
        option={{ label: 'Entrega', value: 'DELIVERY' }}
        clientId={clientId}
        onCancel={() => setCreateDeliveryAddressDrawerOpen(false)}
        onCreate={handleDeliveryAddressCreate}
      />

      <CreateContactDrawer
        open={createPickupContactDrawerOpen}
        clientId={clientId}
        onCancel={() => setCreatePickupContactDrawerOpen(false)}
        onCreate={handlePickupContactCreate}
      />

      <CreateContactDrawer
        open={createDeliveryContactDrawerOpen}
        clientId={clientId}
        onCancel={() => setCreateDeliveryContactDrawerOpen(false)}
        onCreate={handleDeliveryContactCreate}
      />

      <CreateClientDrawer
        open={createClientDrawerOpen}
        onCancel={() => setCreateClientDrawerOpen(false)}
        onCreate={handleClientCreate}
      />
    </Modal>
  );
}
