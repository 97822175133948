import { CloseCircleOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import { changeNotReadyToDeliver } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';

export default function EditStatusNotReadyModal({
  record,
}: DataTableActionProps<components['schemas']['WarehouseCondensed']>) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['ChangeNotReadyToDeliverRequest']) =>
      changeNotReadyToDeliver(record.id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getWarehouseList'] });
      message.success('Estatus cambiado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });
  const handleButtonClick = async () => {
    try {
      const { notes } = await form.validateFields();
      mutation.mutate({
        notes: `Cambió el estatus de ${record.status} a No listo para entregar (en almacén). Motivo: ${
          notes as string
        }`,
      });
    } catch (e) {}
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  // Check if the shipment status is READY_TO_DELIVER to allow the change status
  if (record.status !== 'Listo para entrega') return <></>;

  return (
    <React.Fragment>
      <Modal
        open={open}
        title="No listo para entregar"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button type="primary" key="submit" loading={mutation.isLoading} onClick={handleButtonClick}>
            Guardar
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="edit-status-not-ready" scrollToFirstError>
          <Form.Item
            name={'notes'}
            label="Por qué se cambia el estatus?"
            rules={[
              {
                required: true,
                message: 'Ingresa un motivo',
                type: 'string',
              },
            ]}
          >
            <TextArea style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
      <CloseCircleOutlined onClick={() => setOpen(true)} />
    </React.Fragment>
  );
}
