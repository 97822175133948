import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Input, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { getShipmentPickupList } from '../../api';
import { components, paths } from '../../api/schema';
import { AvailableEntity } from '../../shared/commentsLogger/CommentsLogger';
import DataTable, {
  ColumnsType,
  DataTableFilterCallback,
  DataTableOrderCallback,
  serializeFilters,
  serializeOrders,
} from '../../shared/dataTable/DataTable';
import useDebounce from '../../shared/hooks/useDebounce';
import PanelSkeleton from '../../shared/skeletons/PanelSkeleton';
import CreateService from './CreateService';
import { LabelDrawer } from './pickupPanelActions/LabelDrawer';
import EditZoneModal from './pickupPanelActions/EditZoneModal';
import EditPickupDateModal from './pickupPanelActions/EditPickupDateModal';
import { DeleteAction } from './pickupPanelActions/DeleteAction';
import useConditionalRefresh from '../../shared/hooks/useConditionalRefresh';

const pickupsTableColumns: ColumnsType<components['schemas']['PickupCondensed']> = [
  {
    title: 'Fecha creación',
    dataIndex: 'createdAt',
    key: 'createdAt',
    columnType: 'date',
  },
  {
    title: 'Número de rastreo',
    dataIndex: 'shipmentTrackingNumber',
    key: 'shipmentTrackingNumber',
    columnType: 'text',
  },
  {
    title: 'Fecha programada de recolección',
    dataIndex: 'scheduledPickupDate',
    key: 'scheduledPickupDate',
    columnType: 'date',
  },
  {
    title: 'Cliente',
    dataIndex: 'clientName',
    key: 'clientName',
    columnType: 'text',
  },
  {
    title: 'Zona de recolección',
    dataIndex: 'pickupZone',
    key: 'pickupZone',
    columnType: 'text',
  },
  {
    title: 'Dirección de recolección',
    dataIndex: 'pickupAddress',
    key: 'pickupAddress',
    columnType: 'text',
  },
  {
    title: 'Peso total',
    dataIndex: 'totalWeight',
    key: 'totalWeight',
    columnType: 'text',
    hideFilter: true,
  },
  {
    title: 'Total de paquetes',
    dataIndex: 'totalPackages',
    key: 'totalPackages',
    columnType: 'text',
    hideFilter: true,
  },
  {
    title: 'Estatus',
    dataIndex: 'status',
    key: 'status',
    columnType: 'enum',
    customCellClass: (r) => {
      if (r.status === 'Programado') {
        return 'status-yellow text-center';
      } else if (r.status === 'Recolectado') {
        return 'status-green text-center';
      }
    },
  },
];
const PAGE_SIZE = 50;

export default function PickupPanel() {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>();
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/shipment/pickup/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { isLoading, isError, data, isFetching, refetch } = useQuery({
    queryKey: ['getShipmentPickupList', params],
    queryFn: () => getShipmentPickupList(params),
  });

  useConditionalRefresh(60000, refetch);

  useEffect(() => {
    setParams((state) => ({ ...state, search: debouncedSearch }));
  }, [debouncedSearch]);

  const handleFiltering = (filter: DataTableFilterCallback) => {
    setParams((state) => ({ ...state, filter: serializeFilters(params.filter, filter) }));
  };

  const handleOrdering = (order: DataTableOrderCallback) => {
    setParams((state) => ({ ...state, order: serializeOrders(params.order, order) }));
  };

  const handlePageChange = (page: number) => {
    setParams((state) => ({ ...state, page }));
  };

  return (
    <Row>
      <Col span={24}>
        <Space size={'middle'}>
          <Button type="primary" onClick={() => setCreateModalOpen(true)}>
            Crear servicio
          </Button>
          <Input
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Space>
      </Col>
      {isLoading || isError || isFetching ? (
        <PanelSkeleton />
      ) : (
        <>
          <Col span={24} style={{ marginTop: '10px' }}>
            <DataTable<components['schemas']['PickupCondensed']>
              handleFilters={handleFiltering}
              handleOrder={handleOrdering}
              handlePageChange={handlePageChange}
              columns={pickupsTableColumns}
              results={data.results}
              activeFilters={params.filter}
              activeOrders={params.order}
              paginationData={{
                pageSize: PAGE_SIZE,
                count: data.count,
                currentPage: data.currentPage,
              }}
              commentsLogger={{
                entity: AvailableEntity.SHIPMENT,
                queryKeyToInvalidate: 'getShipmentPickupList',
                entityKey: 'shipmentId',
              }}
              onRowClick={(record) => {
                setSelectedUserId(record.id);
                setDetailModalOpen(true);
              }}
              actions={[LabelDrawer, EditZoneModal, EditPickupDateModal, DeleteAction]}
            />
          </Col>

          {/* <UserDetail userId={selectedUserId} open={detailModalOpen} onCancel={() => setDetailModalOpen(false)} /> */}
          <CreateService
            open={createModalOpen}
            onCancel={() => setCreateModalOpen(false)}
            onCreate={() => setCreateModalOpen(false)}
          />
        </>
      )}
    </Row>
  );
}
