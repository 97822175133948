import { MinusCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, Col, Divider, Form, Input, Modal, Radio, Row, Select } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { createClient, getDefaultsPubGeneral } from '../../api';
import { components } from '../../api/schema';
import ReadonlyInput from '../../shared/form/ReadonlyInput';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';
import SearchCFDIUse from '../../shared/sat/SearchCfdiUse';
import SearchFormaPago from '../../shared/sat/SearchFormaPago';
import SearchPaymentMethod from '../../shared/sat/SearchPaymentMethod';
import SearchRegimenFiscal from '../../shared/sat/SearchRegimenFiscal';
import SelectZipCode from '../../shared/zipCode/SelectZipCode';
import AddressInMapDrawer from './AddressInMapDrawer';

interface CreateClientProps {
  open: boolean;
  onCreate?: () => void;
  onCancel: () => void;
}

export default function CreateClient({ open, onCreate, onCancel }: CreateClientProps) {
  const [showAddressInMapDrawer, setShowAddressInMapDrawer] = useState<boolean>(false);
  const [selectedAddressKey, setSelectedAddressKey] = useState<number | undefined>();
  const [coordinatesArray, setCoordinatesArray] = useState<Array<{ lat: number; lng: number }>>([]);
  const [disabledByRFCPublico, setDisabledByRFCPublico] = useState<boolean>(false);
  const [disabledByCredito, setDisabledByCredito] = useState<boolean>(false);
  const [disabledByPersonaFisica, setDisabledByPersonaFisica] = useState<boolean>(false);
  const [showAddressSection, setShowAddressSection] = useState<boolean>(false);
  const [showSelectInMap, setShowSelectInMap] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreateClientRequest']) => createClient(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getClients'] });
      message.success('Cliente creado correctamente');
      onCreate?.();
      setSelectedZipCodes([]);
    },
    onError: (e: AxiosError<components['schemas']['BaseError400']>) => {
      if (e?.response?.data.httpStatus === 400) {
        if (
          e.response.data.error ===
          'La dirección no es válida, verifica que la calle y número sean correctos y pertenezcan al código postal seleccionado'
        ) {
          message.error('La dirección no es válida, selecciona una ubicación en el mapa');
          handleShowAddressInMapDrawer(0);
          setShowSelectInMap(true);
        } else {
          message.error(e.response.data.error);
        }
      } else {
        message.error('Ocurrio un error, vuelve a intentarlo más tarde');
      }
    },
  });

  const { data: data2 } = useQuery({
    queryKey: ['getDefaultsPubGeneral'],
    queryFn: () => getDefaultsPubGeneral(),
  });

  const [selectedZipCodes, setSelectedZipCodes] = useState<
    Array<{ key: React.Key; zipCode: components['schemas']['ZipcodeCondensed'] }>
  >([]);
  const [form] = Form.useForm<components['schemas']['CreateClientRequest']>();

  useResetFormOnCloseModal({ form, open });

  const handleSelectZipCode = (key: React.Key, zipCode: components['schemas']['ZipcodeCondensed']) => {
    setSelectedZipCodes((zipCodes) => {
      const tmpState = zipCodes.filter((zip) => zip.key !== key);
      return [...tmpState, { key, zipCode }];
    });
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      values.addresses?.forEach((_, index) => {
        if (coordinatesArray[index] !== undefined) {
          const oCoordinates = {
            lat: coordinatesArray[index].lat,
            lng: coordinatesArray[index].lng,
          };
          values.addresses![index].coordinates = oCoordinates;
        }
      });
      mutation.mutate(values);
    } catch (e) {}
  };

  const handleShowAddressInMapDrawer = (key: number) => {
    setSelectedAddressKey(key);
    setShowAddressInMapDrawer(!showAddressInMapDrawer);
  };

  const handleSendObject = (p: { id: number; latLng: { lat: number; lng: number } }) => {
    const arr = [...coordinatesArray];

    if (arr[p.id] !== undefined) {
      arr[p.id] = p.latLng;
      setCoordinatesArray([...arr]);
    } else {
      setCoordinatesArray([...coordinatesArray.slice(0, p.id), p.latLng, ...coordinatesArray.slice(p.id)]);
    }
  };

  const removeItemInArray = (index: number) => {
    const arr = [...coordinatesArray];
    arr.splice(index, 1);
    setCoordinatesArray([...arr]);
  };

  const rfcWatch = Form.useWatch('rfc', form);
  const isCreditWatch = Form.useWatch('credit', form);
  const rfcString: string = rfcWatch !== undefined ? rfcWatch : '';

  useEffect(() => {
    switch (rfcString.length) {
      case 12:
        form.setFieldValue('tipoPersona', 'MORAL');
        break;
      case 13:
        form.setFieldValue('tipoPersona', 'FISICA');
        break;
    }

    if (rfcString === 'XAXX010101000') {
      setShowAddressSection(false);
      setDisabledByRFCPublico(true);
      form.setFieldValue('tipoPersona', 'FISICA');
      form.setFieldValue('cfdiUseId', data2?.cfdiUseId);
      form.setFieldValue('regimenFiscalId', data2?.regimenFiscalId);
      form.setFieldValue('paymentMethodId', data2?.paymentMethodId);
      form.validateFields();
    } else {
      setDisabledByRFCPublico(false);
      setShowAddressSection(true);
      form.setFieldValue('cfdiUseId', '');
      form.setFieldValue('regimenFiscalId', '');
      form.setFieldValue('paymentMethodId', '');
      form.validateFields();
    }
  }, [rfcWatch]);

  useEffect(() => {
    if (isCreditWatch) {
      form.setFieldValue('formaPagoId', data2?.formaPagoId);
      setDisabledByCredito(true);
    } else {
      form.setFieldValue('formaPagoId', '');
      setDisabledByCredito(false);
    }
  }, [isCreditWatch]);

  const handleClickRFCPublico = () => {
    if (rfcString === 'XAXX010101000') {
      form.setFieldValue('rfc', '');
      form.validateFields(['rfc']);
    } else {
      form.setFieldValue('rfc', 'XAXX010101000');
      form.validateFields(['rfc']);
    }
  };

  const tipoPersonaWatch = Form.useWatch('tipoPersona', form);
  const tipoPersonaString: string = tipoPersonaWatch || '';

  useEffect(() => {
    switch (tipoPersonaString) {
      case 'FISICA':
        setDisabledByPersonaFisica(true);
        form.setFieldValue('ivaRetention', false);

        break;
      case 'MORAL':
        setDisabledByPersonaFisica(false);
        break;
      default:
        break;
    }
  }, [tipoPersonaWatch]);

  const strAddressSection = (
    <React.Fragment>
      <Divider>Direcciones</Divider>
      <Row>
        <Col span={24}>
          <Form.List name="addresses">
            {(fields, { remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Row gutter={16} align="middle">
                      <Col span={23}>
                        <Row gutter={16}>
                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              name={[name, 'addressType']}
                              label="Tipo de dirección"
                              rules={[{ required: true, message: 'Selecciona el tipo de dirección' }]}
                            >
                              <Select style={{ width: '100%' }} options={[{ value: 'FISCAL', label: 'Fiscal' }]} />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name={[name, 'zipCodeStr']}
                              label="Código postal"
                              rules={[{ required: true, message: 'Selecciona un código postal' }]}
                            >
                              {/* <SelectZipCode
                                onSelected={(selectedZipCode) => handleSelectZipCode(key, selectedZipCode)}
                              /> */}
                              <Input placeholder="Código postal..." />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'name']}
                              label="Nombre de la dirección"
                              rules={[{ required: true, message: 'Ingresa el nombre de la dirección' }]}
                            >
                              <Input placeholder="Casa..." />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={9}>
                            <Form.Item
                              {...restField}
                              name={[name, 'street']}
                              label="Calle"
                              rules={[{ required: true, message: 'Ingresa el nombre de la calle' }]}
                              style={{ marginBottom: 0 }}
                            >
                              <Input />
                            </Form.Item>
                            {showSelectInMap && (
                              <Button type="link" onClick={() => handleShowAddressInMapDrawer(name)} style={{}}>
                                Ingresar dirección en Mapa.
                              </Button>
                            )}
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              rules={[{ required: true, message: 'Ingresa el número exterior' }]}
                              name={[name, 'exteriorNumber']}
                              label="Número exterior"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={9}>
                            <Form.Item {...restField} name={[name, 'interiorNumber']} label="Número interior">
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        {/* <Row gutter={16} style={{ marginBottom: 20 }}>
                          <Col span={6}>
                            <ReadonlyInput
                              value={selectedZipCodes.find((zipCode) => zipCode.key === key)?.zipCode.district ?? ''}
                              label="Colonia"
                              removeBoldLabel
                            />
                          </Col>
                          <Col span={6}>
                            <ReadonlyInput
                              value={selectedZipCodes.find((zipCode) => zipCode.key === key)?.zipCode.city ?? ''}
                              label="Ciudad"
                              removeBoldLabel
                            />
                          </Col>
                          <Col span={6}>
                            <ReadonlyInput
                              value={selectedZipCodes.find((zipCode) => zipCode.key === key)?.zipCode.municipio ?? ''}
                              label="Municipio"
                              removeBoldLabel
                            />
                          </Col>
                          <Col span={6}>
                            <ReadonlyInput
                              value={selectedZipCodes.find((zipCode) => zipCode.key === key)?.zipCode.state ?? ''}
                              label="Estado"
                              removeBoldLabel
                            />
                          </Col>
                        </Row> */}
                      </Col>
                      <Col span={1}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            onClick={() => {
                              removeItemInArray(name);
                              remove(name);
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
                {/* <Form.Item>
                  <Button disabled type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Agregar dirección
                  </Button>
                </Form.Item> */}
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </React.Fragment>
  );

  return (
    <Modal
      open={open}
      title="Crear cliente"
      style={{ top: 20 }}
      width={800}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onCancel();
            setSelectedZipCodes([]);
          }}
        >
          Cancelar
        </Button>,
        <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
          Guardar
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="create-user-form"
        scrollToFirstError
        autoComplete="off"
        initialValues={{
          addresses: [{ addressType: 'FISCAL' }],
          contacts: [{}],
          credit: false,
          ivaRetention: false,
          sendInvoice: false,
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="comercialName" label="Nombre comercial">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="phoneNumber" label="Teléfono" rules={[{ required: true, message: 'Ingresa el teléfono' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Correo electrónico"
              name="emailAddress"
              rules={[
                {
                  required: true,
                  message: 'Ingresa un correo electrónico',
                  type: 'email',
                },
              ]}
            >
              <Input placeholder="ejemplo@correo.com" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="officeHours" label="Horario de oficina">
              <Input />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Form.Item name="credit" label="Crédito" valuePropName="checked">
              <Checkbox>Sí</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Divider>Fiscal</Divider>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="rfc"
              label="RFC"
              normalize={(value) => (value || '').toUpperCase()}
              rules={[
                {
                  required: true,
                  message: 'Ingresa un RFC válido',
                  pattern:
                    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/i,
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
            <Button type="link" onClick={handleClickRFCPublico}>
              RFC público en general.
            </Button>
          </Col>

          <Col span={8}>
            <Form.Item
              name="cfdiUseId"
              label="Uso de CFDI"
              rules={[
                {
                  required: true,
                  message: 'Selecciona un uso de CFDI',
                },
              ]}
            >
              <SearchCFDIUse disabled={disabledByRFCPublico} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="regimenFiscalId"
              label="Régimen Fiscal"
              rules={[
                {
                  required: true,
                  message: 'Selecciona un régimen fiscal',
                },
              ]}
            >
              <SearchRegimenFiscal disabled={disabledByRFCPublico} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="paymentMethodId"
              label="Método de pago"
              rules={[
                {
                  required: true,
                  message: 'Selecciona un método de pago',
                },
              ]}
            >
              <SearchPaymentMethod disabled={disabledByRFCPublico} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="formaPagoId"
              label="Forma de Pago"
              rules={[
                {
                  required: true,
                  message: 'Selecciona una forma de pago',
                },
              ]}
            >
              <SearchFormaPago disabled={disabledByCredito} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="tipoPersona"
              label="Tipo de persona"
              rules={[{ required: true, message: 'Selecciona el tipo de persona' }]}
            >
              <Radio.Group disabled={disabledByRFCPublico}>
                <Radio value="FISICA">Física</Radio>
                <Radio value="MORAL">Moral</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="ivaRetention" label="Retener IVA" valuePropName="checked">
              <Checkbox disabled={disabledByPersonaFisica}>Sí</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="sendInvoice" label="Envíar factura" valuePropName="checked">
              <Checkbox>Sí</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {showAddressSection && strAddressSection}

        {/* <Divider>Contactos</Divider>
        <Row>
          <Col span={24}>
            <Form.List name="contacts">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key}>
                      <Row gutter={16} align="middle">
                        <Col span={23}>
                          <Row gutter={16}>
                            <Col span={12}>
                              <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                label="Nombre"
                                rules={[{ required: true, message: 'Ingresa el nombre' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                {...restField}
                                name={[name, 'phoneNumber']}
                                label="Teléfono"
                                rules={[{ required: true, message: 'Ingresa el teléfono' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={1}>
                          {fields.length > 1 ? <MinusCircleOutlined onClick={() => remove(name)} /> : ''}
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Agregar contacto
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row> */}
      </Form>
      <AddressInMapDrawer
        selectedAddressKey={selectedAddressKey}
        open={showAddressInMapDrawer}
        onCancel={() => setShowAddressInMapDrawer(false)}
        onSendObject={handleSendObject}
      />
    </Modal>
  );
}
