import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 960 540"
    preserveAspectRatio="none"
    style={{
      position: "absolute",
      zIndex: "-1",
      height: "100%",
      width: "100%",
    }}
    {...props}
  >
    <path fill="#FFF" d="M0 0h960v540H0z" />
    <path
      fill="#f2f1ff"
      d="M960 351c-27.5-19.5-55-39.1-79.7-53.5-24.7-14.4-46.7-23.6-73.3-32.5-26.6-8.8-57.8-17.3-75.4-36.6-17.6-19.3-21.7-49.5-40.9-72.9-19.3-23.4-53.6-39.9-69.7-64.7C604.8 66.1 606.9 33 609 0h351Z"
    />
    <path
      fill="#d7d6ff"
      d="M960 300.9c-23.6-16.8-47.1-33.6-68.3-45.9-21.2-12.3-40.1-20.3-62.8-27.9-22.8-7.5-49.6-14.8-64.7-31.3-15.1-16.6-18.6-42.5-35.1-62.5-16.4-20-45.9-34.2-59.7-55.4-13.8-21.3-12-49.6-10.3-77.9H960Z"
    />
    <path
      fill="#bbbcff"
      d="M960 250.7c-19.6-13.9-39.3-27.9-56.9-38.2-17.7-10.3-33.4-16.9-52.4-23.2-19-6.3-41.2-12.4-53.8-26.2-12.6-13.8-15.6-35.3-29.3-52-13.7-16.7-38.2-28.5-49.8-46.2-11.5-17.7-10-41.3-8.5-64.9H960Z"
    />
    <path
      fill="#9ca2ff"
      d="M960 200.6c-15.7-11.2-31.4-22.4-45.6-30.6-14.1-8.2-26.6-13.5-41.8-18.6-15.2-5-33-9.8-43.1-20.9-10.1-11-12.4-28.3-23.4-41.6-11-13.4-30.6-22.8-39.8-37-9.3-14.1-8.1-33-6.9-51.9H960Z"
    />
    <path
      fill="#7789ff"
      d="M960 150.4c-11.8-8.3-23.6-16.7-34.2-22.9-10.6-6.2-20-10.1-31.4-13.9-11.4-3.8-24.7-7.4-32.3-15.7-7.5-8.3-9.3-21.2-17.5-31.3-8.3-10-23-17-29.9-27.7-6.9-10.6-6-24.7-5.1-38.9H960Z"
    />
    <path
      fill="#4471ff"
      d="M960 100.3c-7.9-5.6-15.7-11.2-22.8-15.3-7-4.1-13.3-6.8-20.9-9.3-7.6-2.5-16.5-4.9-21.6-10.4-5-5.6-6.2-14.2-11.7-20.9-5.4-6.6-15.3-11.4-19.9-18.4-4.6-7.1-4-16.6-3.4-26H960Z"
    />
    <path
      fill="#06f"
      d="M960 50.1c-3.9-2.7-7.9-5.5-11.4-7.6-3.5-2.1-6.7-3.4-10.5-4.6-3.8-1.3-8.2-2.5-10.7-5.3-2.5-2.7-3.1-7-5.9-10.4-2.7-3.3-7.6-5.7-9.9-9.2-2.3-3.6-2-8.3-1.7-13H960Z"
    />
    <path
      fill="#f2f1ff"
      d="M0 189c28.5 15 57.1 30 82.6 42.9 25.5 12.8 47.9 23.5 77.9 30.1 30 6.6 67.5 9.3 87.7 29.8 20.2 20.6 23.1 59 25.5 90.2 2.4 31.2 4.2 55 17 80.1 12.8 25.1 36.6 51.5 60.3 77.9H0Z"
    />
    <path
      fill="#d7d6ff"
      d="M0 239.1c24.5 12.9 48.9 25.8 70.8 36.8 21.8 11 41.1 20.1 66.8 25.8 25.7 5.7 57.8 7.9 75.1 25.6 17.4 17.6 19.8 50.6 21.9 77.3 2 26.7 3.6 47.1 14.6 68.6 11 21.5 31.3 44.2 51.7 66.8H0Z"
    />
    <path
      fill="#bbbcff"
      d="M0 289.3c20.4 10.7 40.8 21.4 59 30.6 18.2 9.2 34.2 16.8 55.6 21.5 21.5 4.8 48.3 6.6 62.7 21.3 14.4 14.7 16.5 42.2 18.2 64.4 1.7 22.3 3 39.3 12.2 57.3 9.1 17.9 26.1 36.7 43 55.6H0Z"
    />
    <path
      fill="#9ca2ff"
      d="M0 339.4c16.3 8.6 32.6 17.2 47.2 24.5 14.5 7.4 27.4 13.5 44.5 17.2 17.1 3.8 38.6 5.3 50.1 17.1 11.6 11.7 13.2 33.7 14.6 51.5 1.3 17.8 2.4 31.4 9.7 45.8 7.4 14.3 20.9 29.4 34.5 44.5H0Z"
    />
    <path
      fill="#7789ff"
      d="M0 389.6c12.2 6.4 24.5 12.8 35.4 18.3 10.9 5.5 20.5 10.1 33.4 13 12.8 2.8 28.9 3.9 37.6 12.7 8.6 8.8 9.9 25.3 10.9 38.7 1 13.3 1.8 23.6 7.3 34.3 5.5 10.8 15.7 22.1 25.8 33.4H0Z"
    />
    <path
      fill="#4471ff"
      d="M0 439.7c8.2 4.3 16.3 8.6 23.6 12.3 7.3 3.6 13.7 6.7 22.3 8.6 8.5 1.9 19.2 2.6 25 8.5 5.8 5.9 6.6 16.9 7.3 25.8.7 8.9 1.2 15.7 4.9 22.8 3.6 7.2 10.4 14.8 17.2 22.3H0Z"
    />
    <path
      fill="#06f"
      d="M0 489.9c4.1 2.1 8.2 4.2 11.8 6.1 3.6 1.8 6.8 3.3 11.1 4.3 4.3.9 9.7 1.3 12.6 4.2 2.8 3 3.3 8.5 3.6 12.9.3 4.5.6 7.9 2.4 11.5 1.9 3.6 5.3 7.3 8.6 11.1H0Z"
    />
  </svg>
);
export default SvgComponent;
