import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Col, Form, Input, Modal, Row, Tooltip } from 'antd';
import { createUser } from '../../api';
import { components } from '../../api/schema';
import SelectArea from '../../shared/areas/SelectArea';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';
import SelectRole from '../../shared/roles/SelectRole';
import { useEffect, useState } from 'react';
import MultiZoneSelect from '../../shared/zone/MultiZoneSelect';
import { AxiosError } from 'axios';
import { CopyOutlined, ReloadOutlined } from '@ant-design/icons';

interface CreateUserProps {
  open: boolean;
  onCreate?: () => void;
  onCancel: () => void;
}

export default function CreateUser({ open, onCreate, onCancel }: CreateUserProps) {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tempPassword, setTempPassword] = useState<string>('');
  const [roleLabel, setRoleLabel] = useState<string | undefined>(undefined);
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreateUserRequest']) => createUser(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsers'] });
      message.success('Usuario creado correctamente');
      onCreate?.();
    },
    onError: (e: AxiosError<components['schemas']['BaseError400']>) => {
      if (
        e.response?.status === 400 &&
        e.response?.data.httpStatus === 400 &&
        e.response?.data.error === 'User already exists'
      ) {
        message.error('El usuario ya existe, intenta con otro correo electrónico');
      } else {
        message.error('Ocurrio un error, vuelve a intentarlo más tarde');
      }
    },
  });
  const [form] = Form.useForm<components['schemas']['CreateUserRequest']>();
  const firstNameWatch: string = Form.useWatch('firstName', form);
  const lastNameWatch: string = Form.useWatch('lastName', form);

  useResetFormOnCloseModal({ form, open });

  // Function to copy the temporary password to the clipboard
  const copyPassword = () => {
    navigator.clipboard.writeText(tempPassword);
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 2000);
  };

  const generatePass = (): string => {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const allChars = uppercaseChars + lowercaseChars + numberChars;

    let password = '';
    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;

    // Generate password with at least one uppercase, one lowercase, and one number
    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * uppercaseChars.length);
      password += uppercaseChars[randomIndex];
      hasUppercase = true;
    }

    for (let i = 0; i < 2; i++) {
      const randomIndex = Math.floor(Math.random() * lowercaseChars.length);
      password += lowercaseChars[randomIndex];
      hasLowercase = true;
    }

    for (let i = 0; i < 1; i++) {
      const randomIndex = Math.floor(Math.random() * numberChars.length);
      password += numberChars[randomIndex];
      hasNumber = true;
    }

    // Fill the rest of the password with random characters
    for (let i = 0; i < 8 - 5 - 2 - 1; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      password += allChars[randomIndex];
    }

    // Shuffle the password to ensure randomness
    password = password
      .split('')
      .sort(() => Math.random() - 0.5)
      .join('');

    // If any requirement is not fulfilled, regenerate the password
    if (!hasUppercase || !hasLowercase || !hasNumber) {
      return generatePass();
    }

    return password;
  };

  const generateRandomPassword = () => {
    const randomPassword = generatePass();
    setTempPassword(randomPassword);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate({ ...values, tempPassword });
    } catch (e) {}
  };

  useEffect(() => {
    generateRandomPassword();
  }, []);

  useEffect(() => {
    if (firstNameWatch && lastNameWatch) {
      form.setFieldsValue({
        email: `${firstNameWatch.split(' ')[0].toLowerCase()}.${lastNameWatch
          .split(' ')[0]
          .toLowerCase()}@jaliscopaqueteria.com`,
      });
    }
  }, [firstNameWatch, lastNameWatch]);

  return (
    <Modal
      open={open}
      title="Crear usuario"
      style={{ top: 20 }}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
          Guardar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="create-user-form" scrollToFirstError>
        <Form.Item name="firstName" label="Nombres" rules={[{ required: true, message: 'Ingresa los nombres' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label="Apellidos" rules={[{ required: true, message: 'Ingresa los apellidos' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Correo electrónico"
          name="email"
          rules={[
            {
              required: true,
              message: 'Ingresa un correo electrónico',
              type: 'email',
            },
          ]}
        >
          <Input placeholder="ejemplo@correo.com" />
        </Form.Item>
        <Form.Item name="areaId" label="Área" rules={[{ required: true, message: 'Selecciona un área' }]}>
          <SelectArea />
        </Form.Item>
        <Form.Item name="roleId" label="Rol" rules={[{ required: true, message: 'Selecciona un rol' }]}>
          <SelectRole onChangeByLabel={(l) => setRoleLabel(l)} />
        </Form.Item>
        {roleLabel === 'Conductor' && (
          <>
            <Form.Item
              name="rfc"
              label="RFC"
              normalize={(value) => (value || '').toUpperCase()}
              rules={[
                {
                  required: true,
                  message: 'Ingresa el RFC',
                  pattern:
                    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/i,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="driversLicense"
              label="Licencia de conducir"
              rules={[{ required: true, message: 'Ingresa la licencia de conducir' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="zones"
              label="Zonas"
              rules={[{ required: true, message: 'Selecciona una o varias zonas a las que pertenece el usuario' }]}
            >
              <MultiZoneSelect />
            </Form.Item>
          </>
        )}
        <Row>
          <Col span={24}>
            <div className="ant-form-item-label">
              <label className="ant-form-item-required">Contraseña temporal</label>
            </div>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col
            span={8}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: '12px',
            }}
          >
            <h2 id="temp-password">{tempPassword}</h2>
          </Col>

          <Col
            style={{
              display: 'flex',
              justifyContent: 'baseline',
              alignItems: 'center',
              marginBottom: '12px',
            }}
          >
            <ReloadOutlined className="icon-button" style={{ fontSize: '18px' }} onClick={generateRandomPassword} />
          </Col>

          <Col
            style={{
              display: 'flex',
              justifyContent: 'baseline',
              alignItems: 'end',
              marginBottom: '12px',
            }}
          >
            <Tooltip color="blue" open={tooltipVisible} title="!Copiado!">
              <Button
                icon={<CopyOutlined />}
                className="btn-add_new"
                style={{ height: '48px' }}
                onClick={copyPassword}
                type="primary"
                key="1"
              >
                Copiar al portapapeles
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
