import { components } from '../../api/schema';

export default function formatAddress(address: components['schemas']['Address']) {
  if (address) {
    if (address.zipCodeStr) {
      return `${address.street} ${address.exteriorNumber}, ${
        address.interiorNumber ? `${address.interiorNumber} int` : ''
      }, ${address.zipCodeStr}`;
    }
    return `${address.street} ${address.exteriorNumber}, ${
      address.interiorNumber ? `${address.interiorNumber} int` : ''
    } ${address.zipCode.district}, ${address.zipCode.zipCode}, ${address.zipCode.city}`;
  }

  return '';
}

export function formatAddressWithName(address: components['schemas']['Address']) {
  if (address) {
    if (address.zipCodeStr) {
      return `${address.name} -${address.street} ${address.exteriorNumber}, ${
        address.interiorNumber ? `${address.interiorNumber} int` : ''
      }, ${address.zipCodeStr}`;
    }
    return `${address.name} -${address.street} ${address.exteriorNumber}, ${
      address.interiorNumber ? `${address.interiorNumber} int` : ''
    } ${address.zipCode.district}, ${address.zipCode.zipCode}, ${address.zipCode.city}`;
  }

  return '';
}
