import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { App, Button, Col, Input, Row, Space, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { getCreditShipments } from '../../api';
import { components, paths } from '../../api/schema';
import { AvailableEntity } from '../../shared/commentsLogger/CommentsLogger';
import DataTable, {
  ColumnsType,
  DataTableFilterCallback,
  DataTableOrderCallback,
  serializeFilters,
  serializeOrders,
} from '../../shared/dataTable/DataTable';
import useDebounce from '../../shared/hooks/useDebounce';
import PanelSkeleton from '../../shared/skeletons/PanelSkeleton';
import CreatePayoff, { CreatePayoffRow } from './CreatePrepaid';

const creditShipmentsTableColumns: ColumnsType<components['schemas']['CreditCondensed']> = [
  {
    title: 'Fecha creación',
    dataIndex: 'createdAt',
    key: 'createdAt',
    columnType: 'date',
  },
  {
    title: 'Número de rastreo',
    dataIndex: 'shipmentTrackingNumber',
    key: 'shipmentTrackingNumber',
    columnType: 'date',
  },
  {
    title: 'Cliente',
    dataIndex: 'clientName',
    key: 'clientName',
    columnType: 'text',
  },
  {
    title: 'Peso total',
    dataIndex: 'totalWeight',
    key: 'totalWeight',
    columnType: 'number',
    hideFilter: true,
  },
  {
    title: 'Total de paquetes',
    dataIndex: 'totalPackages',
    key: 'totalPackages',
    columnType: 'number',
    hideFilter: true,
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    key: 'price',
    columnType: 'number',
    hideFilter: true,
  },
  {
    title: 'Estatus',
    dataIndex: 'status',
    key: 'status',
    columnType: 'enum',
  },
];
const PAGE_SIZE = 150;

export default function CreditPanel() {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<CreatePayoffRow[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [selectedClient, setSelectedClient] = useState<string | undefined>();
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/credit/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { isLoading, isError, data, isFetching } = useQuery({
    queryKey: ['getCreditShipments', params],
    queryFn: () => getCreditShipments(params),
  });
  const { message } = App.useApp();

  useEffect(() => {
    setParams((state) => ({ ...state, search: debouncedSearch }));
  }, [debouncedSearch]);

  const handleFiltering = (filter: DataTableFilterCallback) => {
    setParams((state) => ({ ...state, filter: serializeFilters(params.filter, filter) }));
  };

  const handleOrdering = (order: DataTableOrderCallback) => {
    setParams((state) => ({ ...state, order: serializeOrders(params.order, order) }));
  };

  const handlePageChange = (page: number) => {
    setParams((state) => ({ ...state, page }));
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (selectedRows.length === 0) {
      // Hasn't selected any yet, first check that all of the newSelectedRowKeys are from the same client
      let tmpSelectedClient: string | undefined;
      for (const id of newSelectedRowKeys) {
        const rowFound = data!.results.find((r) => r.id === id);
        if (!tmpSelectedClient) {
          tmpSelectedClient = rowFound?.clientId;
        } else if (tmpSelectedClient !== rowFound?.clientId) {
          message.error('No puedes seleccionar guías de diferentes clientes');
          return;
        }
      }
      let totalPrice = 0;
      setSelectedClient(tmpSelectedClient);
      setSelectedRows(
        newSelectedRowKeys.map((id) => {
          const rowFound = data!.results.find((r) => r.id === id);
          totalPrice += Number(rowFound!.price);
          return {
            id: rowFound!.id,
            shipmentTrackingNumber: rowFound!.shipmentTrackingNumber,
            price: rowFound!.price,
          };
        }),
      );
      setSelectedRowKeys(newSelectedRowKeys);
      setTotalPrice(totalPrice);
    } else {
      // Already selected some rows, so validate that the new selected rows are all from the same client
      let allFromSameClient = true;
      for (const id of newSelectedRowKeys) {
        const rowFound = data!.results.find((r) => r.id === id);
        if (rowFound?.clientId !== selectedClient) {
          message.error('No puedes seleccionar guías de diferentes clientes');
          allFromSameClient = false;
          break;
        }
      }

      if (allFromSameClient) {
        let totalPrice = 0;
        setSelectedRows(
          newSelectedRowKeys.map((id) => {
            const rowFound = data!.results.find((r) => r.id === id);
            totalPrice += Number(rowFound!.price);
            return {
              id: rowFound!.id,
              shipmentTrackingNumber: rowFound!.shipmentTrackingNumber,
              price: rowFound!.price,
            };
          }),
        );
        setSelectedRowKeys(newSelectedRowKeys);
        setTotalPrice(totalPrice);
      }
    }
  };

  const handleBtnClick = () => {
    if (!isSelecting) {
      setIsSelecting(true);
    } else {
      if (selectedRows.length === 0) {
        setIsSelecting(false);
      } else {
        console.log('ahora si selected', selectedRows);
        setCreateModalOpen(true);
      }
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Row>
      <Col span={24}>
        <Space size={'middle'}>
          <Button type="primary" onClick={handleBtnClick}>
            {isSelecting
              ? selectedRows.length > 0
                ? `Confirmar selección de ${selectedRows.length} guía${selectedRows.length > 1 ? 's' : ''}`
                : 'Selecciona guías a pagar'
              : 'Pagar guías'}
          </Button>
          <Input
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Space>
      </Col>
      {isLoading || isError || isFetching ? (
        <PanelSkeleton />
      ) : (
        <>
          <Col span={24} style={{ marginTop: '10px' }}>
            <DataTable<components['schemas']['CreditCondensed']>
              handleFilters={handleFiltering}
              handleOrder={handleOrdering}
              handlePageChange={handlePageChange}
              columns={creditShipmentsTableColumns}
              results={data.results}
              activeFilters={params.filter}
              activeOrders={params.order}
              paginationData={{
                pageSize: PAGE_SIZE,
                count: data.count,
                currentPage: data.currentPage,
              }}
              commentsLogger={{
                entity: AvailableEntity.SHIPMENT,
                queryKeyToInvalidate: 'getCreditShipments',
                entityKey: 'id',
              }}
              rowSelection={isSelecting ? rowSelection : undefined}
            />
          </Col>

          <CreatePayoff
            open={createModalOpen}
            onCancel={() => {
              setCreateModalOpen(false);
            }}
            onCreate={() => {
              setSelectedRowKeys([]);
              setSelectedRows([]);
              setSelectedClient(undefined);
              setCreateModalOpen(false);
              setIsSelecting(false);
            }}
            clientId={selectedClient!}
            selectedRows={selectedRows}
            totalPrice={totalPrice}
          />
        </>
      )}
    </Row>
  );
}
