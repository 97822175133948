import { LoadingOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Input, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { getProductKey, listProductKeys } from '../../api';
import { components, paths } from '../../api/schema';
import useDebounce from '../hooks/useDebounce';

const renderItem = (satProduct: components['schemas']['SATProduct']) => ({
  key: satProduct.id,
  value: satProduct.id,
  satProduct,
  label: (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {satProduct.key} - {satProduct.name}
    </div>
  ),
});

interface SelectSATProductProps {
  value?: string;
  onChange?: (value: string) => void;
  onSelected?: (satProduct: components['schemas']['SATProduct']) => void;
  preselectedSATProductId?: string;
}
const PAGE_SIZE = 15;
export default function SearchSATProduct({ onChange, onSelected, preselectedSATProductId }: SelectSATProductProps) {
  const [options, setOptions] = useState<
    Array<{ value: string; label: JSX.Element; satProduct: components['schemas']['SATProduct'] }>
  >([]);
  const [search, setSearch] = useState<string>('');
  const [inputVal, setInputVal] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/sat/list/product-keys']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { data: product, isFetching: isFetchingSingleProduct } = useQuery({
    queryKey: ['getProductKey', preselectedSATProductId],
    queryFn: () => getProductKey(preselectedSATProductId),
    enabled: preselectedSATProductId !== undefined,
  });
  const { data, isFetching } = useQuery({
    queryKey: ['listProductKeys', params],
    queryFn: () => listProductKeys(params),
    enabled: debouncedSearch.length > 0,
  });

  useEffect(() => {
    if (data?.results.length) {
      setOptions(data.results.map((satProduct) => renderItem(satProduct)));
    } else {
      setOptions([]);
    }
    setOpen(true);
  }, [data]);

  useEffect(() => {
    if (preselectedSATProductId && product) {
      setInputVal(product.name);
      onChange?.(product.id);
      onSelected?.({
        id: product.id,
        name: product.name,
        key: product.key,
        complement: product.complement,
        required: product.required,
      });
    }
  }, [product, preselectedSATProductId]);

  useEffect(() => {
    if (debouncedSearch.length) {
      setParams((state) => ({ ...state, search: debouncedSearch }));
      setOpen(false);
    } else {
      setOpen(false);
      setOptions([]);
    }
  }, [debouncedSearch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setInputVal(e.target.value);
  };

  const handleSelected = (satProduct: components['schemas']['SATProduct']) => {
    setInputVal(satProduct.name);
    onSelected?.(satProduct);
    onChange?.(satProduct.id);
    setOpen(false);
  };

  const content = (
    <div style={{ width: 350, maxHeight: 350, overflowY: 'auto' }}>
      {options.map((option, k) => (
        <div className="custom-popover-item" key={k} onClick={() => handleSelected(option.satProduct)}>
          {option.label}
        </div>
      ))}
    </div>
  );
  return (
    <Popover content={content} open={options.length > 0 && open} arrow={false}>
      <Input
        style={{ width: '100%' }}
        placeholder="Buscar producto SAT"
        onChange={handleInputChange}
        value={inputVal}
        suffix={isFetching || isFetchingSingleProduct ? <LoadingOutlined /> : <span />}
      />
    </Popover>
  );
}
