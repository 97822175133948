import type { RangePickerProps as RPickerProps } from 'antd/es/date-picker/generatePicker';
import type { Moment } from 'moment-timezone';
import * as React from 'react';
import DatePicker from './DatePicker';

export interface RangePickerProps extends Omit<RPickerProps<Moment>, 'picker'> {}

const RangePicker = React.forwardRef<any, RangePickerProps>((props, ref) => (
  <DatePicker.RangePicker {...props} mode={undefined} ref={ref} />
));

RangePicker.displayName = 'TimePicker';

export default RangePicker;
