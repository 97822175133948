import { App as AntApp } from 'antd';
import { Amplify } from 'aws-amplify';
import { Route, Routes } from 'react-router-dom';
import AuthProvider from './components/auth/AuthProvider';
import ProtectedLayout from './components/auth/ProtectedLayout';
import LoginPage from './components/auth/login/LoginPage';
import ClientPanel from './components/client/ClientPanel';
import { amplifyConfig } from './config';
import NotFound from './components/notFound/NotFound';
import UserPanel from './components/user/UserPanel';
import ZipCodePanel from './components/zipCode/ZipCodePanel';
import PickupPanel from './components/pickup/PickupPanel';
import VehiclePanel from './components/vehicle/VehiclePanel';
import VehicleAtServicePanel from './components/vehicleAtService/VehicleAtServicePanel';
import WarehousePanel from './components/warehouse/WarehousePanel';
import ZonePanel from './components/zone/ZonePanel';
import PricePanel from './components/price/PricePanel';
import InTransitPanel from './components/transit/TransitPanel';
import DeliveredPanel from './components/delivered/DeliveredPanel';
import SearchPanel from './components/search/SearchPanel';
import UserClientPanel from './components/user-clients/UserClientPanel';
import PrepaidPanel from './components/prepaid/PrepaidPanel';
import CreditPanel from './components/credit/CreditPanel';
Amplify.configure(amplifyConfig);

function App() {
  return (
    <AntApp>
      <AuthProvider>
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route element={<ProtectedLayout />}>
            <Route path="usuarios-internos" element={<UserPanel />} />
            <Route path="usuarios-clientes" element={<UserClientPanel />} />
            <Route path="clientes" element={<ClientPanel />} />
            <Route path="codigos-postales" element={<ZipCodePanel />} />
            <Route path="precios" element={<PricePanel />} />
            <Route path="zonas" element={<ZonePanel />} />
            <Route path="recolecciones" element={<PickupPanel />} />
            <Route path="en-piso" element={<WarehousePanel />} />
            <Route path="en-transito" element={<InTransitPanel />} />
            <Route path="entregados" element={<DeliveredPanel />} />
            <Route path="vehiculos" element={<VehiclePanel />} />
            <Route path="vehiculos-en-servicio" element={<VehicleAtServicePanel />} />
            <Route path="busqueda" element={<SearchPanel />} />
            <Route path="guias-prepagadas" element={<PrepaidPanel />} />
            <Route path="credito" element={<CreditPanel />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </AntApp>
  );
}

export default App;
