import { Button, Drawer, Modal, Space } from 'antd';
import GMap from '../maps/GMap';
import { useState } from 'react';

interface IAddressInMapProps {
  open: boolean;
  onCancel: () => void;
  selectedAddressKey: number | undefined;
  centerCoords?: [number, number] | undefined;
  onSendObject: (p: { id: number; latLng: { lat: number; lng: number } }) => void;
}

export default function AddressInMapDrawer({
  open,
  onCancel,
  selectedAddressKey,
  onSendObject,
  centerCoords,
}: IAddressInMapProps) {
  const [geocodeLoading, setGeocodeLoading] = useState(false);
  const [streetName, setStreetName] = useState('');
  const [coords, setCoords] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const handleSaveClick = async () => {
    if (geocodeLoading) return;
    Modal.confirm({
      title: 'Verificar dirección',
      content: (
        <>
          <p>La dirección que seleccionaste es:</p>
          <p>
            <b>{streetName}</b>
          </p>
          <p>¿Es correcto?</p>
        </>
      ),
      okText: 'Sí',
      cancelText: 'No',
      onOk() {
        handleSubmit();
      },
    });
  };

  const handleSubmit = () => {
    onSendObject({ id: selectedAddressKey!, latLng: coords });
    onCancel();
  };

  const handleGetCoordinates = (coordinates: { lat: number; lng: number }) => {
    setGeocodeLoading(true);
    setCoords(coordinates);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: coordinates }, (results, status) => {
      if (status === 'OK') {
        const streetName = results?.[0]?.formatted_address;
        setStreetName(streetName ?? 'Sin resultados');
      } else {
        setStreetName('Sin resultados');
      }
      setGeocodeLoading(false);
    });
  };

  return (
    <Drawer
      title={`Seleccionar dirección`}
      placement="right"
      style={{ top: 20 }}
      width={800}
      destroyOnClose
      maskClosable={false}
      closable={false}
      extra={
        <Space>
          <Button
            key="back"
            onClick={() => {
              onCancel();
            }}
          >
            Cancelar
          </Button>
          <Button key="submit" htmlType="submit" type="primary" onClick={handleSaveClick}>
            Guardar
          </Button>
        </Space>
      }
      open={open}
    >
      <GMap onGetCoordinates={handleGetCoordinates} centerCoords={centerCoords} />
    </Drawer>
  );
}
