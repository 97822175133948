import { BarcodeOutlined, DownloadOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Drawer, Form, Row, Spin } from 'antd';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { getShipingLabel } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';
import useResetFormOnCloseModal from '../../../shared/hooks/useResetFormModalOnClose';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const LabelDrawer = ({ record }: DataTableActionProps<components['schemas']['PickupCondensed']>) => {
  const [form] = Form.useForm<components['schemas']['EditUserRequest']>();
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  const { data, remove } = useQuery({
    queryKey: ['getShipingLabel'],
    queryFn: () => getShipingLabel(record.shipmentId),
    enabled: record.shipmentId !== undefined && open,
  });

  useResetFormOnCloseModal({ form, open });

  const onDocumentLoadSuccess = (values: any) => {
    setNumPages(values.numPages);
  };

  const handleCancel = () => {
    setOpen(false);
    setPageNumber(1);
    setNumPages(null);
    setScale(1);
    remove();
  };

  const PdfPageControls = () => {
    return (
      <div className="pdfActionContainer">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button className="pdfButton" disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
            {'<'}
          </Button>
          <p style={{ margin: '0 10px' }}>
            {pageNumber}/{numPages}
          </p>
          <Button
            className="pdfButton"
            disabled={pageNumber === numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            {'>'}
          </Button>
        </div>
      </div>
    );
  };

  const downloadPDF = (pdf: string) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = `etiqueta_${record.shipmentId}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const PdfZoomControls = ({ pdfSelected }: { pdfSelected: string }) => {
    return (
      <div className="pdfZoomContainer">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button className="pdfButton" onClick={() => setScale(scale + 0.1)}>
            +
          </Button>
          <Button className="pdfButton" onClick={() => setScale(scale - 0.1)}>
            -
          </Button>
          <div style={{ height: '1px', width: '100%', backgroundColor: '#f3f3f3' }} />
          <Button className="pdfButton" onClick={() => downloadPDF(pdfSelected)}>
            <DownloadOutlined size={16} />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Drawer
        open={open}
        title="Vista etiqueta de envío"
        width={'60%'}
        destroyOnClose
        maskClosable={false}
        closable={false}
        extra={[
          <Button key="back" type="primary" onClick={handleCancel}>
            Cerrar
          </Button>,
        ]}
      >
        {!data ? (
          <Row justify="center">
            <Spin />
          </Row>
        ) : (
          <>
            {/* <div style={{ position: 'relative' }}>
              <div className="pdf-container">
                <Document
                  loading={
                    <Row justify="center">
                      <Spin />
                    </Row>
                  }
                  onLoadSuccess={onDocumentLoadSuccess}
                  file={`data:application/pdf;base64,${data.labelBase64}`}
                >
                  <Page
                    scale={scale}
                    className="pdf-page"
                    width={700}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    pageNumber={pageNumber}
                  />
                </Document>
              </div>
            </div> */}
            <iframe
              title="pdf Preview"
              src={`data:application/pdf;base64,${data.labelBase64}`}
              height="100%"
              width="100%"
            />
            {/* <PdfPageControls /> */}
            {/* <PdfZoomControls pdfSelected={data.labelBase64} /> */}
          </>
        )}
      </Drawer>
      <BarcodeOutlined onClick={() => setOpen(true)} />
    </>
  );
};
