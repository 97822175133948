import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Divider, Form, Input, InputNumber, Modal, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { editVehicle, getVehicle, listVehicleConfig } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';
import useResetFormOnCloseModal from '../../../shared/hooks/useResetFormModalOnClose';

export const EditVehicle = ({ record }: DataTableActionProps<components['schemas']['VehicleCondensed']>) => {
  const [form] = Form.useForm<components['schemas']['EditVehicleRequest']>();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { data: vehicleConfig } = useQuery({
    queryKey: ['listVehicleConfig'],
    queryFn: () => listVehicleConfig(),
  });
  const { data, remove } = useQuery({
    queryKey: ['getVehicle', record.id],
    queryFn: () => getVehicle(record.id),
    enabled: record.id !== undefined && open,
  });

  const mutation = useMutation({
    mutationFn: (body: components['schemas']['EditVehicleRequest']) => editVehicle(record.id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getVehicles'] });
      message.success('Vehículo editado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        vehicleConfigId: data.vehicleConfig.id,
        plate: data.plate,
        year: data.year,
        respCivilInsurer: data.respCivilInsurer,
        respCivilPolicyNumber: data.respCivilPolicyNumber,
        pesoBruto: data.pesoBruto,
      });
    }
  }, [data]);

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate(values);
    } catch (e) {}
  };

  const handleCancel = () => {
    setOpen(false);
    remove();
  };

  return (
    <>
      <Modal
        open={open}
        title="Editar vehículo"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
            Guardar
          </Button>,
        ]}
      >
        {data && vehicleConfig ? (
          <Form form={form} layout="vertical" name="edit-vehicle-form" scrollToFirstError>
            <Form.Item
              name="name"
              label="Nombre del vehículo"
              rules={[{ required: true, message: 'Ingresa el nombre del vehículo' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="vehicleConfigId"
              label="Configuración vehícular"
              rules={[{ required: true, message: 'Selecciona una configuración vehícular' }]}
            >
              <Select
                style={{ width: '100%' }}
                options={vehicleConfig.map((conf) => ({ value: conf.id, label: conf.description }))}
              />
            </Form.Item>
            <Form.Item
              name="plate"
              label="Placa"
              rules={[
                {
                  required: true,
                  message: 'Ingresa la placa, de 5 a 7 caracteres sin espacios ni caracteres especiales',
                  pattern: /^[a-zA-Z0-9]{5,7}$/,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="year"
              label="Año"
              rules={[{ required: true, message: 'Ingresa el año del vehículo', pattern: /^[0-9]{4}$/ }]}
            >
              <Input placeholder="Ej. 2015" />
            </Form.Item>
            <Form.Item
              name="pesoBruto"
              label="Peso bruto (toneladas)"
              rules={[{ required: true, message: 'Ingresa el peso bruto en toneladas que puede cargar el vehiculo' }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Peso bruto en toneladas que puede cargar el vehiculo"
              />
            </Form.Item>
            <Divider>Responsabilidad Civíl</Divider>
            <Form.Item
              name="respCivilInsurer"
              label="Nombre de la aseguradora"
              rules={[{ required: true, message: 'Ingresa el nombre de la aseguradora' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="respCivilPolicyNumber"
              label="Número de póliza"
              rules={[{ required: true, message: 'Ingresa el número de póliza' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        ) : (
          <Spin size="large" />
        )}
      </Modal>
      <EditOutlined onClick={() => setOpen(true)} />
    </>
  );
};
