import { Button, Col, Form, Input, Popover, Row, Typography, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
const { Title, Text } = Typography;

interface ForgotPwProps {
  onFinish: () => void;
  changeStepLogin: () => void;
}
type ForgotPasswordSteps = 'requestEmail' | 'updatePw';
const ForgotPassword = ({ onFinish, changeStepLogin }: ForgotPwProps) => {
  const [step, setStep] = useState<ForgotPasswordSteps>('requestEmail');
  const [tmpEmail, setTmpEmail] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingFirstStep, setLoadingFirstStep] = useState(false);
  const [loadingSecondStep, setLoadingSecondStep] = useState(false);
  const [form] = Form.useForm();
  const [formSecondStep] = Form.useForm();

  const handleOnFinishFirst = async ({ email }: { email: string }) => {
    setLoadingFirstStep(true);

    try {
      await Auth.forgotPassword(email);
      setTmpEmail(email);
      setStep('updatePw');
      form.resetFields();
      setLoadingFirstStep(false);
    } catch (e: any) {
      if (e.message === 'Username/client id combination not found.') {
        messageApi.error('No existe una cuenta con el correo ingresado', 2);
      } else if (e.message === 'Attempt limit exceeded, please try after some time.') {
        messageApi.error('Se excedió el límite de intentos, intente después de un tiempo.');
      } else {
        messageApi.error('Error desconocido');
      }
      setLoadingFirstStep(false);
    }
  };

  const handleOnFinishSecond = async ({ code, password }: { code: string; password: string }) => {
    setLoadingSecondStep(true);
    try {
      await Auth.forgotPasswordSubmit(tmpEmail, code, password);
      formSecondStep.resetFields();
      setLoadingSecondStep(false);
      onFinish();
    } catch (e: any) {
      if (e.message === 'Invalid verification code provided, please try again.') {
        messageApi.error('Código de verificación inválido', 2);
      } else {
        messageApi.error('Error desconocido');
      }
      setLoadingSecondStep(false);
    }
  };

  const handleChangeStep = () => {
    form.resetFields();
    setLoadingFirstStep(false);
    setLoadingSecondStep(false);
    changeStepLogin();
  };

  return (
    <>
      {contextHolder}
      {step === 'requestEmail' ? (
        <Row>
          <Col span={24}>
            <Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
              Olvidaste tu contraseña?
            </Title>
            <Text style={{ fontSize: '12px' }} type="secondary">
              Ingresa tu email para recibir un código
            </Text>
          </Col>
          <Col span={24} style={{ marginTop: '15px' }}>
            <Form form={form} name="forgotPw" autoComplete="off" onFinish={handleOnFinishFirst} layout="vertical">
              <Col span={24} style={{ marginTop: '15px' }}>
                <Form.Item
                  label="Correo electrónico"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Ingresa tu correo electrónico!',
                      type: 'email',
                    },
                  ]}
                >
                  <Input placeholder="ejemplo@correo.com" />
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: '5px', textAlign: 'right' }}>
                <Button type="link" style={{ paddingRight: 0 }} onClick={handleChangeStep}>
                  Cancelar
                </Button>
              </Col>
              <Col span={24} style={{ marginTop: '10px', textAlign: 'right' }}>
                <Button block type="primary" htmlType="submit" loading={loadingFirstStep}>
                  Enviar
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
              Nueva contraseña
            </Title>
            <Text style={{ fontSize: '12px' }} type="secondary">
              Ingresa el código que recibiste por correo y tu nueva contraseña
            </Text>
          </Col>
          <Col span={24} style={{ marginTop: '15px' }}>
            <Form
              form={formSecondStep}
              name="updatePw"
              autoComplete="off"
              onFinish={handleOnFinishSecond}
              layout="vertical"
            >
              <Col span={24} style={{ marginTop: '15px' }}>
                <Form.Item
                  label="Código de verificación"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: 'Ingresa el código que recibiste por correo!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: '15px' }}>
                <Popover
                  content={() => (
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col span={24}>* Contener al menos 8 caracteres</Col>
                        </Row>
                        <Row>
                          <Col span={24}>* Contener al menos una mayúscula</Col>
                        </Row>
                        <Row>
                          <Col span={24}>* Contener al menos una minúscula</Col>
                        </Row>
                        <Row>
                          <Col span={24}>* Contener al menos un número</Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  title="Tu contraseña debe:"
                  placement="bottomLeft"
                  trigger="focus"
                >
                  <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa tu contraseña!',
                      },
                      () => ({
                        validator(_, value) {
                          const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
                          if (!value || reg.test(value)) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('La contraseña no cumple con los requisitos'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Popover>
              </Col>
              <Col span={24} style={{ marginTop: '15px' }}>
                <Form.Item
                  label="Confirmar contraseña"
                  name="confirm"
                  rules={[
                    { required: true, message: 'Confirma tu contraseña!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Las contraseñas que ingresaste no son iguales!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: '10px', textAlign: 'right' }}>
                <Button block type="primary" htmlType="submit" loading={loadingSecondStep}>
                  Enviar
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ForgotPassword;
