import { useMutation } from '@tanstack/react-query';
import { App, Button, Col, Drawer, Form, Input, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { createAddress } from '../../api';
import { components } from '../../api/schema';
import ReadonlyInput from '../../shared/form/ReadonlyInput';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';
import SelectZipCode from '../../shared/zipCode/SelectZipCode';
import { AxiosError } from 'axios';
import AddressInMapDrawer from './AddressInMapDrawer';

interface CreateAddressProps {
  clientId?: string;
  open: boolean;
  option: { label: string; value: components['schemas']['Address']['addressType'] };
  onCreate?: (address: components['schemas']['Address']) => void;
  onCancel: () => void;
  clientRfc?: string;
}

export default function CreateAddressDrawer({
  open,
  clientId,
  onCreate,
  onCancel,
  option,
  clientRfc,
}: CreateAddressProps) {
  const { message } = App.useApp();
  const [selectedZipCode, setSelectedZipCode] = useState<components['schemas']['ZipcodeCondensed'] | undefined>();
  const [selectedAddressKey, setSelectedAddressKey] = useState<number | undefined>();
  const [showAddressInMapDrawer, setShowAddressInMapDrawer] = useState<boolean>(false);
  const [selectedCoords, setSelectedCoords] = useState<[number, number]>();
  const [showSelectInMap, setShowSelectInMap] = useState<boolean>(false);

  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreateAddress']) => createAddress(clientId ?? '', body),
    onSuccess: (data) => {
      message.success('Dirección creada correctamente');
      onCreate?.(data);
      setSelectedZipCode(undefined);
    },
    onError: (e: AxiosError<components['schemas']['BaseError400']>) => {
      if (e?.response?.data.httpStatus === 400) {
        if (
          e.response.data.error ===
          'La dirección no es válida, verifica que la calle y número sean correctos y pertenezcan al código postal seleccionado'
        ) {
          message.error('La dirección no es válida, selecciona una ubicación en el mapa');
          handleShowAddressInMapDrawer(undefined);
          setShowSelectInMap(true);
        } else {
          message.error(e.response.data.error);
        }
      } else {
        message.error('Ocurrio un error, vuelve a intentarlo más tarde');
      }
    },
  });

  const [form] = Form.useForm<components['schemas']['CreateAddress']>();

  useEffect(() => {
    if (open && clientId) {
      form.setFieldsValue({
        addressType: option.value,
      });
    }
  }, [open, clientId]);

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (selectedCoords) {
        const oCoordinates = {
          lat: selectedCoords[0],
          lng: selectedCoords[1],
        };

        values.coordinates = oCoordinates;
      } else {
        values.coordinates = {};
      }
      mutation.mutate(values);
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  const handleSelectZipCode = (zipCode: components['schemas']['ZipcodeCondensed']) => {
    setSelectedZipCode(zipCode);
  };

  const handleShowAddressInMapDrawer = (key: number | undefined) => {
    setSelectedAddressKey(key);
    setShowAddressInMapDrawer(!showAddressInMapDrawer);
  };

  const handleSendObject = (p: { id: number; latLng: { lat: number; lng: number } }) => {
    const lat = p.latLng.lat;
    const lng = p.latLng.lng;
    setSelectedCoords([lat, lng]);
  };

  return (
    <Drawer
      open={open}
      title="Crear dirección"
      style={{ top: 20 }}
      width={800}
      destroyOnClose
      maskClosable={false}
      closable={false}
      extra={
        <Space>
          <Button
            key="back"
            onClick={() => {
              onCancel();
              setSelectedZipCode(undefined);
            }}
          >
            Cancelar
          </Button>
          <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
            Guardar
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="create-address-form"
        scrollToFirstError
        autoComplete="off"
        initialValues={{
          rfc: clientRfc,
        }}
      >
        <Row gutter={16}>
          <Col span={23}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name={'addressType'}
                  label="Tipo de dirección"
                  rules={[{ required: true, message: 'Selecciona el tipo de dirección' }]}
                >
                  <Select style={{ width: '100%' }} options={[option]} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={'zipCodeId'}
                  label="Código postal"
                  rules={[{ required: true, message: 'Selecciona un código postal' }]}
                >
                  <SelectZipCode onSelected={handleSelectZipCode} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={'rfc'}
                  label="RFC"
                  normalize={(value) => (value || '').toUpperCase()}
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: 'Ingresa un RFC válido',
                      pattern:
                        /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/i,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Button
                  type="link"
                  onClick={() => {
                    form.setFieldValue('rfc', 'XAXX010101000');
                    form.validateFields(['rfc']);
                  }}
                  style={{ paddingLeft: 0 }}
                >
                  RFC Público en general
                </Button>
              </Col>
            </Row>
            {option.value === 'PICKUP' ? (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name={'name'}
                    label="Nombre de la dirección"
                    rules={[{ required: true, message: 'Ingresa el nombre de la dirección' }]}
                  >
                    <Input placeholder="Casa..." />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={'reference'} label="Referencia">
                    <Input placeholder="Casa color amarillo..." />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name={'recipientName'}
                    label="Nombre del destinatario"
                    rules={[{ required: true, message: 'Ingresa el nombre del destinatario' }]}
                  >
                    <Input placeholder="Juan..." />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={'name'}
                    label="Nombre de la dirección"
                    rules={[{ required: true, message: 'Ingresa el nombre de la dirección' }]}
                  >
                    <Input placeholder="Casa..." />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'reference'} label="Referencia">
                    <Input placeholder="Casa color amarillo..." />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  name={'street'}
                  label="Calle"
                  rules={[{ required: true, message: 'Ingresa el nombre de la calle' }]}
                  style={{ marginBottom: 0 }}
                >
                  <Input />
                </Form.Item>
                {showSelectInMap && (
                  <Button
                    type="link"
                    onClick={() => handleShowAddressInMapDrawer(undefined)}
                    style={{ paddingLeft: 0 }}
                  >
                    Ingresar dirección en Mapa.
                  </Button>
                )}
              </Col>
              <Col span={6}>
                <Form.Item
                  rules={[{ required: true, message: 'Ingresa el número exterior' }]}
                  name={'exteriorNumber'}
                  label="Número exterior"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={'interiorNumber'} label="Número interior">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={'betweenStreets'}
                  label="Entre calles"
                  rules={[{ message: 'Ingresa entre que calles está' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginBottom: 20 }}>
              <Col span={6}>
                <ReadonlyInput value={selectedZipCode?.district ?? ''} label="Colonia" removeBoldLabel />
              </Col>
              <Col span={6}>
                <ReadonlyInput value={selectedZipCode?.city ?? ''} label="Ciudad" removeBoldLabel />
              </Col>
              <Col span={6}>
                <ReadonlyInput value={selectedZipCode?.municipio ?? ''} label="Municipio" removeBoldLabel />
              </Col>
              <Col span={6}>
                <ReadonlyInput value={selectedZipCode?.state ?? ''} label="Estado" removeBoldLabel />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <AddressInMapDrawer
        selectedAddressKey={selectedAddressKey}
        open={showAddressInMapDrawer}
        onCancel={() => setShowAddressInMapDrawer(false)}
        onSendObject={handleSendObject}
      />
    </Drawer>
  );
}
