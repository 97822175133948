import { components } from './schema';
import axios from './axios';

export const getComments = async (entityId: string): Promise<components['schemas']['CommentLogResponse']['data']> => {
  const data = await axios.get<components['schemas']['CommentLogResponse']>(`/comments-logger/${entityId}`);
  return data.data.data;
};

export const createComment = async (
  entityId: string,
  body: components['schemas']['CommentLogPutRequest'],
): Promise<components['schemas']['CommentLogPutResponse']['data']> => {
  const data = await axios.put<components['schemas']['CommentLogPutResponse']>(`/comments-logger/${entityId}`, body);
  return data.data.data;
};
