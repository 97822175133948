import { useQuery } from '@tanstack/react-query';
import { Col, Input, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { getWarehouseList } from '../../api';
import { components, paths } from '../../api/schema';
import { AvailableEntity } from '../../shared/commentsLogger/CommentsLogger';
import DataTable, {
  ColumnsType,
  DataTableFilterCallback,
  DataTableOrderCallback,
  serializeFilters,
  serializeOrders,
} from '../../shared/dataTable/DataTable';
import useDebounce from '../../shared/hooks/useDebounce';
import PanelSkeleton from '../../shared/skeletons/PanelSkeleton';

import { SearchOutlined } from '@ant-design/icons';
import { LabelDrawer } from './warehousePanelActions/LabelDrawer';
import EditPriceModal from './warehousePanelActions/EditPriceModal';
import ChangeStatusPopConfirm from './warehousePanelActions/ChangeStatusPopConfirm';
import ChangeBackStatusPopConfirm from './warehousePanelActions/ChangeBackStatusPopConfirm';
import formatMoneyToMXN from '../../shared/shipment/formatMoney';
import useConditionalRefresh from '../../shared/hooks/useConditionalRefresh';

const warehousesTableColumns: ColumnsType<components['schemas']['WarehouseCondensed']> = [
  {
    title: 'Fecha creación',
    dataIndex: 'createdAt',
    key: 'createdAt',
    columnType: 'date',
  },
  {
    title: 'Número de rastreo',
    dataIndex: 'shipmentTrackingNumber',
    key: 'shipmentTrackingNumber',
    columnType: 'text',
  },
  {
    title: 'Fecha de arribo al almacén',
    dataIndex: 'arrivedAtWarehouse',
    key: 'arrivedAtWarehouse',
    columnType: 'date',
  },
  {
    title: 'Cliente',
    dataIndex: 'clientName',
    key: 'clientName',
    columnType: 'text',
  },
  {
    title: 'Zona de entrega',
    dataIndex: 'deliveryZone',
    key: 'deliveryZone',
    columnType: 'text',
  },
  {
    title: 'Dirección de  entrega',
    dataIndex: 'deliveryAddress',
    key: 'deliveryAddress',
    columnType: 'text',
    trimValueToMax: 50,
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    key: 'price',
    columnType: 'number',
    hideFilter: true,
    customFormat: (r) => formatMoneyToMXN(r.price),
    customCellClass(r) {
      if (r.status !== 'Listo para entrega') {
        if (r.priceChanged) {
          return 'status-yellow text-right';
        } else if (r.needsPriceRevision) {
          return 'status-red text-right';
        }
      }
    },
    customCellTooltip(r) {
      if (r.priceChanged) {
        return `Precio original: ${formatMoneyToMXN(r.originalTotalPrice)}`;
      }
    },
  },
  {
    title: 'Peso total',
    dataIndex: 'totalWeight',
    key: 'totalWeight',
    columnType: 'text',
    hideFilter: true,
  },
  {
    title: 'Total de paquetes',
    dataIndex: 'totalPackages',
    key: 'totalPackages',
    columnType: 'text',
    hideFilter: true,
  },
  {
    title: 'Estatus',
    dataIndex: 'status',
    key: 'status',
    columnType: 'enum',
    customCellClass: (r) => {
      if (r.status === 'En almacén') {
        return 'status-yellow text-center';
      } else if (r.status === 'Listo para entrega') {
        return 'status-green text-center';
      }
    },
  },
];
const PAGE_SIZE = 50;

export default function WareHousePanel() {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/shipment/warehouse/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { isLoading, isError, data, isFetching, refetch } = useQuery({
    queryKey: ['getWarehouseList', params],
    queryFn: () => getWarehouseList(params),
  });

  useConditionalRefresh(60000, refetch);

  useEffect(() => {
    setParams((state) => ({ ...state, search: debouncedSearch }));
  }, [debouncedSearch]);

  const handleFiltering = (filter: DataTableFilterCallback) => {
    setParams((state) => ({ ...state, filter: serializeFilters(params?.filter, filter) }));
  };

  const handleOrdering = (order: DataTableOrderCallback) => {
    setParams((state) => ({ ...state, order: serializeOrders(params?.order, order) }));
  };

  const handlePageChange = (page: number) => {
    setParams((state) => ({ ...state, page }));
  };

  return (
    <Row>
      <Col span={24}>
        <Space size={'middle'}>
          <Input
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Space>
      </Col>
      {isLoading || isError || isFetching ? (
        <PanelSkeleton />
      ) : (
        <>
          <Col span={24} style={{ marginTop: '10px' }}>
            <DataTable<components['schemas']['WarehouseCondensed']>
              handleFilters={handleFiltering}
              handleOrder={handleOrdering}
              handlePageChange={handlePageChange}
              columns={warehousesTableColumns}
              results={data.results}
              activeFilters={params?.filter}
              activeOrders={params?.order}
              paginationData={{
                pageSize: PAGE_SIZE,
                count: data.count,
                currentPage: data.currentPage,
              }}
              commentsLogger={{
                entity: AvailableEntity.SHIPMENT,
                queryKeyToInvalidate: 'getWarehouseList',
                entityKey: 'shipmentId',
              }}
              actions={[LabelDrawer, EditPriceModal, ChangeStatusPopConfirm, ChangeBackStatusPopConfirm]}
            />
          </Col>
        </>
      )}
    </Row>
  );
}
