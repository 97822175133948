import { components, paths } from './schema';
import axios, { purgeQueryParams } from './axios';

export const listProductKeys = async (
  params: paths['/sat/list/product-keys']['get']['parameters']['query'],
): Promise<components['schemas']['ProductKeysListResponse']['data']> => {
  const data = await axios.get<components['schemas']['ProductKeysListResponse']>(
    `/sat/list/product-keys?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const getProductKey = async (
  productKey?: string,
): Promise<components['schemas']['SATProductResponse']['data']> => {
  if (!productKey) return await Promise.reject(new Error());
  const data = await axios.get<components['schemas']['SATProductResponse']>(`/sat/product-key/${productKey}`);
  return data.data.data;
};

export const listCfdiUse = async (): Promise<components['schemas']['CfdiUseListResponse']['data']> => {
  const data = await axios.get<components['schemas']['CfdiUseListResponse']>(`/sat/list/cfdi-use`);
  return data.data.data;
};

export const listPaymentMethod = async (): Promise<components['schemas']['PaymentMethodListResponse']['data']> => {
  const data = await axios.get<components['schemas']['PaymentMethodListResponse']>(`/sat/list/payment-method`);
  return data.data.data;
};

export const listRegimenFiscal = async (): Promise<components['schemas']['RegimenFiscalListResponse']['data']> => {
  const data = await axios.get<components['schemas']['RegimenFiscalListResponse']>(`/sat/list/regimen-fiscal`);
  return data.data.data;
};

export const listUnitKeys = async (): Promise<components['schemas']['UnitKeysListResponse']['data']> => {
  const data = await axios.get<components['schemas']['UnitKeysListResponse']>(`/sat/list/unit-keys`);
  return data.data.data;
};

export const listVehicleConfig = async (): Promise<components['schemas']['VehicleConfigResponse']['data']> => {
  const data = await axios.get<components['schemas']['VehicleConfigResponse']>(`/sat/list/vehicle-config`);
  return data.data.data;
};

export const listFormaPago = async (): Promise<components['schemas']['FormaPagoListResponse']['data']> => {
  const data = await axios.get<components['schemas']['FormaPagoListResponse']>(`/sat/list/forma-pago`);
  return data.data.data;
};
