import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Input, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { getVehicles } from '../../api';
import { components, paths } from '../../api/schema';
import DataTable, {
  ColumnsType,
  DataTableFilterCallback,
  DataTableOrderCallback,
  serializeFilters,
  serializeOrders,
} from '../../shared/dataTable/DataTable';
import useDebounce from '../../shared/hooks/useDebounce';
import PanelSkeleton from '../../shared/skeletons/PanelSkeleton';
import CreateVehicle from './CreateVehicle';
import { EditVehicle } from './vehiclePanelActions/EditAction';
import { DeleteVehicle } from './vehiclePanelActions/DeleteAction';
import VehicleDetail from './VehicleDetail';

const vehiclesTableColumns: ColumnsType<components['schemas']['VehicleCondensed']> = [
  {
    title: 'Vehículo',
    dataIndex: 'name',
    key: 'name',
    columnType: 'text',
  },
  {
    title: 'Config. Vehículo',
    dataIndex: 'vehicleConfig',
    key: 'vehicleConfig',
    columnType: 'text',
  },
  {
    title: 'Placas',
    dataIndex: 'plate',
    key: 'plate',
    columnType: 'text',
  },
  {
    title: 'Año',
    dataIndex: 'year',
    key: 'year',
    columnType: 'text',
  },
];
const PAGE_SIZE = 50;

export default function VehiclePanel() {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | undefined>();
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/vehicle/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { isLoading, isError, data, isFetching } = useQuery({
    queryKey: ['getVehicles', params],
    queryFn: () => getVehicles(params),
  });

  useEffect(() => {
    setParams((state) => ({ ...state, search: debouncedSearch }));
  }, [debouncedSearch]);

  const handleFiltering = (filter: DataTableFilterCallback) => {
    setParams((state) => ({ ...state, filter: serializeFilters(params.filter, filter) }));
  };

  const handleOrdering = (order: DataTableOrderCallback) => {
    setParams((state) => ({ ...state, order: serializeOrders(params.order, order) }));
  };

  const handlePageChange = (page: number) => {
    setParams((state) => ({ ...state, page }));
  };

  return (
    <Row>
      <Col span={24}>
        <Space size={'middle'}>
          <Button type="primary" onClick={() => setCreateModalOpen(true)}>
            Crear Vehículo
          </Button>
          <Input
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Space>
      </Col>
      {isLoading || isError || isFetching ? (
        <PanelSkeleton />
      ) : (
        <>
          <Col span={24} style={{ marginTop: '10px' }}>
            <DataTable<components['schemas']['VehicleCondensed']>
              handleFilters={handleFiltering}
              handleOrder={handleOrdering}
              handlePageChange={handlePageChange}
              columns={vehiclesTableColumns}
              results={data.results}
              activeFilters={params.filter}
              activeOrders={params.order}
              paginationData={{
                pageSize: PAGE_SIZE,
                count: data.count,
                currentPage: data.currentPage,
              }}
              actions={[EditVehicle, DeleteVehicle]}
              onRowClick={(record) => {
                setSelectedVehicleId(record.id);
                setDetailModalOpen(true);
              }}
            />
          </Col>

          <VehicleDetail
            vehicleId={selectedVehicleId}
            open={detailModalOpen}
            onCancel={() => setDetailModalOpen(false)}
          />

          <CreateVehicle
            open={createModalOpen}
            onCancel={() => setCreateModalOpen(false)}
            onCreate={() => setCreateModalOpen(false)}
          />
        </>
      )}
    </Row>
  );
}
