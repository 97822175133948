import { DollarOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, InputNumber, Modal } from 'antd';
import React, { useState } from 'react';
import { changePrice } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';
import TextArea from 'antd/es/input/TextArea';
import formatMoneyToMXN from '../../../shared/shipment/formatMoney';

export default function EditPriceModal({ record }: DataTableActionProps<components['schemas']['WarehouseCondensed']>) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['ChangePriceRequest']) => changePrice(record.shipmentId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getWarehouseList'] });
      message.success('Precio cambiado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });
  const handleButtonClick = async () => {
    try {
      const { price, notes } = await form.validateFields();
      mutation.mutate({
        price,
        notes: `Cambio de precio de ${formatMoneyToMXN(record.price)} a ${formatMoneyToMXN(price)}. Motivo: ${
          notes as string
        }`,
      });
    } catch (e) {}
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        title="Editar precio"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button type="primary" key="submit" loading={mutation.isLoading} onClick={handleButtonClick}>
            Guardar
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
        ]}
      >
        <Form
          form={form}
          initialValues={{
            price: record.price,
          }}
          layout="vertical"
          name="edit-price"
          scrollToFirstError
        >
          <Form.Item
            name={'price'}
            label="Precio"
            rules={[
              {
                required: true,
                message: 'Ingresa el precio',
                type: 'number',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) => `$ ${value!}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => (value ? value.replace(/\$\s?|(,*)/g, '') : '')}
            />
          </Form.Item>
          <Form.Item
            name={'notes'}
            label="Motivo de cambio"
            rules={[
              {
                required: true,
                message: 'Ingresa un motivo de cambio de precio',
                type: 'string',
              },
            ]}
          >
            <TextArea style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
      <DollarOutlined onClick={() => setOpen(true)} />
    </React.Fragment>
  );
}
