/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Col, Input, InputRef, Row, Space, Statistic, Table, TableColumnsType, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { getShipmentDetails } from '../../api';
import useDebounce from '../../shared/hooks/useDebounce';
import PanelSkeleton from '../../shared/skeletons/PanelSkeleton';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import formatAddress from '../../shared/client/formatAddress';
import formatMoneyToMXN from '../../shared/shipment/formatMoney';

const statusMap = {
  AT_PICKUP_ADDRESS: 'En recolección',
  PICKED_UP: 'Recolectado',
  AT_WAREHOUSE: 'En piso',
  READY_TO_DELIVER: 'Listo para entregar',
  IN_TRANSIT: 'En tránsito',
  DELIVERED: 'Entregado',
};

interface ExpandedDataType {
  key: React.Key;
  count: number;
  productName: string;
  merchDesc: string;
  amount: number;
  weight: string;
}

interface DataType {
  key: React.Key;
  count: number;
  dimensionsW: string;
  dimensionsL: string;
  dimensionsH: string;
  weight: string;
  totalPrice: string;
  items: ExpandedDataType[];
}

const FILES_ENDPOINT = process.env.REACT_APP_FACTURAS_FILES_ENDPOINT;

const columns: TableColumnsType<DataType> = [
  {
    title: '#',
    dataIndex: 'count',
    align: 'center',
  },
  {
    title: 'Ancho',
    dataIndex: 'dimensionsW',
  },
  {
    title: 'Largo',
    dataIndex: 'dimensionsL',
  },
  {
    title: 'Altura',
    dataIndex: 'dimensionsH',
  },
  {
    title: 'Peso',
    dataIndex: 'weight',
    align: 'right',
  },
  {
    title: 'Precio total',
    dataIndex: 'totalPrice',
    align: 'right',
  },
];

export default function SearchPanel() {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 300);
  const [scannedId, setScannedId] = useState<string | undefined>();
  const inputRef = React.useRef<InputRef>(null);
  const { isLoading, isError, data, isFetching } = useQuery({
    queryKey: ['getShipmentDetails', scannedId],
    queryFn: () => getShipmentDetails(scannedId),
    enabled: !!scannedId,
    retry: false,
  });

  useEffect(() => {
    if (debouncedSearch.length === 10) {
      setScannedId(debouncedSearch);
    }
  }, [debouncedSearch]);

  const handleClean = () => {
    setSearch('');
    setScannedId(undefined);
    inputRef.current?.focus();
  };

  const expandedRowRender = (record: any) => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: '#',
        dataIndex: 'count',
        align: 'center',
      },
      {
        title: 'Clave de los bienes',
        dataIndex: 'productName',
      },
      {
        title: 'Descripción',
        dataIndex: 'merchDesc',
      },
      {
        title: 'Cantidad',
        dataIndex: 'amount',
        align: 'center',
      },
      {
        title: 'Peso',
        dataIndex: 'weight',
        align: 'right',
      },
    ];

    return <Table columns={columns} dataSource={record.items} pagination={false} />;
  };

  return (
    <Row>
      <Col span={24}>
        <Space size={'middle'}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Buscar envío
          </Typography.Title>
          <Input
            placeholder="Buscar por número de guía..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
            autoFocus
            maxLength={10}
            ref={inputRef}
          />
          <Button type="primary" icon={<ReloadOutlined />} onClick={handleClean}>
            Limpiar
          </Button>
        </Space>
      </Col>
      {scannedId ? (
        <>
          {isLoading || isFetching ? (
            <PanelSkeleton />
          ) : (
            <>
              {isError ? (
                <Col span={24} style={{ marginTop: '10px' }}>
                  No se encontró el envío
                </Col>
              ) : (
                <Col span={24} style={{ marginTop: '10px' }}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <div className="card-grid">
                        <Card bordered={false} title="Detalle" className="custom-card-header">
                          <Row gutter={16}>
                            <Col span={6}>
                              <Statistic title="Estatus" value={statusMap[data.shipment.shipmentStatus]} />
                            </Col>
                            <Col span={6}>
                              <Statistic
                                title="Fecha creación"
                                value={moment(data.shipment.createdAt).format('DD/MM/YYYY HH:mm')}
                              />
                            </Col>
                            <Col span={6}>
                              <Statistic
                                title="Número de rastreo"
                                value={data.shipment.trackingNumber}
                                formatter={(v) => v}
                              />
                            </Col>
                            <Col span={6}>
                              <Statistic title="Cliente" value={data.shipment.client.name} />
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={6}>
                              <Statistic
                                title="Peso total"
                                value={`${data.shipmentPackages.reduce((acc, p) => acc + p.totalWeightInKg, 0)} kg`}
                              />
                            </Col>
                            <Col span={6}>
                              <Statistic title="Total paquetes" value={data.shipmentPackages.length} />
                            </Col>
                            <Col span={6}>
                              <Statistic
                                title="Precio"
                                value={data.shipment.totalPrice!}
                                formatter={(v) => formatMoneyToMXN(v as number)}
                              />
                            </Col>
                          </Row>
                        </Card>
                        {data.shipment.needsPickup && (
                          <Card bordered={false} title="Recolección" className="custom-card-header">
                            <Row gutter={16}>
                              <Col span={6}>
                                <Statistic
                                  title="Fecha programada recolección"
                                  value={
                                    data.shipment.needsPickup
                                      ? moment(data.shipmentPickup.scheduledPickupDate).format('DD/MM/YYYY')
                                      : 'N/A'
                                  }
                                />
                              </Col>
                              <Col span={6}>
                                <Statistic
                                  title="Recolectado el"
                                  value={
                                    data.shipmentPickup.pickedUpAt
                                      ? moment(data.shipmentPickup.pickedUpAt).format('DD/MM/YYYY HH:mm')
                                      : 'Sin recolectar'
                                  }
                                />
                              </Col>
                              <Col span={6}>
                                <Statistic
                                  title="Recolectado por"
                                  value={
                                    data.shipmentPickup.pickedUpBy
                                      ? `${data.shipmentPickup.pickedUpBy.firstName} ${data.shipmentPickup.pickedUpBy.lastName}`
                                      : 'Sin recolectar'
                                  }
                                  formatter={(v) => v}
                                />
                              </Col>
                              <Col span={6}>
                                <Statistic title="Zona recolección" value={data.shipmentPickup.zone.name} />
                              </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }}>
                              <Col span={8}>
                                <Statistic
                                  title="Dirección recolección"
                                  value={formatAddress(data.shipment.pickupAddress)}
                                />
                              </Col>
                              <Col span={8}>
                                <Statistic
                                  title="Contacto recolección"
                                  value={
                                    data.shipment.pickupContact
                                      ? data.shipment.pickupContact.name
                                      : data.shipment.client.name
                                  }
                                />
                              </Col>
                              {data.shipmentAtWarehouse && (
                                <Col span={8}>
                                  <Statistic
                                    title="Fecha de arribo al almacén"
                                    value={
                                      data.shipment.needsPickup
                                        ? moment(data.shipmentAtWarehouse.arrivedAtWarehouse).format('DD/MM/YYYY HH:mm')
                                        : 'N/A'
                                    }
                                  />
                                </Col>
                              )}
                            </Row>
                          </Card>
                        )}
                        {data.shipmentInTransit && (
                          <Card bordered={false} title="Entrega" className="custom-card-header">
                            <Row gutter={16}>
                              <Col span={6}>
                                <Statistic
                                  title="En tránsito desde"
                                  value={moment(data.shipmentInTransit.inTransitAt).format('DD/MM/YYYY HH:mm')}
                                />
                              </Col>
                              <Col span={6}>
                                <Statistic
                                  title="Operador"
                                  value={`${data.shipmentInTransit.driver.firstName} ${data.shipmentInTransit.driver.lastName}`}
                                />
                              </Col>
                              <Col span={6}>
                                <Statistic title="Vehículo" value={data.shipmentInTransit.vehicle.name} />
                              </Col>
                              <Col span={6}>
                                <Statistic
                                  title="Zona entrega"
                                  value={data.shipment.deliveryAddress.zipCode.zone.name}
                                />
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Statistic
                                  title="Dirección entrega"
                                  value={formatAddress(data.shipment.deliveryAddress)}
                                />
                              </Col>
                              <Col span={12}>
                                <Statistic
                                  title="Nombre del destinatario"
                                  value={data.shipment.deliveryAddress.recipientName}
                                />
                              </Col>
                            </Row>
                            {data.shipmentInTransit.deliveredAt && (
                              <Row style={{ marginTop: 10 }}>
                                <Col span={6}>
                                  <Statistic
                                    title="Entregado el"
                                    value={moment(data.shipmentInTransit.deliveredAt).format('DD/MM/YYYY HH:mm')}
                                  />
                                </Col>
                              </Row>
                            )}
                          </Card>
                        )}
                        <Card bordered={false} title="Paquetes" className="custom-card-header">
                          <Row gutter={16}>
                            <Col span={24}>
                              <Table
                                className="ant-table-preserve-paddings"
                                columns={columns}
                                pagination={false}
                                expandable={{ expandedRowRender }}
                                dataSource={data.shipmentPackages.map((p, i) => ({
                                  key: i,
                                  count: i + 1,
                                  dimensionsW: `${p.dimensionsW} cm`,
                                  dimensionsL: `${p.dimensionsL} cm`,
                                  dimensionsH: `${p.dimensionsH} cm`,
                                  weight: `${p.totalWeightInKg} kg`,
                                  totalPrice: formatMoneyToMXN(p.totalPrice!),
                                  items: p.merchItems.map((item, j) => ({
                                    key: j,
                                    count: j + 1,
                                    productName: item.product.name,
                                    merchDesc: item.description,
                                    amount: item.amount,
                                    weight: `${item.weightInKg} kg`,
                                  })),
                                }))}
                              />
                            </Col>
                          </Row>
                        </Card>
                        {data.shipment.facturaInvoiceUID ||
                        data.shipment.notaCreditoUID ||
                        data.shipment?.payOff?.facturaREPInvoiceUID ? (
                          <Card bordered={false} title="Ver CFDI" className="custom-card-header">
                            <Space>
                              {data.shipment.facturaInvoiceUID && (
                                <Card title="Carta porte">
                                  <Space>
                                    <Button
                                      type="primary"
                                      href={`${FILES_ENDPOINT}/${data.shipment.facturaInvoiceUID}.pdf`}
                                      target="_blank"
                                    >
                                      PDF
                                    </Button>
                                    <Button
                                      type="primary"
                                      href={`${FILES_ENDPOINT}/${data.shipment.facturaInvoiceUID}.xml`}
                                      target="_blank"
                                    >
                                      XML
                                    </Button>
                                  </Space>
                                </Card>
                              )}
                              {data.shipment.notaCreditoUID && (
                                <Card title="Nota crédito">
                                  <Space>
                                    <Button
                                      type="primary"
                                      href={`${FILES_ENDPOINT}/${data.shipment.notaCreditoUID}.pdf`}
                                      target="_blank"
                                    >
                                      PDF
                                    </Button>
                                    <Button
                                      type="primary"
                                      href={`${FILES_ENDPOINT}/${data.shipment.notaCreditoUID}.xml`}
                                      target="_blank"
                                    >
                                      XML
                                    </Button>
                                  </Space>
                                </Card>
                              )}
                              {data.shipment?.payOff?.facturaREPInvoiceUID && (
                                <Card title="REP">
                                  <Space>
                                    <Button
                                      type="primary"
                                      href={`${FILES_ENDPOINT}/${data.shipment?.payOff?.facturaREPInvoiceUID}.pdf`}
                                      target="_blank"
                                    >
                                      PDF
                                    </Button>
                                    <Button
                                      type="primary"
                                      href={`${FILES_ENDPOINT}/${data.shipment?.payOff?.facturaREPInvoiceUID}.xml`}
                                      target="_blank"
                                    >
                                      XML
                                    </Button>
                                  </Space>
                                </Card>
                              )}
                            </Space>
                          </Card>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
            </>
          )}
        </>
      ) : null}
    </Row>
  );
}
