import { useQuery } from '@tanstack/react-query';
import { Col, Input, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { getShipmentDeliveredList } from '../../api';
import { components, paths } from '../../api/schema';
import { AvailableEntity } from '../../shared/commentsLogger/CommentsLogger';
import DataTable, {
  ColumnsType,
  DataTableFilterCallback,
  DataTableOrderCallback,
  serializeFilters,
  serializeOrders,
} from '../../shared/dataTable/DataTable';
import useDebounce from '../../shared/hooks/useDebounce';
import PanelSkeleton from '../../shared/skeletons/PanelSkeleton';

import { SearchOutlined } from '@ant-design/icons';
import { LabelDrawer } from '../pickup/pickupPanelActions/LabelDrawer';
import { RangePicker } from '../../shared/dateComponents';
import moment from 'moment-timezone';
import { RangePickerProps } from '../../shared/dateComponents/RangePicker';
import useConditionalRefresh from '../../shared/hooks/useConditionalRefresh';
import { ViewCfdi } from '../../shared/shipment/ViewCfdi';
const deliveredTableColumns: ColumnsType<components['schemas']['DeliveredCondensed']> = [
  {
    title: 'Entregado en',
    dataIndex: 'deliveredAt',
    key: 'deliveredAt',
    columnType: 'date',
  },
  {
    title: 'Número de rastreo',
    dataIndex: 'trackingNumber',
    key: 'trackingNumber',
    columnType: 'text',
  },
  {
    title: 'Zona de entrega',
    dataIndex: 'zone',
    key: 'zone',
    columnType: 'text',
  },
  {
    title: 'Cliente',
    dataIndex: 'clientName',
    key: 'clientName',
    columnType: 'text',
  },
  {
    title: 'Dirección de  entrega',
    dataIndex: 'address',
    key: 'address',
    columnType: 'text',
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    key: 'price',
    columnType: 'number',
  },
  {
    title: 'Peso total',
    dataIndex: 'totalWeight',
    key: 'totalWeight',
    columnType: 'text',
    hideFilter: true,
  },
  {
    title: 'Total de paquetes',
    dataIndex: 'totalPackages',
    key: 'totalPackages',
    columnType: 'text',
    hideFilter: true,
  },
  {
    title: 'Conductor',
    dataIndex: 'driver',
    key: 'driver',
    columnType: 'text',
    hideFilter: true,
  },
  {
    title: 'Vehículo',
    dataIndex: 'vehicle',
    key: 'vehicle',
    columnType: 'text',
  },
];

const rangePickerPresets: RangePickerProps['presets'] = [
  { label: 'Hoy', value: [moment().startOf('day'), moment().endOf('day')] },
  { label: 'Ayer', value: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')] },
  { label: 'Esta semana', value: [moment().startOf('week'), moment().endOf('week')] },
  {
    label: 'Semana pasada',
    value: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
  },
  { label: 'Este mes', value: [moment().startOf('month'), moment().endOf('month')] },
  {
    label: 'Mes pasado',
    value: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
  },
  { label: 'Este año', value: [moment().startOf('year'), moment().endOf('year')] },
  {
    label: 'Año pasado',
    value: [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')],
  },
];

const PAGE_SIZE = 50;

export default function DeliveredPanel() {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/shipment/delivered/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
    startDate: moment().startOf('month').unix(),
    endDate: moment().endOf('month').unix(),
  });
  const { isLoading, isError, data, isFetching, refetch } = useQuery({
    queryKey: ['getShipmentDeliveredList', params],
    queryFn: () => getShipmentDeliveredList(params),
  });

  useConditionalRefresh(60000, refetch);

  useEffect(() => {
    setParams((state) => ({ ...state, search: debouncedSearch }));
  }, [debouncedSearch]);

  const handleFiltering = (filter: DataTableFilterCallback) => {
    setParams((state) => ({ ...state, filter: serializeFilters(params?.filter, filter) }));
  };

  const handleOrdering = (order: DataTableOrderCallback) => {
    setParams((state) => ({ ...state, order: serializeOrders(params?.order, order) }));
  };

  const handlePageChange = (page: number) => {
    setParams((state) => ({ ...state, page }));
  };

  const handleDateChange = (dates: [moment.Moment | null, moment.Moment | null] | null) => {
    setParams((state) => {
      if (dates && Array.isArray(dates) && dates[0] !== null && dates[1] !== null) {
        return {
          ...state,
          startDate: dates[0].startOf('day').unix(),
          endDate: dates[1].endOf('day').unix(),
        };
      } else {
        return { ...state };
      }
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Space size={'middle'}>
          <RangePicker
            value={[moment.unix(params.startDate!), moment.unix(params.endDate!)]}
            onChange={handleDateChange}
            format={'DD/MM/YYYY'}
            presets={rangePickerPresets}
          />
          <Input
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Space>
      </Col>
      {isLoading || isError || isFetching ? (
        <PanelSkeleton />
      ) : (
        <>
          <Col span={24} style={{ marginTop: '10px' }}>
            <DataTable<components['schemas']['DeliveredCondensed']>
              handleFilters={handleFiltering}
              handleOrder={handleOrdering}
              handlePageChange={handlePageChange}
              columns={deliveredTableColumns}
              results={data.results}
              activeFilters={params?.filter}
              activeOrders={params?.order}
              paginationData={{
                pageSize: PAGE_SIZE,
                count: data.count,
                currentPage: data.currentPage,
              }}
              commentsLogger={{
                entity: AvailableEntity.SHIPMENT,
                queryKeyToInvalidate: 'getShipmentDeliveredList',
                entityKey: 'shipmentId',
              }}
              actions={[LabelDrawer, ViewCfdi]}
            />
          </Col>
        </>
      )}
    </Row>
  );
}
