import { Input, InputNumber, Select } from 'antd';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { DatePicker } from '../dateComponents';
import { DatePickerProps } from '../dateComponents/DatePicker';
import { AvailableFilterTypes } from './DataTableFilter';

interface FilterOption {
  key: string;
  filterName: string;
}

interface FilterOptionType {
  type: AvailableFilterTypes;
  typeLabel: string;
  options: FilterOption[];
}

interface FilterOptions {
  typeText: FilterOptionType;
  typeDate: FilterOptionType;
  typeNumber: FilterOptionType;
}
const filterOptions: FilterOptions = {
  typeText: {
    type: 'text',
    typeLabel: 'Texto',
    options: [
      {
        key: 'textIncludes',
        filterName: 'El texto contiene',
      },
      {
        key: 'textNotIncludes',
        filterName: 'El texto no contiene',
      },
      {
        key: 'textStartsWith',
        filterName: 'El texto comienza por',
      },
      {
        key: 'textEndsWith',
        filterName: 'El texto termina en',
      },
      {
        key: 'textExact',
        filterName: 'El texto es exactamente',
      },
    ],
  },
  typeDate: {
    type: 'date',
    typeLabel: 'Fecha',
    options: [
      {
        key: 'dateAt',
        filterName: 'La fecha es',
      },
      {
        key: 'dateBeforeAt',
        filterName: 'La fecha es anterior a',
      },
      {
        key: 'dateAfterAt',
        filterName: 'La fecha posterior a',
      },
    ],
  },
  typeNumber: {
    type: 'number',
    typeLabel: 'Número',
    options: [
      {
        key: 'numberGreaterThan',
        filterName: 'Mayor que',
      },
      {
        key: 'numberGreaterThanOrEqual',
        filterName: 'Mayor o igual que',
      },
      {
        key: 'numberLessThan',
        filterName: 'Menor que',
      },
      {
        key: 'numberLessThanOrEqual',
        filterName: 'Menor o igual que',
      },
      {
        key: 'numberEquals',
        filterName: 'Es igual a',
      },
      {
        key: 'numberNotEquals',
        filterName: 'No es igual a',
      },
    ],
  },
};

const filterByDateAvailableOptions = [
  { label: 'Hoy', value: 'today' },
  { label: 'Mañana', value: 'tomorrow' },
  { label: 'Ayer', value: 'yesterday' },
  { label: 'Semana pasada', value: 'duringLastWeek' },
  { label: 'Mes pasado', value: 'duringLastMonth' },
  { label: 'Año pasado', value: 'duringLastYear' },
  { label: 'Fecha exacta', value: 'exact' },
];

export interface HandleFilteredCallback {
  filterConditionType: string;
  filterConditionVal: any;
  filterConditionValExtra?: any | null;
}

interface FilterByCondProps {
  columnType: AvailableFilterTypes;
  handleFiltered: (filter: HandleFilteredCallback | null) => void;
  prefilledCond: HandleFilteredCallback | null;
}
export default function DataTableFilterByCond({ columnType, handleFiltered, prefilledCond }: FilterByCondProps) {
  const [availableOptions, setAvailableOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [currentFilterType, setCurrentFilterType] = useState<keyof FilterOptions>();
  const [selectedFilterOption, setSelectedFilterOption] = useState('none');
  const [textInputVal, setTextInputVal] = useState<string | null>(null);
  const [numberInputVal, setNumberInputVal] = useState<number | null>(null);
  const [selectedDateOption, setSelectedDateOption] = useState('today');
  const [selectedDateVal, setSelectedDateVal] = useState<string | null>(null);
  useEffect(() => {
    const cellType = Object.keys(filterOptions).find(
      (fO) => filterOptions[fO as keyof FilterOptions].type === columnType,
    );
    setCurrentFilterType(cellType as keyof FilterOptions);
    setAvailableOptions(
      filterOptions[cellType as keyof FilterOptions].options.map((op) => ({
        label: op.filterName,
        value: op.key,
      })),
    );

    return () => {
      setSelectedFilterOption('none');
      setTextInputVal(null);
      setNumberInputVal(null);
      setSelectedDateOption('today');
      setSelectedDateVal(null);
      handleFiltered(null);
    };
  }, []);

  useEffect(() => {
    if (prefilledCond) {
      const cellType = Object.keys(filterOptions).find(
        (fO) => filterOptions[fO as keyof FilterOptions].type === columnType,
      );
      setSelectedFilterOption(prefilledCond.filterConditionType);

      setTimeout(() => {
        if (cellType === 'typeText') {
          setTextInputVal(prefilledCond.filterConditionVal);
        } else if (cellType === 'typeNumber') {
          setNumberInputVal(prefilledCond.filterConditionVal);
        } else if (cellType === 'typeDate') {
          setSelectedDateOption(prefilledCond.filterConditionVal);
          setSelectedDateVal(prefilledCond.filterConditionValExtra);
        }
      }, 10);
    }
  }, [prefilledCond]);

  useEffect(() => {
    if (selectedFilterOption !== 'none') {
      if (currentFilterType === 'typeText' && textInputVal) {
        handleFiltered({
          filterConditionType: selectedFilterOption,
          filterConditionVal: textInputVal,
        });
      } else if (currentFilterType === 'typeNumber' && numberInputVal) {
        handleFiltered({
          filterConditionType: selectedFilterOption,
          filterConditionVal: numberInputVal,
        });
      } else if (currentFilterType === 'typeDate' && selectedDateOption) {
        if (selectedDateOption === 'exact' && selectedDateVal) {
          handleFiltered({
            filterConditionType: selectedFilterOption,
            filterConditionVal: selectedDateOption,
            filterConditionValExtra: selectedDateVal,
          });
        } else {
          handleFiltered({
            filterConditionType: selectedFilterOption,
            filterConditionVal: selectedDateOption,
          });
        }
      }
    } else {
      handleFiltered(null);
    }
  }, [textInputVal, numberInputVal, selectedDateOption, selectedDateVal, currentFilterType, selectedFilterOption]);

  useEffect(() => {
    if (selectedFilterOption === 'none') {
      setTextInputVal(null);
      setNumberInputVal(null);
      setSelectedDateOption('today');
      setSelectedDateVal(null);
      handleFiltered(null);
    }
  }, [selectedFilterOption]);

  const handleFilterOptionChange = (value: string) => {
    setSelectedFilterOption(value);
  };

  const handleTypeTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextInputVal(e.target.value);
  };

  const handleTypeNumberInput = (value: number | null) => {
    setNumberInputVal(value);
  };

  const handleInputSelectChange = (value: string) => {
    setSelectedDateOption(value);
  };

  const handleChangeExactDateFilter: DatePickerProps['onChange'] = (_, dateString) => {
    setSelectedDateVal(dateString);
  };

  return (
    <div style={{ padding: '0 25px' }}>
      {availableOptions?.length && (
        <>
          <Select
            defaultValue={'none'}
            style={{ width: 200, marginBottom: 5 }}
            onChange={handleFilterOptionChange}
            value={selectedFilterOption}
            options={[{ label: 'Ninguno', value: 'none' }, ...availableOptions]}
          />
          {selectedFilterOption !== 'none' &&
            (currentFilterType === 'typeDate' ? (
              <>
                <Select
                  value={selectedDateOption}
                  style={{ width: 200, marginBottom: 5 }}
                  onChange={handleInputSelectChange}
                  options={filterByDateAvailableOptions}
                />
                {selectedDateOption === 'exact' && (
                  <DatePicker
                    value={selectedDateVal === null ? undefined : moment(selectedDateVal)}
                    style={{ marginTop: 5, width: 200 }}
                    onChange={handleChangeExactDateFilter}
                  />
                )}
              </>
            ) : currentFilterType === 'typeNumber' ? (
              <InputNumber
                value={numberInputVal === null ? undefined : numberInputVal}
                style={{ width: 200 }}
                onChange={handleTypeNumberInput}
              />
            ) : currentFilterType === 'typeText' ? (
              <Input value={textInputVal === null ? undefined : textInputVal} onChange={handleTypeTextInput} />
            ) : null)}
        </>
      )}
    </div>
  );
}
