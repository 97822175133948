import { DeleteTwoTone } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Popconfirm } from 'antd';
import { useState } from 'react';
import { deleteVehicle } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';

export const DeleteVehicle = ({ record }: DataTableActionProps<components['schemas']['VehicleCondensed']>) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (id: string) => deleteVehicle(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getVehicles'] });
      message.success('Vehículo eliminado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  return (
    <Popconfirm
      title="Eliminar"
      description={`Estas seguro que deseas eliminar a ${record.name}`}
      open={open}
      onConfirm={() => mutation.mutate(record.id)}
      okButtonProps={{ loading: mutation.isLoading }}
      onCancel={() => setOpen(false)}
      cancelText="Cancelar"
      okText="Eliminar"
    >
      <DeleteTwoTone twoToneColor={'#cf1322'} onClick={() => setOpen(true)} />
    </Popconfirm>
  );
};
