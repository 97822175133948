import { LoadingOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { App, Input, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { getZipCodes, getZipCode } from '../../api';
import { components, paths } from '../../api/schema';
import useDebounce from '../hooks/useDebounce';

const renderItem = (zipCode: components['schemas']['ZipcodeCondensed']) => ({
  key: `${zipCode.id}`,
  value: zipCode.id,
  zipCode,
  label: (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {zipCode.zipCode}
      <span>
        {zipCode.district} / {zipCode.city}
      </span>
    </div>
  ),
});

interface SelectZipCodeProps {
  value?: string;
  onChange?: (value: string) => void;
  onSelected: (zipCode: components['schemas']['ZipcodeCondensed']) => void;
  preselectedZipCodeId?: string;
  // Prop added to control if the element is disabled or not in UI
  disabled?: boolean;
}
const PAGE_SIZE = 30;
export default function SelectZipCode({ onChange, onSelected, preselectedZipCodeId, disabled }: SelectZipCodeProps) {
  const [options, setOptions] = useState<
    Array<{ value: string; label: JSX.Element; zipCode: components['schemas']['ZipcodeCondensed'] }>
  >([]);
  const [search, setSearch] = useState<string>('');
  const [inputVal, setInputVal] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/zipcode/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });

  const [randomQueryKey] = useState(`getZipCode-${Math.random()}`);
  const [randomQueryKeyMultiple] = useState(`getZipCode-${Math.random()}`);
  const { message } = App.useApp();

  const { data: zipCode, isFetching: isFetchingSingleZipCode } = useQuery({
    queryKey: [randomQueryKey, preselectedZipCodeId],
    queryFn: () => getZipCode(preselectedZipCodeId),
    enabled: preselectedZipCodeId !== undefined,
  });

  const { data, isFetching } = useQuery({
    queryKey: [randomQueryKeyMultiple, params],
    queryFn: () => getZipCodes(params),
    enabled: debouncedSearch.length > 0,
  });

  useEffect(() => {
    if (data?.results.length) {
      setOptions(data.results.map((zipCode) => renderItem(zipCode)));
    } else {
      setOptions([]);
    }
    setOpen(true);
  }, [data]);

  useEffect(() => {
    if (preselectedZipCodeId && zipCode) {
      setInputVal(zipCode.zipCode);
      onChange?.(zipCode.id);
      onSelected({
        id: zipCode.id,
        city: zipCode.city,
        district: zipCode.district,
        municipio: zipCode.municipio,
        price: zipCode.price.price,
        priceId: zipCode.priceId,
        state: zipCode.state,
        stateKey: zipCode.stateKey,
        outOfRange: zipCode.outOfRange,
        zipCode: zipCode.zipCode,
        zoneId: zipCode.zoneId,
        zoneName: zipCode.zoneName,
        zoneType: zipCode.zoneType,
      });
    }
  }, [zipCode, preselectedZipCodeId]);

  useEffect(() => {
    if (debouncedSearch.length) {
      setParams((state) => ({ ...state, search: debouncedSearch }));
      setOpen(false);
    } else {
      setOpen(false);
      setOptions([]);
    }
  }, [debouncedSearch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setInputVal(e.target.value);
  };

  const handleSelected = (zipCode: components['schemas']['ZipcodeCondensed']) => {
    if (zipCode.outOfRange) {
      message.error('El código postal seleccionado está fuera de rango');
      return;
    }
    setInputVal(zipCode.zipCode);
    onSelected(zipCode);
    onChange?.(zipCode.id);
    setOpen(false);
  };

  const content = (
    <div style={{ width: 350, maxHeight: 350, overflowY: 'auto' }}>
      {options.map((option, k) => (
        <div
          className={`custom-popover-item ${option.zipCode.outOfRange && 'out-of-range'}`}
          key={k}
          onClick={() => handleSelected(option.zipCode)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
  return (
    <Popover content={content} open={options.length > 0 && open} arrow={false}>
      <Input
        disabled={disabled}
        placeholder="Buscar código postal"
        onChange={handleInputChange}
        value={inputVal}
        suffix={isFetching || isFetchingSingleZipCode ? <LoadingOutlined /> : <span />}
      />
    </Popover>
  );
}
