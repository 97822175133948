import { FileTextOutlined } from '@ant-design/icons';
import { Button, Card, Modal, Space } from 'antd';
import { useState } from 'react';
import { DataTableActionProps } from '../dataTable/DataTable';

export interface ViewCfdiProps {
  facturaInvoiceUID: string;
  notaCreditoUID: string;
  facturaRepUID: string;
}

const FILES_ENDPOINT = process.env.REACT_APP_FACTURAS_FILES_ENDPOINT;

export const ViewCfdi = ({ record }: DataTableActionProps<ViewCfdiProps>) => {
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        title="Visualizar CFDI"
        style={{ top: 20 }}
        destroyOnClose
        width={590}
        maskClosable={false}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
        ]}
      >
        <>
          <Space>
            {record.facturaInvoiceUID && (
              <Card title="Carta porte">
                <Space>
                  <Button type="primary" href={`${FILES_ENDPOINT}/${record.facturaInvoiceUID}.pdf`} target="_blank">
                    PDF
                  </Button>
                  <Button type="primary" href={`${FILES_ENDPOINT}/${record.facturaInvoiceUID}.xml`} target="_blank">
                    XML
                  </Button>
                </Space>
              </Card>
            )}
            {record.notaCreditoUID && (
              <Card title="Nota crédito">
                <Space>
                  <Button type="primary" href={`${FILES_ENDPOINT}/${record.notaCreditoUID}.pdf`} target="_blank">
                    PDF
                  </Button>
                  <Button type="primary" href={`${FILES_ENDPOINT}/${record.notaCreditoUID}.xml`} target="_blank">
                    XML
                  </Button>
                </Space>
              </Card>
            )}
            {record.facturaRepUID && (
              <Card title="REP">
                <Space>
                  <Button type="primary" href={`${FILES_ENDPOINT}/${record.facturaRepUID}.pdf`} target="_blank">
                    PDF
                  </Button>
                  <Button type="primary" href={`${FILES_ENDPOINT}/${record.facturaRepUID}.xml`} target="_blank">
                    XML
                  </Button>
                </Space>
              </Card>
            )}
          </Space>
        </>
      </Modal>
      <FileTextOutlined onClick={() => setOpen(true)} />
    </>
  );
};
