import { useQuery } from '@tanstack/react-query';
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Skeleton, Space } from 'antd';
import { useEffect, useState } from 'react';
import { getDefaultsSAT } from '../../api';
import { components } from '../../api/schema';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';
import SearchSATProduct from '../../shared/sat/SearchProductKeys';
import SearchSATUnit from '../../shared/sat/SearchUnitKeys';

export interface NewMerchItem {
  key?: string;
  count?: number;
  productKey: string;
  productName: string;
  unitKey: string;
  amount: number;
  weight: number;
  merchDesc: string;
}

interface AddMerchProps {
  preselectedMerch?: NewMerchItem;
  open: boolean;
  onCreate: (merch: NewMerchItem) => void;
  onCancel: () => void;
}

export default function AddMerchDrawer({ open, preselectedMerch, onCreate, onCancel }: AddMerchProps) {
  const [form] = Form.useForm<NewMerchItem>();
  const [productName, setProductName] = useState<string>('');
  const { data } = useQuery({
    queryKey: ['getDefaultsSAT'],
    queryFn: () => getDefaultsSAT(),
  });
  useResetFormOnCloseModal({ form, open });

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setProductName('');
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        unitKey: data.unitKeyId,
      });
    }
  }, [data]);

  useEffect(() => {
    if (preselectedMerch) {
      form.setFieldsValue({
        productKey: preselectedMerch.productKey,
        productName,
        unitKey: preselectedMerch.unitKey,
        amount: preselectedMerch.amount,
        weight: preselectedMerch.weight,
        merchDesc: preselectedMerch.merchDesc,
      });
    }
  }, [preselectedMerch]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      values.productName = productName;
      if (preselectedMerch) {
        onCreate({ ...values, key: preselectedMerch.key, count: preselectedMerch.count });
      } else {
        onCreate(values);
      }
    } catch (e) {}
  };

  const handleProductSelect = (product: components['schemas']['SATProduct']) => {
    setProductName(product.name);
  };
  return (
    <Drawer
      open={open}
      title="Agregar mercancía"
      style={{ top: 20 }}
      width={800}
      destroyOnClose
      maskClosable={false}
      closable={false}
      extra={
        <Space>
          <Button
            key="back"
            onClick={() => {
              onCancel();
              setProductName('');
            }}
          >
            Cancelar
          </Button>
          <Button key="submit" htmlType="submit" type="primary" onClick={handleSubmit}>
            Agregar
          </Button>
        </Space>
      }
    >
      {data ? (
        <Form form={form} layout="vertical" name="add-merch-form" scrollToFirstError autoComplete="off">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={'merchDesc'}
                label="Descripción"
                rules={[{ required: true, message: 'Ingresa una descripción' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={'productKey'}
                label="Bienes transportados (Clave del SAT)"
                rules={[{ required: true, message: 'Selecciona una clave' }]}
              >
                <SearchSATProduct
                  onSelected={handleProductSelect}
                  preselectedSATProductId={form.getFieldValue('productKey')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'unitKey'}
                label="Clave unidad"
                rules={[{ required: true, message: 'Selecciona una clave' }]}
              >
                <SearchSATUnit />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={'amount'}
                label="Cantidad"
                rules={[{ required: true, message: 'Ingresa una cantidad', type: 'number' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'weight'}
                label="Peso en Kg"
                rules={[{ required: true, message: 'Ingresa el peso en kg', type: 'number' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Skeleton active />
      )}
    </Drawer>
  );
}
