import { Autocomplete, GoogleMap, Libraries, MarkerF, useLoadScript } from '@react-google-maps/api';
import React, { CSSProperties, useState } from 'react';

interface IMapProps {
  onGetCoordinates: (coords: { lat: number; lng: number }) => void;
  centerCoords?: [number, number];
}

const inputStyle: CSSProperties = {
  boxSizing: `border-box`,
  border: `1px solid transparent`,
  width: `240px`,
  height: `32px`,
  padding: `0 12px`,
  borderRadius: `3px`,
  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  fontSize: `14px`,
  outline: `none`,
  textOverflow: `ellipses`,
  position: 'absolute',
  top: '10px',
  left: '10px',
};

const libraries: Libraries = ['places'];
export default function GMap({ onGetCoordinates, centerCoords }: IMapProps) {
  const initialCoords =
    centerCoords !== undefined
      ? { lat: centerCoords[0], lng: centerCoords[1] }
      : { lat: 20.645197835216933, lng: -103.35607544502378 };

  const [latLngClick, setLatLngClick] = useState(initialCoords);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
    libraries,
  });
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [center, setCenter] = useState(initialCoords);

  const handleClick = (e: any) => {
    setLatLngClick(e.latLng);
    onGetCoordinates({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const handleSearch = (place: any) => {
    setLatLngClick({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    setCenter({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    onGetCoordinates({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  };

  const handleDragEnd = (e: any) => {
    setLatLngClick(e.latLng);
    onGetCoordinates({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };
  return (
    <React.Fragment>
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          clickableIcons={false}
          options={{ disableDefaultUI: true }}
          mapContainerClassName="map-container"
          center={center}
          zoom={15}
          onClick={handleClick}
        >
          <Autocomplete
            onLoad={(a) => {
              setAutocomplete(a);
            }}
            onPlaceChanged={() => {
              const place = autocomplete.getPlace();
              handleSearch(place);
            }}
          >
            <input type="text" placeholder="Buscar..." style={inputStyle} />
          </Autocomplete>
          <MarkerF visible draggable onDragEnd={handleDragEnd} position={latLngClick} />
        </GoogleMap>
      )}
    </React.Fragment>
  );
}
