import { CopyOutlined, LockOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { App, Button, Col, Modal, Row, Spin, Tooltip } from 'antd';
import { useState } from 'react';
import { resetPassword } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';

export const ResetPassword = ({ record }: DataTableActionProps<components['schemas']['UserCondensed']>) => {
  const [open, setOpen] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [newTempPassword, setNewTempPassword] = useState<string | undefined>(undefined);
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: () => resetPassword(record.id),
    onSuccess: (res) => {
      setNewTempPassword(res.tempPassword);
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  const handleSubmit = async () => {
    if (requestSent) {
      handleCancel();
      return;
    }
    try {
      mutation.mutate();
      setRequestSent(true);
    } catch (e) {}
  };

  // Function to copy the temporary password to the clipboard
  const copyPassword = () => {
    navigator.clipboard.writeText(newTempPassword!);
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
    setRequestSent(false);
    setNewTempPassword(undefined);
  };

  return (
    <>
      <Modal
        open={open}
        title="Resetear contraseña"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
            {requestSent ? 'Cerrar' : 'Resetear'}
          </Button>,
        ]}
      >
        {requestSent ? (
          <div>
            {mutation.isLoading ? (
              <Spin />
            ) : (
              <div>
                <Row>
                  <Col span={24}>
                    <div className="ant-form-item-label">
                      <label className="ant-form-item-required">Nueva contraseña temporal</label>
                    </div>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col
                    span={8}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '12px',
                    }}
                  >
                    <h2 id="temp-password">{newTempPassword}</h2>
                  </Col>

                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'baseline',
                      alignItems: 'end',
                      marginBottom: '12px',
                    }}
                  >
                    <Tooltip color="blue" open={tooltipVisible} title="!Copiado!">
                      <Button
                        icon={<CopyOutlined />}
                        className="btn-add_new"
                        style={{ height: '48px' }}
                        onClick={copyPassword}
                        type="primary"
                        key="1"
                      >
                        Copiar al portapapeles
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        ) : (
          <div>
            <p>¿Estás seguro de que deseas resetear la contraseña de este usuario?</p>
          </div>
        )}
      </Modal>
      <LockOutlined onClick={() => setOpen(true)} />
    </>
  );
};
