import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Alert, App, Button, Col, Divider, Form, InputNumber, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { createPrepaid } from '../../api';
import { components } from '../../api/schema';
import SearchClient from '../../shared/client/SearchClient';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';
import SearchFormaPago from '../../shared/sat/SearchFormaPago';

interface CreatePrepaidProps {
  open: boolean;
  onCreate?: () => void;
  onCancel: () => void;
}

export default function CreatePrepaid({ open, onCreate, onCancel }: CreatePrepaidProps) {
  const [clientId, setClientId] = useState<string | undefined>();
  const [client, setClient] = useState<components['schemas']['ClientCondensed'] | undefined>();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreatePrepaidShipmentRequest']) => createPrepaid(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getPrepaids'] });
      message.success('Guías prepagadas creadas correctamente');
      onCreate?.();
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  useResetFormOnCloseModal({ form, open });

  const handleSelectClient = (client: components['schemas']['ClientCondensed']) => {
    queryClient.invalidateQueries({ queryKey: ['getClient'] });
    setClientId(client.id);
    setClient(client);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate({
        ...values,
        paidAt: moment().toISOString(),
        clientId: clientId!,
        pricePaid: values.individualPrice * values.shipmentsAmount,
      });
    } catch (e) {}
  };

  const handleCancel = () => {
    form.resetFields();
    setClientId(undefined);
    setClient(undefined);
    onCancel();
  };

  return (
    <Modal
      open={open}
      title="Crear paquete de guias prepagadas"
      style={{ top: 20 }}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          loading={mutation.isLoading}
          disabled={client?.hasActivePrepaidShipment}
          onClick={handleSubmit}
        >
          Guardar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="create-prepaid-form" scrollToFirstError>
        <Form.Item name="clientId" label="Cliente" rules={[{ required: true, message: 'Selecciona un cliente' }]}>
          <SearchClient onSelected={(selectedClient) => handleSelectClient(selectedClient)} />
        </Form.Item>
        {clientId && client && (
          <>
            {client.hasActivePrepaidShipment ? (
              <Row>
                <Col span={24}>
                  <Alert message="El cliente ya tiene un paquete de guias prepagadas activo" type="error" showIcon />
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="individualPrice"
                      label="Precio individual"
                      rules={[{ required: true, message: 'Ingresa el precio individual de las guias prepagadas' }]}
                    >
                      <InputNumber
                        formatter={(value) => `$ ${value!}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        precision={2}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="shipmentsAmount"
                      label="Cantidad de guias"
                      rules={[{ required: true, message: 'Ingresa la cantidad de guias prepagadas' }]}
                    >
                      <InputNumber style={{ width: '100%' }} min={0} precision={0} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="formaPagoId"
                      label="Forma de Pago"
                      rules={[
                        {
                          required: true,
                          message: 'Selecciona una forma de pago',
                        },
                      ]}
                    >
                      <SearchFormaPago />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
}
