export const amplifyConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or n ot
    mandatorySignIn: false,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_PUBLIC_FILES,
      region: process.env.REACT_APP_AWS_REGION,
    },
  },
};
