import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Modal, Select, Spin } from 'antd';
import { editVehicleAtService, getAvailableVehicles } from '../../api';
import { components } from '../../api/schema';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';
import { EditOutlined } from '@ant-design/icons';
import { DataTableActionProps } from '../../shared/dataTable/DataTable';
import { useState } from 'react';

export default function EditVehicleAtService({
  record,
}: DataTableActionProps<components['schemas']['VehicleAtServiceCondensed']>) {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const [open, setOpen] = useState(false);

  const mutation = useMutation({
    mutationFn: (body: components['schemas']['EditVehicleAtServiceRequest']) => editVehicleAtService(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getVehiclesAtService'] });
      message.success('Vehículo asignado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });
  const { data, remove } = useQuery({
    queryKey: ['getAvailableVehicles'],
    queryFn: () => getAvailableVehicles(),
    enabled: record.id !== undefined && open,
  });
  const [form] = Form.useForm<components['schemas']['EditVehicleAtServiceRequest']>();

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate({
        vehicleId: values.vehicleId === '0' ? null : values.vehicleId,
        userId: record.driverId,
      });
    } catch (e) {}
  };

  const handleCancel = () => {
    setOpen(false);
    remove();
  };

  return (
    <>
      <Modal
        open={open}
        title="Asignar vehículo a conductor"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
            Guardar
          </Button>,
        ]}
      >
        {data ? (
          <Form form={form} layout="vertical" name="edit-vehicle-at-service-form" scrollToFirstError>
            <Form.Item
              name="vehicleId"
              label="Vehículo"
              rules={[{ required: true, message: 'Selecciona un vehículo' }]}
            >
              <Select
                style={{ width: '100%' }}
                options={[
                  {
                    createdAt: '',
                    deletedAt: '',
                    id: '0',
                    name: 'Sin vehículo',
                    plate: '',
                    respCivilInsurer: '',
                    respCivilPolicyNumber: '',
                    updatedAt: '',
                    vehicleConfig: { description: '', id: '', key: '', totalAxis: 0, totalWheels: 0 },
                    year: '',
                  },
                  ...data,
                ].map((v) => ({ value: v.id, label: v.name }))}
              />
            </Form.Item>
          </Form>
        ) : (
          <Spin size="large" />
        )}
      </Modal>
      <EditOutlined onClick={() => setOpen(true)} />
    </>
  );
}
