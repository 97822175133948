import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      if (cognitoUser) setUser(cognitoUser);
      setLoading(false);
    } catch (e) {
      navigate('/login');
      setLoading(false);
    }
  };

  const signIn = (newUser: any) => {
    setUser(newUser);
    navigate('/usuarios-internos');
  };

  const logout = async () => {
    setLoading(true);
    await Auth.signOut();
    setUser(null);
    navigate('/login');
    setLoading(false);
  };

  const value = {
    user,
    loading,
    signIn,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
