import { Button, Col, Form, Input, Popover, Row, Typography, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
const { Title, Text } = Typography;

interface LoginFormProps {
  onFinish: (user: any) => void;
  changeStepForgotPw: () => void;
}
type LoginFormSteps = 'login' | 'completePw';
const LoginForm = ({ onFinish, changeStepForgotPw }: LoginFormProps) => {
  const [step, setStep] = useState<LoginFormSteps>('login');
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingFirstStep, setLoadingFirstStep] = useState(false);
  const [loadingSecondStep, setLoadingSecondStep] = useState(false);
  const [tmpUser, setTmpUser] = useState(null);
  const [form] = Form.useForm();
  const [formSecondStep] = Form.useForm();

  const handleChangeStep = () => {
    form.resetFields();
    setLoadingFirstStep(false);
    changeStepForgotPw();
  };

  const handleOnFinish = async ({ email, password }: { email: string; password: string }) => {
    setLoadingFirstStep(true);
    try {
      const user = await Auth.signIn(email, password);
      setTmpUser(user);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setStep('completePw');
        setLoadingFirstStep(false);
        form.resetFields();
      } else {
        onFinish(user);
      }
    } catch (e: any) {
      console.log(e);
      if (e.message === 'User does not exist.') {
        messageApi.error('No existe una cuenta con el correo ingresado', 2);
      } else if (e.message === 'Incorrect username or password.') {
        messageApi.error('Correo o contraseña incorrecta');
      } else {
        messageApi.error('Error desconocido');
      }
      setLoadingFirstStep(false);
    }
  };

  const handleOnFinishSecond = async ({ password }: { password: string }) => {
    setLoadingSecondStep(true);
    try {
      const user = await Auth.completeNewPassword(tmpUser, password);
      // User is already logged in if no errors
      if (user) {
        onFinish(user);
        formSecondStep.resetFields();
        setLoadingSecondStep(false);
      }
    } catch (e: any) {
      messageApi.error('Error desconocido');
    }
  };

  return (
    <>
      {contextHolder}
      {step === 'login' ? (
        <Row>
          <Col span={24}>
            <Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
              Inicia sesión
            </Title>
            <Text style={{ fontSize: '12px' }} type="secondary">
              Ingresa tus datos
            </Text>
          </Col>
          <Col span={24} style={{ marginTop: '15px' }}>
            <Form form={form} name="login" autoComplete="off" onFinish={handleOnFinish} layout="vertical">
              <Col span={24} style={{ marginTop: '15px' }}>
                <Form.Item
                  label="Correo electrónico"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Ingresa tu correo electrónico!',
                      type: 'email',
                    },
                  ]}
                >
                  <Input placeholder="ejemplo@correo.com" />
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: '15px' }}>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[{ required: true, message: 'Ingresa tu contraseña!' }]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: '10px', textAlign: 'right' }}>
                <Button type="link" style={{ paddingRight: 0 }} onClick={handleChangeStep}>
                  Olvistaste tu contraseña?
                </Button>
              </Col>
              <Col span={24} style={{ marginTop: '10px', textAlign: 'right' }}>
                <Button block type="primary" htmlType="submit" loading={loadingFirstStep}>
                  Iniciar sesión
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
              Nueva contraseña
            </Title>
            <Text style={{ fontSize: '12px' }} type="secondary">
              Ingresa tu nueva contraseña
            </Text>
          </Col>
          <Col span={24} style={{ marginTop: '15px' }}>
            <Form
              form={formSecondStep}
              name="completePw"
              autoComplete="off"
              onFinish={handleOnFinishSecond}
              layout="vertical"
            >
              <Col span={24} style={{ marginTop: '15px' }}>
                <Popover
                  content={() => (
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col span={24}>* Contener al menos 8 caracteres</Col>
                        </Row>
                        <Row>
                          <Col span={24}>* Contener al menos una mayúscula</Col>
                        </Row>
                        <Row>
                          <Col span={24}>* Contener al menos una minúscula</Col>
                        </Row>
                        <Row>
                          <Col span={24}>* Contener al menos un número</Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  title="Tu contraseña debe:"
                  placement="bottomLeft"
                  trigger="focus"
                >
                  <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa tu contraseña!',
                      },
                      () => ({
                        validator(_, value) {
                          const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
                          if (!value || reg.test(value)) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('La contraseña no cumple con los requisitos'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Popover>
              </Col>
              <Col span={24} style={{ marginTop: '15px' }}>
                <Form.Item
                  label="Confirmar contraseña"
                  name="confirm"
                  rules={[
                    { required: true, message: 'Confirma tu contraseña!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Las contraseñas que ingresaste no son iguales!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: '10px', textAlign: 'right' }}>
                <Button block type="primary" htmlType="submit" loading={loadingSecondStep}>
                  Enviar
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};

export default LoginForm;
