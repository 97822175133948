import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Input, Modal, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { editZipCode, getPrices, getZones } from '../../../api';
import { components, paths } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';
import useResetFormOnCloseModal from '../../../shared/hooks/useResetFormModalOnClose';

const PAGE_SIZE = 100;
export const EditZipCode = ({ record }: DataTableActionProps<components['schemas']['ZipcodeCondensed']>) => {
  const [form] = Form.useForm<components['schemas']['CreateZipcodeRequest']>();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const [paramsZone] = useState<paths['/zone/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { data, remove } = useQuery({
    queryKey: ['getZones', paramsZone],
    queryFn: () => getZones(paramsZone),
    enabled: record.id !== undefined && open,
  });
  const [params] = useState<paths['/price/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { data: dataPrices, remove: removePrice } = useQuery({
    queryKey: ['getPrices', params],
    queryFn: () => getPrices(params),
    enabled: open,
  });
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreateZipcodeRequest']) => editZipCode(record.id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getZipCodes'] });
      message.success('Código postal editado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        zipCode: record.zipCode,
        district: record.district,
        city: record.city,
        municipio: record.municipio,
        state: record.state,
        stateKey: record.stateKey,
        priceId: record.priceId,
        zoneId: record.zoneId,
      });
    }
  }, [data]);

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate(values);
    } catch (e) {}
  };

  const handleCancel = () => {
    setOpen(false);
    remove();
    removePrice();
  };

  return (
    <>
      <Modal
        open={open}
        title="Editar código postal"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
            Guardar
          </Button>,
        ]}
      >
        {data && dataPrices ? (
          <Form form={form} layout="vertical" name="edit-zipcode-form" scrollToFirstError>
            <Form.Item
              name="zipCode"
              label="Código postal"
              rules={[{ required: true, message: 'Ingresa el código postal' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="district" label="Colonia" rules={[{ required: true, message: 'Ingresa la colonia' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="city" label="Ciudad">
              <Input />
            </Form.Item>
            <Form.Item name="municipio" label="Municipio">
              <Input />
            </Form.Item>
            <Form.Item name="state" label="Estado">
              <Input />
            </Form.Item>
            <Form.Item name="stateKey" label="Estado Abreviado" normalize={(value) => (value || '').toUpperCase()}>
              <Input placeholder="JAL" />
            </Form.Item>
            <Form.Item name="priceId" label="Precio" rules={[{ required: true, message: 'Selecciona un precio' }]}>
              <Select
                style={{ width: '100%' }}
                options={dataPrices.results.map((price) => ({ value: price.id, label: price.name }))}
              />
            </Form.Item>
            <Form.Item name="zoneId" label="Zona" rules={[{ required: true, message: 'Selecciona una zona' }]}>
              <Select
                style={{ width: '100%' }}
                options={data.results.map((zone) => ({ value: zone.id, label: zone.name }))}
              />
            </Form.Item>
          </Form>
        ) : (
          <Spin size="large" />
        )}
      </Modal>
      <EditOutlined onClick={() => setOpen(true)} />
    </>
  );
};
