import { Auth } from 'aws-amplify';
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  const idToken = session.getIdToken().getJwtToken();
  config.headers.Authorization = idToken;
  return config;
});

/**
 * Deletes all object properties that are undefined, this way only those query params that have a value will be sent to the API
 * @param a QueryParams object
 * @returns object with query params purged
 */
export const purgeQueryParams = (obj: any): any => {
  const newObject = JSON.parse(JSON.stringify(obj));
  Object.keys(newObject).forEach((key) => {
    if (newObject[key] === undefined) {
      delete newObject[key];
    }
  });
  return newObject;
};

export default instance;
