import { ArrowRightOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { DataTableFilterCallback, DataTableOrderCallback } from './DataTable';
import DataTableFilterByCond, { HandleFilteredCallback } from './DataTableFilterByCond';
import DataTableFilterByVal from './DataTableFilterByVal';

export type AvailableFilterTypes = 'action' | 'text' | 'number' | 'date' | 'enum' | 'boolean';
export type OrderType = 'ASC' | 'DESC';
export interface OnFilterApplyCallback {
  filterByVal: string[];
  filterByCond: HandleFilteredCallback | null;
}
export interface OnOrderApplyCallback {
  order: OrderType | null;
}
interface DataTableFilterProps {
  columnResults: string[];
  columnDataType: AvailableFilterTypes;
  onFilterApply: (data: OnFilterApplyCallback) => void;
  onOrderApply: (data: OnOrderApplyCallback) => void;
  columnActiveFilters: DataTableFilterCallback | undefined;
  columnActiveOrders: DataTableOrderCallback | undefined;
}
export default function DataTableFilter({
  columnResults,
  columnDataType,
  onFilterApply,
  onOrderApply,
  columnActiveFilters,
  columnActiveOrders,
}: DataTableFilterProps) {
  const [collapseFilterByCondOpen, setCollapseFilterByCondOpen] = useState(false);
  const [collapseFilterByValOpen, setCollapseFilterByValOpen] = useState(true);
  const [filterByValueItems, setFilterByValueItems] = useState<string[]>([]);
  const [filterByCondData, setFilterByCondData] = useState<HandleFilteredCallback | null>(null);
  const [currentOrder, setCurrentOrder] = useState<OrderType | null>(null);
  const [prefilledFilter, setPrefilledFilter] = useState<OnFilterApplyCallback | null>(null);
  useEffect(() => {
    if (columnActiveFilters) {
      if (columnActiveFilters.filterByCond) {
        setCollapseFilterByCondOpen(true);
        setCollapseFilterByValOpen(false);
        setPrefilledFilter({
          filterByCond: columnActiveFilters.filterByCond,
          filterByVal: [],
        });
      } else if (columnActiveFilters.filterByVal) {
        setCollapseFilterByValOpen(true);
        setCollapseFilterByCondOpen(false);
        setPrefilledFilter({
          filterByCond: null,
          filterByVal: columnActiveFilters.filterByVal,
        });
      } else {
        // Default behavior
        setCollapseFilterByCondOpen(false);
        setCollapseFilterByValOpen(true);
      }
    } else if (columnDataType === 'date') {
      setCollapseFilterByCondOpen(true);
      setCollapseFilterByValOpen(false);
    }

    if (columnActiveOrders) {
      if (columnActiveOrders.order === 'ASC') {
        setCurrentOrder('ASC');
      } else {
        setCurrentOrder('DESC');
      }
    }
    return () => {
      setCollapseFilterByCondOpen(false);
      setCollapseFilterByValOpen(true);
      setFilterByValueItems([]);
    };
  }, []);

  const handleClickFilterByCondition = () => {
    setCollapseFilterByCondOpen(!collapseFilterByCondOpen);
    if (collapseFilterByValOpen) setCollapseFilterByValOpen(false);
  };

  const handleClickFilterByValue = () => {
    setCollapseFilterByValOpen(!collapseFilterByValOpen);
    if (collapseFilterByCondOpen) setCollapseFilterByCondOpen(false);
  };

  const handleClickOrderAsc = () => {
    // It already had ASC order, so this is to remve the order at all
    if (currentOrder === 'ASC') {
      onOrderApply({ order: null });
      setCurrentOrder(null);
    } else {
      setCurrentOrder('ASC');
      onOrderApply({ order: 'ASC' });
    }
  };

  const handleClickOrderDesc = () => {
    // It already had DESC order, so this is to remve the order at all
    if (currentOrder === 'DESC') {
      onOrderApply({ order: null });
      setCurrentOrder(null);
    } else {
      setCurrentOrder('DESC');
      onOrderApply({ order: 'DESC' });
    }
  };

  const handleApplyFilter = () => {
    onFilterApply({
      filterByCond: filterByCondData,
      filterByVal: filterByValueItems,
    });
  };

  const handleClearFilter = () => {
    onFilterApply({
      filterByCond: null,
      filterByVal: [],
    });
    onOrderApply({ order: null });
  };

  return (
    <div
      className="custom-data-table"
      style={{ padding: '0', backgroundColor: '#fff', width: 250 }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div className="data-table-filter-list">
        <ul>
          <li onClick={handleClickOrderAsc} style={{ fontWeight: currentOrder === 'ASC' ? 800 : 400 }}>
            {columnDataType === 'text' ? (
              <Space>
                Ordenar A<ArrowRightOutlined />Z
              </Space>
            ) : (
              'Ordenar menor a mayor'
            )}
          </li>
          <li onClick={handleClickOrderDesc} style={{ fontWeight: currentOrder === 'DESC' ? 800 : 400 }}>
            {columnDataType === 'text' ? (
              <Space>
                Ordenar Z<ArrowRightOutlined />A
              </Space>
            ) : (
              'Ordenar mayor a menor'
            )}
          </li>
        </ul>
      </div>
      <Divider />
      <div className="data-table-filter-list">
        <ul>
          {columnDataType !== 'boolean' && columnDataType !== 'enum' && (
            <>
              <li onClick={handleClickFilterByCondition}>
                {collapseFilterByCondOpen ? <DownOutlined /> : <RightOutlined />}
                <span style={{ paddingLeft: 3 }}>Filtrar por condición</span>
              </li>
              {collapseFilterByCondOpen && (
                <DataTableFilterByCond
                  columnType={columnDataType}
                  handleFiltered={(filter) => setFilterByCondData(filter)}
                  prefilledCond={prefilledFilter ? prefilledFilter.filterByCond : null}
                />
              )}
            </>
          )}

          {columnDataType === 'text' ||
          columnDataType === 'number' ||
          columnDataType === 'enum' ||
          columnDataType === 'boolean' ? (
            <>
              <li onClick={handleClickFilterByValue}>
                {collapseFilterByValOpen ? <DownOutlined /> : <RightOutlined />}

                <span style={{ paddingLeft: 3 }}>Filtrar por valor</span>
              </li>
              {collapseFilterByValOpen && (
                <DataTableFilterByVal
                  columnResults={columnResults}
                  handleFilteredItems={(items) => setFilterByValueItems(items)}
                  prefilledItems={prefilledFilter ? prefilledFilter.filterByVal : null}
                />
              )}
            </>
          ) : null}
        </ul>
      </div>
      <Divider />
      <Row style={{ padding: 10, marginTop: 10 }} justify={'end'}>
        <Col>
          <Space>
            <Button type="default" onClick={handleClearFilter}>
              Limpiar
            </Button>
            <Button type="primary" onClick={handleApplyFilter}>
              Aplicar
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
}
