import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Input, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { getPrepaids } from '../../api';
import { components, paths } from '../../api/schema';
import DataTable, {
  ColumnsType,
  DataTableFilterCallback,
  DataTableOrderCallback,
  serializeFilters,
  serializeOrders,
} from '../../shared/dataTable/DataTable';
import useDebounce from '../../shared/hooks/useDebounce';
import PanelSkeleton from '../../shared/skeletons/PanelSkeleton';
import CreatePrepaid from './CreatePrepaid';
import PrepaidDetail from './PrepaidDetail';
import { DeletePrepaid } from './prepaidPanelActions/DeleteAction';

const prepaidShipmentsTableColumns: ColumnsType<components['schemas']['PrepaidCondensed']> = [
  {
    title: 'Cliente',
    dataIndex: 'clientName',
    key: 'clientName',
    columnType: 'text',
  },
  {
    title: 'Precio individual',
    dataIndex: 'individualPrice',
    key: 'individualPrice',
    columnType: 'number',
    hideFilter: true,
  },
  {
    title: 'Precio total',
    dataIndex: 'pricePaid',
    key: 'pricePaid',
    columnType: 'number',
    hideFilter: true,
  },
  {
    title: 'Envíos pagados',
    dataIndex: 'shipmentsAmount',
    key: 'shipmentsAmount',
    columnType: 'number',
    hideFilter: true,
  },
  {
    title: 'Envíos usados',
    dataIndex: 'shipmentsUsed',
    key: 'shipmentsUsed',
    columnType: 'number',
    hideFilter: true,
  },
];
const PAGE_SIZE = 50;

export default function PrepaidPanel() {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [selectedPrepaidId, setSelectedPrepaidId] = useState<string | undefined>();
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/prepaid-shipment/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    status: 'ACTIVE',
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { isLoading, isError, data, isFetching } = useQuery({
    queryKey: ['getPrepaids', params],
    queryFn: () => getPrepaids(params),
  });

  useEffect(() => {
    setParams((state) => ({ ...state, search: debouncedSearch }));
  }, [debouncedSearch]);

  const handleFiltering = (filter: DataTableFilterCallback) => {
    setParams((state) => ({ ...state, filter: serializeFilters(params.filter, filter) }));
  };

  const handleOrdering = (order: DataTableOrderCallback) => {
    setParams((state) => ({ ...state, order: serializeOrders(params.order, order) }));
  };

  const handlePageChange = (page: number) => {
    setParams((state) => ({ ...state, page }));
  };

  return (
    <Row>
      <Col span={24}>
        <Space size={'middle'}>
          <Button type="primary" onClick={() => setCreateModalOpen(true)}>
            Crear Guías Prepagadas
          </Button>
          <Input
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
          <Select
            style={{ width: 200 }}
            placeholder="Filtrar por status"
            allowClear
            onChange={(value) => setParams((state) => ({ ...state, status: value }))}
            value={params.status}
          >
            <Select.Option value="ALL">Todos</Select.Option>
            <Select.Option value="ACTIVE">Activas</Select.Option>
            <Select.Option value="COMPLETED">Completadas</Select.Option>
            <Select.Option value="CANCELLED">Canceladas</Select.Option>
          </Select>
        </Space>
      </Col>
      {isLoading || isError || isFetching ? (
        <PanelSkeleton />
      ) : (
        <>
          <Col span={24} style={{ marginTop: '10px' }}>
            <DataTable<components['schemas']['PrepaidCondensed']>
              handleFilters={handleFiltering}
              handleOrder={handleOrdering}
              handlePageChange={handlePageChange}
              columns={prepaidShipmentsTableColumns}
              results={data.results}
              activeFilters={params.filter}
              activeOrders={params.order}
              paginationData={{
                pageSize: PAGE_SIZE,
                count: data.count,
                currentPage: data.currentPage,
              }}
              actions={[DeletePrepaid]}
              onRowClick={(record) => {
                setSelectedPrepaidId(record.id);
                setDetailModalOpen(true);
              }}
            />
          </Col>

          <PrepaidDetail
            prepaidId={selectedPrepaidId}
            open={detailModalOpen}
            onCancel={() => setDetailModalOpen(false)}
          />

          <CreatePrepaid
            open={createModalOpen}
            onCancel={() => setCreateModalOpen(false)}
            onCreate={() => setCreateModalOpen(false)}
          />
        </>
      )}
    </Row>
  );
}
