import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Divider, Form, Input, InputNumber, Modal, Select, Spin } from 'antd';
import { createVehicle, listVehicleConfig } from '../../api';
import { components } from '../../api/schema';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';

interface CreateVehicleProps {
  open: boolean;
  onCreate?: () => void;
  onCancel: () => void;
}

export default function CreateVehicle({ open, onCreate, onCancel }: CreateVehicleProps) {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreateVehicleRequest']) => createVehicle(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getVehicles'] });
      message.success('Vehículo creado correctamente');
      onCreate?.();
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });
  const { data: vehicleConfig } = useQuery({
    queryKey: ['listVehicleConfig'],
    queryFn: () => listVehicleConfig(),
  });
  const [form] = Form.useForm<components['schemas']['CreateVehicleRequest']>();

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate(values);
    } catch (e) {}
  };

  return (
    <Modal
      open={open}
      title="Crear vehículo"
      style={{ top: 20 }}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
          Guardar
        </Button>,
      ]}
    >
      {!vehicleConfig ? (
        <Spin size="large" />
      ) : (
        <Form form={form} layout="vertical" name="create-vehicle-form" scrollToFirstError>
          <Form.Item
            name="name"
            label="Nombre vehículo"
            rules={[{ required: true, message: 'Ingresa el nombre del vehículo' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="vehicleConfigId"
            label="Configuración vehícular"
            rules={[{ required: true, message: 'Selecciona una configuración vehícular' }]}
          >
            <Select
              style={{ width: '100%' }}
              options={vehicleConfig.map((conf) => ({ value: conf.id, label: conf.description }))}
            />
          </Form.Item>
          <Form.Item
            name="plate"
            label="Placa"
            rules={[
              {
                required: true,
                message: 'Ingresa la placa, de 5 a 7 caracteres sin espacios ni caracteres especiales',
                pattern: /^[a-zA-Z0-9]{5,7}$/,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="year"
            label="Año"
            rules={[{ required: true, message: 'Ingresa el año del vehículo', pattern: /^[0-9]{4}$/ }]}
          >
            <Input placeholder="Ej. 2015" />
          </Form.Item>
          <Form.Item
            name="pesoBruto"
            label="Peso bruto (toneladas)"
            rules={[{ required: true, message: 'Ingresa el peso bruto en toneladas que puede cargar el vehiculo' }]}
          >
            <InputNumber style={{ width: '100%' }} placeholder="Peso bruto en toneladas que puede cargar el vehiculo" />
          </Form.Item>
          <Divider>Responsabilidad Civíl</Divider>
          <Form.Item
            name="respCivilInsurer"
            label="Nombre de la aseguradora"
            rules={[{ required: true, message: 'Ingresa el nombre de la aseguradora' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="respCivilPolicyNumber"
            label="Número de póliza"
            rules={[{ required: true, message: 'Ingresa el número de póliza' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
