import axios, { purgeQueryParams } from './axios';
import { components, paths } from './schema';

export const createPayoff = async (
  body: components['schemas']['CreatePayOffRequest'],
): Promise<components['schemas']['CreatePayOffResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreatePayOffResponse']>(`/credit/pay-off`, body);
  return data.data.data;
};

export const getCreditShipments = async (
  params: paths['/credit/list']['get']['parameters']['query'],
): Promise<components['schemas']['CreditListResponse']['data']> => {
  const data = await axios.get<components['schemas']['CreditListResponse']>(
    `/credit/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};
