import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Input, InputNumber, Modal } from 'antd';
import { createPrice } from '../../api';
import { components } from '../../api/schema';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';

interface CreatePriceProps {
  open: boolean;
  onCreate?: () => void;
  onCancel: () => void;
}
export default function CreatePrice({ open, onCreate, onCancel }: CreatePriceProps) {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreatePriceRequest']) => createPrice(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getPrices'] });
      message.success('Precio creado correctamente');
      onCreate?.();
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });
  const [form] = Form.useForm<components['schemas']['CreatePriceRequest']>();

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate(values);
    } catch (e) {}
  };

  return (
    <Modal
      open={open}
      title="Crear precio"
      style={{ top: 20 }}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
          Guardar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="create-price-form" scrollToFirstError>
        <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre del precio' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="price" label="Precio" rules={[{ required: true, message: 'Ingresa el precio' }]}>
          <InputNumber
            formatter={(value) => `$ ${value!}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            // min={0}
            precision={2}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
