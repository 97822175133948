import { useQuery } from '@tanstack/react-query';
import { Select, Skeleton } from 'antd';
import { listRegimenFiscal } from '../../api';

interface SelectSATRegimenFiscalProps {
  value?: string;
  onChange?: (value: string) => void;
  onChangeByLabel?: (label: string) => void;
  // Prop added to control if the element is disabled or not in UI
  disabled?: boolean;
}
export default function SearchRegimenFiscal({
  onChange,
  onChangeByLabel,
  value,
  disabled,
}: SelectSATRegimenFiscalProps) {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['listRegimenFiscal'],
    queryFn: () => listRegimenFiscal(),
    staleTime: 10000, // This list wont be updated frequently so we can cache it for a while
  });

  const localOnChange = (
    value: string,
    option: { value: string; label: string } | Array<{ value: string; label: string }>,
  ) => {
    onChange?.(value);
    if (!Array.isArray(option)) {
      onChangeByLabel?.(option.label);
    }
  };

  if (isLoading) return <Skeleton.Input active size="small" />;
  if (isError) return <Select status="error" style={{ width: '100%' }} />;

  return (
    <Select
      disabled={disabled}
      style={{ width: '100%' }}
      showSearch
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      onChange={localOnChange}
      value={value}
      options={data.map((item) => ({
        label: `${item.key} - ${item.name}`,
        value: item.id,
      }))}
    />
  );
}
