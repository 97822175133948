import { CloseCircleTwoTone } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Popconfirm } from 'antd';
import { useState } from 'react';
import { deleteService } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';

export const DeleteAction = ({ record }: DataTableActionProps<components['schemas']['PickupCondensed']>) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: (id: string) => deleteService(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getShipmentPickupList'] });
      message.success('Servicio eliminado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  return (
    <Popconfirm
      title="Eliminar envío"
      description={`Deseas cancelar el servicio? Se eliminará el envío y no se podrá recuperar. ¿Estás seguro?`}
      open={open}
      onConfirm={() => mutation.mutate(record.shipmentId)}
      okButtonProps={{ loading: mutation.isLoading }}
      onCancel={() => setOpen(false)}
      cancelText="Cancelar"
      okText="Eliminar"
    >
      <CloseCircleTwoTone twoToneColor={'#cf1322'} onClick={() => setOpen(true)} />
    </Popconfirm>
  );
};
