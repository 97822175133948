import { useQuery } from '@tanstack/react-query';
import { Button, Col, Divider, Modal, Row, Spin } from 'antd';
import ReadonlyInput from '../../shared/form/ReadonlyInput';
import { getClient } from '../../api';
import ReadonlyCheckbox from '../../shared/form/ReadonlyCheckbox';
import formatAddress from '../../shared/client/formatAddress';

interface ClientDetailProps {
  clientId?: string | undefined;
  open: boolean;
  onCancel: () => void;
}

const formatAddressType = {
  PICKUP: 'Recolección',
  DELIVERY: 'Entrega',
  DELIVERY_AND_PICKUP: 'Entrega y recolección',
  FISCAL: 'Fiscal',
  PICKUP_AND_FISCAL: 'Recolección y fiscal',
};

export default function ClientDetail({ clientId, open, onCancel }: ClientDetailProps) {
  const { data, remove } = useQuery({
    queryKey: ['getClient', clientId],
    queryFn: () => getClient(clientId),
    enabled: clientId !== undefined && open,
  });

  const handleCancel = () => {
    remove();
    onCancel();
  };

  return (
    <Modal
      open={open}
      title="Vista de cliente"
      style={{ top: 20 }}
      destroyOnClose
      width={800}
      maskClosable
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
      ]}
    >
      {!data ? (
        <Spin size="large" />
      ) : (
        <>
          <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
            <Col span={12}>
              <ReadonlyInput value={data.name} label="Nombre" />
            </Col>
            <Col span={6}>
              <ReadonlyInput value={data.comercialName ?? ''} label="Nombre comercial" />
            </Col>
            <Col span={6}>
              <ReadonlyInput value={data.phoneNumber} label="Teléfono" />
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
            <Col span={12}>
              <ReadonlyInput value={data.emailAddress ?? ''} label="Correo electrónico" />
            </Col>
            <Col span={10}>
              <ReadonlyInput value={data.officeHours} label="Horario de oficina" />
            </Col>
            <Col span={2}>
              <ReadonlyCheckbox checked={data.credit} label="Crédito" checkboxText="Sí" />
            </Col>
          </Row>
          <Divider>Fiscal</Divider>
          <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
            <Col span={8}>
              <ReadonlyInput value={data.rfc} label="RFC" />
            </Col>
            <Col span={8}>
              <ReadonlyInput value={data.cfdiUse.name} label="Uso de CFDI" />
            </Col>
            <Col span={8}>
              <ReadonlyInput value={data.regimenFiscal.name} label="Régimen Fiscal" />
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
            <Col span={8}>
              <ReadonlyInput value={data.paymentMethod.name} label="Método de pago" />
            </Col>
            <Col span={8}>
              <ReadonlyInput value={data.tipoPersona === 'FISICA' ? 'Física' : 'Moral'} label="Tipo de persona" />
            </Col>
            <Col span={8}>
              <ReadonlyInput value={data.formaPago.name} label="Forma de Pago" />
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
            <Col span={4}>
              <ReadonlyCheckbox checked={data.ivaRetention} label="Retener IVA" checkboxText="Sí" />
            </Col>
            <Col span={4}>
              <ReadonlyCheckbox checked={data.sendInvoice} label="Envíar factura" checkboxText="Sí" />
            </Col>
          </Row>
          <Divider>Direcciones</Divider>
          {data.addresses.length ? (
            <>
              {data.addresses.map((address, k) => (
                <Row key={k} gutter={[8, 8]} style={{ marginBottom: 5 }}>
                  <Col span={4}>
                    <ReadonlyInput value={formatAddressType[address.addressType]} label="Tipo dirección" />
                  </Col>
                  <Col span={6}>
                    <ReadonlyInput value={address.name} label="Nombre dirección" />
                  </Col>
                  <Col span={14}>
                    <ReadonlyInput value={formatAddress(address)} label="Dirección" />
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            <div>No se encontraron direcciones</div>
          )}
          <Divider>Contactos</Divider>
          {data.contacts.length ? (
            <>
              {data.contacts.map((contact, k) => (
                <Row key={k} gutter={[8, 8]} style={{ marginBottom: 5 }}>
                  <Col span={12}>
                    <ReadonlyInput value={contact.name} label="Nombre" />
                  </Col>
                  <Col span={12}>
                    <ReadonlyInput value={contact.phoneNumber} label="Teléfono" />
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            <div>No se encontraron contactos</div>
          )}
        </>
      )}
    </Modal>
  );
}
