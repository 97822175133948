import { useQuery } from '@tanstack/react-query';
import { getAreas } from '../../api';
import { Select, Skeleton } from 'antd';

interface SelectAreaProps {
  value?: string;
  onChange?: (value: string) => void;
}
export default function SelectArea({ value, onChange }: SelectAreaProps) {
  const { isLoading, isError, data } = useQuery({
    queryKey: ['getAreas'],
    queryFn: () => getAreas(),
    staleTime: 10000, // We won't be changing roles that often so we can make this stale until a while after to prevent extra queries
  });

  if (isLoading) return <Skeleton.Input active size="small" />;
  if (isError) return <Select status="error" style={{ width: '100%' }} />;

  return (
    <Select
      style={{ width: '100%' }}
      options={data.map((area) => ({ value: area.id, label: area.area }))}
      onChange={onChange}
      value={value}
    />
  );
}
