import axios, { purgeQueryParams } from './axios';
import { components, paths } from './schema';

export const getUsersClient = async (
  params: paths['/user-client/list']['get']['parameters']['query'],
): Promise<components['schemas']['UserClientListResponse']['data']> => {
  const data = await axios.get<components['schemas']['UserClientListResponse']>(
    `/user-client/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const createUserClient = async (
  body: components['schemas']['CreateUserClientRequest'],
): Promise<components['schemas']['CreateUserClientResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateUserClientResponse']>(`/user-client`, body);
  return data.data.data;
};

export const deleteUserClient = async (
  userClientId: string,
): Promise<components['schemas']['DeleteUserClientResponse']['data']> => {
  const data = await axios.delete<components['schemas']['DeleteUserClientResponse']>(`/user-client/${userClientId}`);
  return data.data.data;
};

export const getUserClient = async (
  userClientId?: string,
): Promise<components['schemas']['GetUserClientResponse']['data']> => {
  if (!userClientId) return await Promise.reject(new Error());
  const data = await axios.get<components['schemas']['GetUserClientResponse']>(`/user-client/${userClientId}`);
  return data.data.data;
};

export const editUserClient = async (
  userClientId: string,
  body: components['schemas']['EditUserClientRequest'],
): Promise<components['schemas']['EditUserClientResponse']['data']> => {
  const data = await axios.put<components['schemas']['EditUserClientResponse']>(`/user-client/${userClientId}`, body);
  return data.data.data;
};

export const resetPasswordClient = async (
  userClientId: string,
): Promise<components['schemas']['ClientResetPasswordResponse']['data']> => {
  const data = await axios.post<components['schemas']['ClientResetPasswordResponse']>(
    `/user-client/${userClientId}/reset-password`,
  );
  return data.data.data;
};
