import axios, { purgeQueryParams } from './axios';
import { components, paths } from './schema';

export const createPrepaid = async (
  body: components['schemas']['CreatePrepaidShipmentRequest'],
): Promise<components['schemas']['CreatePrepaidShipmentResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreatePrepaidShipmentResponse']>(`/prepaid-shipment`, body);
  return data.data.data;
};

export const getPrepaid = async (
  prepaidId?: string,
): Promise<components['schemas']['GetPrepaidShipmentResponse']['data']> => {
  if (!prepaidId) return await Promise.reject(new Error());
  const data = await axios.get<components['schemas']['GetPrepaidShipmentResponse']>(`/prepaid-shipment/${prepaidId}`);
  return data.data.data;
};

export const deletePrepaid = async (
  prepaidId: string,
): Promise<components['schemas']['DeletePrepaidResponse']['data']> => {
  const data = await axios.delete<components['schemas']['DeletePrepaidResponse']>(`/prepaid-shipment/${prepaidId}`);
  return data.data.data;
};

export const getPrepaids = async (
  params: paths['/prepaid-shipment/list']['get']['parameters']['query'],
): Promise<components['schemas']['PrepaidListResponse']['data']> => {
  const data = await axios.get<components['schemas']['PrepaidListResponse']>(
    `/prepaid-shipment/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};
