import { useQuery } from '@tanstack/react-query';
import { Col, Input, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { getShipmentInTransitList } from '../../api';
import { components, paths } from '../../api/schema';
import { AvailableEntity } from '../../shared/commentsLogger/CommentsLogger';
import DataTable, {
  ColumnsType,
  DataTableFilterCallback,
  DataTableOrderCallback,
  serializeFilters,
  serializeOrders,
} from '../../shared/dataTable/DataTable';
import useDebounce from '../../shared/hooks/useDebounce';
import PanelSkeleton from '../../shared/skeletons/PanelSkeleton';

import { SearchOutlined } from '@ant-design/icons';
import { LabelDrawer } from '../pickup/pickupPanelActions/LabelDrawer';
import { ReturnStatus } from './transitPanelActions/ReturnAction';
import useConditionalRefresh from '../../shared/hooks/useConditionalRefresh';
import { ViewCfdi } from '../../shared/shipment/ViewCfdi';

const inTransitTableColumns: ColumnsType<components['schemas']['InTransitCondensed']> = [
  {
    title: 'En tránsito desde',
    dataIndex: 'inTransitAt',
    key: 'inTransitAt',
    columnType: 'date',
  },
  {
    title: 'Número de rastreo',
    dataIndex: 'trackingNumber',
    key: 'trackingNumber',
    columnType: 'text',
  },
  {
    title: 'Zona de entrega',
    dataIndex: 'zone',
    key: 'zone',
    columnType: 'text',
  },
  {
    title: 'Cliente',
    dataIndex: 'clientName',
    key: 'clientName',
    columnType: 'text',
  },
  {
    title: 'Dirección de  entrega',
    dataIndex: 'address',
    key: 'address',
    columnType: 'text',
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    key: 'price',
    columnType: 'number',
  },
  {
    title: 'Peso total',
    dataIndex: 'totalWeight',
    key: 'totalWeight',
    columnType: 'text',
    hideFilter: true,
  },
  {
    title: 'Total de paquetes',
    dataIndex: 'totalPackages',
    key: 'totalPackages',
    columnType: 'text',
    hideFilter: true,
  },
  {
    title: 'Conductor',
    dataIndex: 'driver',
    key: 'driver',
    columnType: 'text',
    hideFilter: true,
  },
  {
    title: 'Vehículo',
    dataIndex: 'vehicle',
    key: 'vehicle',
    columnType: 'text',
  },
];

const PAGE_SIZE = 50;

export default function InTransitPanel() {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/shipment/in-transit/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { isLoading, isError, data, isFetching, refetch } = useQuery({
    queryKey: ['getShipmentInTransitList', params],
    queryFn: () => getShipmentInTransitList(params),
  });

  useConditionalRefresh(60000, refetch);

  useEffect(() => {
    setParams((state) => ({ ...state, search: debouncedSearch }));
  }, [debouncedSearch]);

  const handleFiltering = (filter: DataTableFilterCallback) => {
    setParams((state) => ({ ...state, filter: serializeFilters(params?.filter, filter) }));
  };

  const handleOrdering = (order: DataTableOrderCallback) => {
    setParams((state) => ({ ...state, order: serializeOrders(params?.order, order) }));
  };

  const handlePageChange = (page: number) => {
    setParams((state) => ({ ...state, page }));
  };

  return (
    <Row>
      <Col span={24}>
        <Space size={'middle'}>
          <Input
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Space>
      </Col>
      {isLoading || isError || isFetching ? (
        <PanelSkeleton />
      ) : (
        <>
          <Col span={24} style={{ marginTop: '10px' }}>
            <DataTable<components['schemas']['InTransitCondensed']>
              handleFilters={handleFiltering}
              handleOrder={handleOrdering}
              handlePageChange={handlePageChange}
              columns={inTransitTableColumns}
              results={data.results}
              activeFilters={params?.filter}
              activeOrders={params?.order}
              paginationData={{
                pageSize: PAGE_SIZE,
                count: data.count,
                currentPage: data.currentPage,
              }}
              commentsLogger={{
                entity: AvailableEntity.SHIPMENT,
                queryKeyToInvalidate: 'getShipmentInTransitList',
                entityKey: 'shipmentId',
              }}
              actions={[LabelDrawer, ReturnStatus, ViewCfdi]}
            />
          </Col>
        </>
      )}
    </Row>
  );
}
