import { Input, Space, Typography } from 'antd';

interface ReadonlyInputProps {
  label: string;
  value: string;
  removeBoldLabel?: boolean;
}
export default function ReadonlyInput({ label, value, removeBoldLabel }: ReadonlyInputProps) {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Text strong={!removeBoldLabel}>{label}</Typography.Text>
      <Input value={value} disabled style={{ background: 'transparent', color: '#000' }} />
    </Space>
  );
}
