import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Input, Modal, Select, Spin } from 'antd';
import { createZipCode, getZones, getPrices } from '../../api';
import { components, paths } from '../../api/schema';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';
import { useState } from 'react';

interface CreateZipCodeProps {
  open: boolean;
  onCreate?: () => void;
  onCancel: () => void;
}
const PAGE_SIZE = 100;
export default function CreateZipCode({ open, onCreate, onCancel }: CreateZipCodeProps) {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const [paramsZone] = useState<paths['/zone/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { data, remove } = useQuery({
    queryKey: ['getZones', paramsZone],
    queryFn: () => getZones(paramsZone),
    enabled: open,
  });
  const [params] = useState<paths['/price/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { data: dataPrices, remove: removePrice } = useQuery({
    queryKey: ['getPrices', params],
    queryFn: () => getPrices(params),
    enabled: open,
  });
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['CreateZipcodeRequest']) => createZipCode(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getZipCodes'] });
      message.success('Código postal creado correctamente');
      onCreate?.();
      remove();
      removePrice();
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });
  const [form] = Form.useForm<components['schemas']['CreateZipcodeRequest']>();

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate(values);
    } catch (e) {}
  };

  return (
    <Modal
      open={open}
      title="Crear código postal"
      style={{ top: 20 }}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
          Guardar
        </Button>,
      ]}
    >
      {data && dataPrices ? (
        <Form form={form} layout="vertical" name="create-zipcode-form" scrollToFirstError>
          <Form.Item
            name="zipCode"
            label="Código postal"
            rules={[{ required: true, message: 'Ingresa el código postal' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="district" label="Colonia" rules={[{ required: true, message: 'Ingresa la colonia' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="city" label="Ciudad">
            <Input />
          </Form.Item>
          <Form.Item name="municipio" label="Municipio">
            <Input />
          </Form.Item>
          <Form.Item name="state" label="Estado">
            <Input />
          </Form.Item>
          <Form.Item name="stateKey" label="Estado Abreviado" normalize={(value) => (value || '').toUpperCase()}>
            <Input placeholder="JAL" />
          </Form.Item>
          <Form.Item name="priceId" label="Precio" rules={[{ required: true, message: 'Selecciona un precio' }]}>
            <Select
              style={{ width: '100%' }}
              options={dataPrices.results.map((price) => ({ value: price.id, label: price.name }))}
            />
          </Form.Item>
          <Form.Item name="zoneId" label="Zona" rules={[{ required: true, message: 'Selecciona un rol' }]}>
            <Select
              style={{ width: '100%' }}
              options={data.results.map((zone) => ({ value: zone.id, label: zone.name }))}
            />
          </Form.Item>
        </Form>
      ) : (
        <Spin size="large" />
      )}
    </Modal>
  );
}
