import { EditOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { App, Button, Form, Input, Modal, Spin } from 'antd';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';
import { editUser, getUser } from '../../../api';
import { useEffect, useState } from 'react';
import SelectArea from '../../../shared/areas/SelectArea';
import SelectRole from '../../../shared/roles/SelectRole';
import useResetFormOnCloseModal from '../../../shared/hooks/useResetFormModalOnClose';
import MultiZoneSelect from '../../../shared/zone/MultiZoneSelect';

export const EditUser = ({ record }: DataTableActionProps<components['schemas']['UserCondensed']>) => {
  const [form] = Form.useForm<components['schemas']['EditUserRequest']>();
  const [open, setOpen] = useState(false);
  const [roleLabel, setRoleLabel] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { data, remove } = useQuery({
    queryKey: ['getUser'],
    queryFn: () => getUser(record.id),
    enabled: record.id !== undefined && open,
  });
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['EditUserRequest']) => editUser(record.id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsers'] });
      message.success('Usuario editado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        firstName: data.firstName,
        lastName: data.lastName,
        areaId: data.area.id,
        roleId: data.role.id,
        rfc: data.rfc,
        zones: data.zones.map((zone) => zone.zone.id),
        driversLicense: data.driversLicense,
      });
      setRoleLabel(data.role.role);
    }
  }, [data]);

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate(values);
    } catch (e) {}
  };

  const handleCancel = () => {
    setOpen(false);
    remove();
  };

  return (
    <>
      <Modal
        open={open}
        title="Editar usuario"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
            Guardar
          </Button>,
        ]}
      >
        {!data ? (
          <Spin size="large" />
        ) : (
          <Form form={form} layout="vertical" name="create-user-form" scrollToFirstError>
            <Form.Item name="firstName" label="Nombres" rules={[{ required: true, message: 'Ingresa los nombres' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="lastName" label="Apellidos" rules={[{ required: true, message: 'Ingresa los apellidos' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="areaId" label="Área" rules={[{ required: true, message: 'Selecciona un área' }]}>
              <SelectArea />
            </Form.Item>
            <Form.Item name="roleId" label="Rol" rules={[{ required: true, message: 'Selecciona un rol' }]}>
              <SelectRole onChangeByLabel={(l) => setRoleLabel(l)} />
            </Form.Item>

            {roleLabel === 'Conductor' && (
              <>
                <Form.Item
                  name="rfc"
                  label="RFC"
                  normalize={(value) => (value || '').toUpperCase()}
                  rules={[
                    {
                      required: true,
                      message: 'Ingresa el RFC',
                      pattern:
                        /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/i,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="driversLicense"
                  label="Licencia de conducir"
                  rules={[{ required: true, message: 'Ingresa la licencia de conducir' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="zones"
                  label="Zonas"
                  rules={[{ required: true, message: 'Selecciona una o varias zonas a las que pertenece el usuario' }]}
                >
                  <MultiZoneSelect />
                </Form.Item>
              </>
            )}
          </Form>
        )}
      </Modal>
      <EditOutlined onClick={() => setOpen(true)} />
    </>
  );
};
