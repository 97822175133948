import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';

interface DataTableFilterByValProps {
  columnResults: string[];
  handleFilteredItems: (items: string[]) => void;
  prefilledItems: string[] | null;
}

interface Item extends Object {
  key: string;
  item: string;
  checked: boolean;
}
export default function DataTableFilterByVal({
  columnResults,
  handleFilteredItems,
  prefilledItems,
}: DataTableFilterByValProps) {
  const [items, setItems] = useState<Item[]>([]);
  const [originalItems, setOriginalItems] = useState<Item[]>([]);
  const [tmpInputValue, setTmpInputValue] = useState('');

  useEffect(() => {
    const tmpItems: Item[] = [];
    setOriginalItems(
      columnResults
        .filter((element, index) => {
          return columnResults.indexOf(element) === index;
        })
        .filter((element) => element !== null)
        .map((res, i) => {
          const item = {
            key: `${i}-${res}`,
            item: `${res}`,
            checked: true,
          };
          tmpItems.push(item);
          return item;
        }),
    );

    return () => {
      handleFilteredItems([]);
      setItems([]);
      setOriginalItems([]);
      setTmpInputValue('');
    };
  }, []);

  useEffect(() => {
    handleFilteredItems(items.filter((item) => item.checked).map((item) => item.item));
  }, [items]);

  useEffect(() => {
    if (originalItems?.length) {
      if (prefilledItems?.length) {
        setItems(
          originalItems
            .filter((res) => prefilledItems.includes(res.item))
            .map((res) => ({
              key: res.key,
              item: res.item,
              checked: res.checked,
            })),
        );
      } else {
        setItems(
          originalItems.map((res) => ({
            key: res.key,
            item: res.item,
            checked: res.checked,
          })),
        );
      }
    }
  }, [originalItems, prefilledItems]);

  const handleClearSelection = () => {
    setItems((items) =>
      items.map((item) => ({
        key: item.key,
        item: `${item.item}`,
        checked: false,
      })),
    );
  };

  const handleSelectAll = () => {
    setItems((items) =>
      items.map((item) => ({
        key: item.key,
        item: `${item.item}`,
        checked: true,
      })),
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTmpInputValue(e.target.value);
  };

  const handleClickValue = (key: string) => {
    setItems((items) =>
      items.map((item) => ({
        key: item.key,
        item: `${item.item}`,
        checked: item.key === key ? !item.checked : item.checked,
      })),
    );
  };

  const getHighlightedText = (text: string, highlight: string) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => (
          <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
            {part}
          </span>
        ))}
      </span>
    );
  };

  return (
    <div style={{ padding: '0 25px' }}>
      <Row>
        <Col span={24}>
          <Button type="link" size="small" style={{ fontSize: 10 }} onClick={handleSelectAll}>
            Seleccionar todo
          </Button>
          <Button type="link" size="small" style={{ fontSize: 10 }} onClick={handleClearSelection}>
            Borrar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Input suffix={<SearchOutlined />} onChange={handleInputChange} />
          <div
            style={{
              height: 130,
              overflowY: 'auto',
            }}
          >
            <ul>
              {items?.length &&
                items
                  .filter((res) => {
                    const rgx = new RegExp(`(${tmpInputValue})`, 'gi');
                    return rgx.test(res.item);
                  })
                  .map((item) => (
                    <li key={item.key} onClick={() => handleClickValue(item.key)}>
                      {item.checked && <CheckOutlined />}
                      <span style={{ paddingLeft: item.checked ? 5 : 22 }}>
                        {getHighlightedText(item.item.toString(), tmpInputValue)}
                      </span>
                    </li>
                  ))}
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
}
