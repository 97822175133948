import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Input, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { components } from '../../../api/schema';
import { editUserClient, getUserClient } from '../../../api/userClient';
import SearchClient from '../../../shared/client/SearchClient';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';
import useResetFormOnCloseModal from '../../../shared/hooks/useResetFormModalOnClose';

export const EditUserClient = ({ record }: DataTableActionProps<components['schemas']['UserClientCondensed']>) => {
  const [form] = Form.useForm<components['schemas']['EditUserClientRequest']>();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { data, remove } = useQuery({
    queryKey: ['getUserClient'],
    queryFn: () => getUserClient(record.id),
    enabled: record.id !== undefined && open,
  });
  const mutation = useMutation({
    mutationFn: (body: components['schemas']['EditUserClientRequest']) => editUserClient(record.id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsersClient'] });
      message.success('Usuario cliente editado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        clientId: data.client.id,
      });
    }
  }, [data]);

  useResetFormOnCloseModal({ form, open });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutation.mutate(values);
    } catch (e) {}
  };

  const handleSelectClient = (selectedClient: components['schemas']['ClientCondensed']) => {
    form.setFieldsValue({ clientId: selectedClient.id });
  };

  const handleCancel = () => {
    setOpen(false);
    remove();
  };

  return (
    <>
      <Modal
        open={open}
        title="Editar usuario"
        style={{ top: 20 }}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
            Guardar
          </Button>,
        ]}
      >
        {!data ? (
          <Spin size="large" />
        ) : (
          <Form form={form} layout="vertical" name="create-user-client-form" scrollToFirstError>
            <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="clientId" label="Cliente" rules={[{ required: true, message: 'Selecciona un cliente' }]}>
              <SearchClient
                onSelected={(selectedClient) => handleSelectClient(selectedClient)}
                preselectedClientId={data.client.id}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
      <EditOutlined onClick={() => setOpen(true)} />
    </>
  );
};
