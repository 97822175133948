import { useEffect } from 'react';

const useConditionalRefresh = (refreshInterval: number, refetchCallback: () => void): void => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        document.querySelectorAll('.ant-drawer-open').length === 0 &&
        document.querySelectorAll('.ant-modal-root').length === 0
      ) {
        refetchCallback(); // Refresh the data
      }
    }, refreshInterval);

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [refreshInterval, refetchCallback]);
};

export default useConditionalRefresh;
