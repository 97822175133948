import { CheckCircleOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Popconfirm } from 'antd';
import React, { useState } from 'react';
import { changeReadyToDeliver } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';

export default function ChangeStatusModal({
  record,
}: DataTableActionProps<components['schemas']['WarehouseCondensed']>) {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const mutation = useMutation({
    mutationFn: () => changeReadyToDeliver(record.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getWarehouseList'] });
      message.success('Estatus cambiado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });
  const handleConfirm = async () => {
    await mutation.mutateAsync();
  };

  // Check if the shipment status is AT_WAREHOUSE to allow the change status
  if (record.status !== 'En almacén') return <></>;

  return (
    <React.Fragment>
      <Popconfirm
        title="Listo para entrega"
        description={`El paquete ya está listo para ser subido a la camioneta y ser entregado?`}
        open={open}
        cancelText="Cancelar"
        okText="Listo"
        onConfirm={handleConfirm}
        onCancel={() => setOpen(false)}
      ></Popconfirm>
      <CheckCircleOutlined onClick={() => setOpen(true)} />
    </React.Fragment>
  );
}
