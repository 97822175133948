import { BarcodeOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Drawer, Form, Row, Spin } from 'antd';
import { useState } from 'react';
import { getShipingLabel } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';
import useResetFormOnCloseModal from '../../../shared/hooks/useResetFormModalOnClose';

export const LabelDrawer = ({ record }: DataTableActionProps<components['schemas']['PickupCondensed']>) => {
  const [form] = Form.useForm<components['schemas']['EditUserRequest']>();
  const [open, setOpen] = useState(false);

  const { data, remove } = useQuery({
    queryKey: ['getShipingLabel'],
    queryFn: () => getShipingLabel(record.shipmentId),
    enabled: record.shipmentId !== undefined && open,
  });

  useResetFormOnCloseModal({ form, open });

  const handleCancel = () => {
    setOpen(false);
    remove();
  };

  return (
    <>
      <Drawer
        open={open}
        title="Vista etiqueta de envío"
        width={'60%'}
        destroyOnClose
        maskClosable={false}
        closable={false}
        extra={[
          <Button key="back" type="primary" onClick={handleCancel}>
            Cerrar
          </Button>,
        ]}
      >
        {!data ? (
          <Row justify="center">
            <Spin />
          </Row>
        ) : (
          <>
            <iframe
              title="pdf Preview"
              src={`data:application/pdf;base64,${data.labelBase64}`}
              height="100%"
              width="100%"
            />
          </>
        )}
      </Drawer>
      <BarcodeOutlined onClick={() => setOpen(true)} />
    </>
  );
};
