import { EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, Col, Divider, Form, Input, Modal, Radio, Row, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { editClient, getClient, getDefaultsPubGeneral } from '../../../api';
import { components } from '../../../api/schema';
import { DataTableActionProps } from '../../../shared/dataTable/DataTable';
import ReadonlyInput from '../../../shared/form/ReadonlyInput';
import useResetFormOnCloseModal from '../../../shared/hooks/useResetFormModalOnClose';
import SearchCFDIUse from '../../../shared/sat/SearchCfdiUse';
import SearchPaymentMethod from '../../../shared/sat/SearchPaymentMethod';
import SearchRegimenFiscal from '../../../shared/sat/SearchRegimenFiscal';
import SelectZipCode from '../../../shared/zipCode/SelectZipCode';
import AddressInMapDrawer from '../AddressInMapDrawer';
import SearchFormaPago from '../../../shared/sat/SearchFormaPago';

export const EditClient = ({ record }: DataTableActionProps<components['schemas']['ClientCondensed']>) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [showAddressInMapDrawer, setShowAddressInMapDrawer] = useState<boolean>(false);
  const [selectedAddressKey, setSelectedAddressKey] = useState<number | undefined>();
  const [selectedCoords, setSelectedCoords] = useState<[number, number]>();
  const [selectedZipCodes, setSelectedZipCodes] = useState<
    Array<{ key: React.Key; zipCode: components['schemas']['ZipcodeCondensed'] }>
  >([]);
  const [coordinatesArray, setCoordinatesArray] = useState<Array<{ lat: number; lng: number }>>([]);
  const [originalAddresses, setOriginalAddresses] = useState<components['schemas']['EditClientRequest']['addresses']>(
    [],
  );
  const [originalContacts, setOriginalContacts] = useState<components['schemas']['EditClientRequest']['contacts']>([]);
  const [originalZipCodes, setOriginalZipCodes] = useState<string[]>([]);
  const [disabledByRFCPublico, setDisabledByRFCPublico] = useState<boolean>(false);
  const [disabledByPersonaFisica, setDisabledByPersonaFisica] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { data, remove } = useQuery({
    queryKey: ['getClient'],
    queryFn: () => getClient(record.id),
    enabled: record.id !== undefined && open,
  });

  const { data: data2 } = useQuery({
    queryKey: ['getDefaultsPubGeneral'],
    queryFn: () => getDefaultsPubGeneral(),
    enabled: record.id !== undefined && open,
  });

  const mutation = useMutation({
    mutationFn: (body: components['schemas']['EditClientRequest']) => editClient(record.id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getClients'] });
      message.success('Cliente editado correctamente');
    },
    onError: () => {
      message.error('Ocurrio un error, vuelve a intentarlo más tarde');
    },
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        phoneNumber: data.phoneNumber,
        emailAddress: data.emailAddress,
        officeHours: data.officeHours,
        credit: data.credit,
        rfc: data.rfc,
        cfdiUseId: data.cfdiUse.id,
        regimenFiscalId: data.regimenFiscal.id,
        paymentMethodId: data.paymentMethod.id,
        formaPagoId: data.formaPago.id,
        tipoPersona: data.tipoPersona,
        ivaRetention: data.ivaRetention,
        sendInvoice: data.sendInvoice,
        addresses: data.addresses,
        contacts: data.contacts,
      });

      const arrOriginalAddresses: components['schemas']['EditClientRequest']['addresses'] = [];
      const arrOriginalContacts: components['schemas']['EditClientRequest']['contacts'] = [];
      const arrCoordinatesArrayM = [...coordinatesArray];
      const arrOriginalZipCodes = [...originalZipCodes];

      data.addresses.forEach((address, index) => {
        arrOriginalAddresses[index] = {
          name: address.name,
          street: address.name,
          exteriorNumber: address.exteriorNumber,
          interiorNumber: address.interiorNumber,
          zipCodeId: address.zipCode?.id,
          coordinates: {
            lat: address.coords?.coordinates![1],
            lng: address.coords?.coordinates![0],
          },
          addressType: address.addressType,
          id: address.id,
          shouldDelete: false,
        };

        // Copy of array to allow mutation
        arrCoordinatesArrayM[index] = {
          lat: address.coords?.coordinates![1],
          lng: address.coords?.coordinates![0],
        };

        // Array to control zip codes through updates or deletes in UI
        arrOriginalZipCodes[index] = address.zipCode?.id;
      });

      data.contacts.forEach((contact, index) => {
        arrOriginalContacts[index] = {
          id: contact.id,
          name: contact.name,
          phoneNumber: contact.phoneNumber,
          shouldDelete: false,
        };
      });

      setOriginalAddresses(arrOriginalAddresses);
      setCoordinatesArray(arrCoordinatesArrayM);
      setOriginalContacts(arrOriginalContacts);
      setOriginalZipCodes(arrOriginalZipCodes);
    }
  }, [data]);
  useResetFormOnCloseModal({ form, open });

  /**
   * This method uses an object containing data from a form. The object is generated by Ant Design (antd) and is referred to as 'values'.
   * It iterates over the 'addresses' array within the object to assign the corresponding coordinates to each address.
   * There is a copy of the 'originalAddresses' array, which contains the addresses with which the client is initially created.
   * Modifications to this array are made based on the values from the 'values' object provided by antd.
   * It checks if the address already exists in the array and performs the mapping if it does, otherwise, it assigns a new record.
   * After mapping the addresses, it proceeds to map the contacts.
   * Finally, when the values from the 'values' object are correctly mapped, a 'requestObject' is created and sent to be saved in the database.
   */

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const arrFinalAddresses = [...originalAddresses];

      const arrFinalContacts = [...originalContacts];

      if (values.addresses?.length > 0) {
        values.addresses.forEach((address: any, index: number) => {
          // Search matches among original addresses and values from the form
          const indexArr = arrFinalAddresses.findIndex((addressArr) => addressArr.id === address.id);
          if (indexArr !== -1) {
            arrFinalAddresses[indexArr].name = address.name;
            arrFinalAddresses[indexArr].street = address.street;
            arrFinalAddresses[indexArr].exteriorNumber = address.exteriorNumber;
            arrFinalAddresses[indexArr].interiorNumber = address.interiorNumber;
            arrFinalAddresses[indexArr].zipCodeId = address.zipCodeId;
            if (!arrFinalAddresses[indexArr].shouldDelete) {
              arrFinalAddresses[indexArr].coordinates = {
                lat: coordinatesArray[index].lat ? coordinatesArray[index].lat : undefined,
                lng: coordinatesArray[index].lng ? coordinatesArray[index].lng : undefined,
              };
            }
            arrFinalAddresses[indexArr].addressType = address.addressType;
            arrFinalAddresses[indexArr].id = address.id;
          }
          // No match
          else {
            if (address.id === undefined) {
              arrFinalAddresses.push({
                name: address.name,
                street: address.street,
                exteriorNumber: address.exteriorNumber,
                interiorNumber: address.interiorNumber,
                zipCodeId: address.zipCodeId,
                coordinates: {
                  lat: coordinatesArray[index]?.lat,
                  lng: coordinatesArray[index]?.lng,
                },
                addressType: address.addressType,
                id: address.id,
                shouldDelete: false,
              });
            }
          }
        });
      }
      if (values.contacts?.length > 0) {
        values.contacts.forEach((contact: any, index: number) => {
          const indexArr = arrFinalContacts.findIndex((contactArr) => contactArr.id === contact.id);
          if (indexArr !== -1) {
            arrFinalContacts[indexArr].id = contact.id;
            arrFinalContacts[indexArr].name = contact.name;
            arrFinalContacts[indexArr].phoneNumber = contact.phoneNumber;
          }
          // New Contact
          if (contact.id === undefined) {
            arrFinalContacts.push({
              id: contact.id,
              name: contact.name,
              phoneNumber: contact.phoneNumber,
              shouldDelete: false,
            });
          }
        });
      }

      const requestObject: components['schemas']['EditClientRequest'] = {
        name: values.name,

        rfc: values.rfc,
        officeHours: values.officeHours,
        phoneNumber: values.phoneNumber,
        emailAddress: values.emailAddress,
        credit: values.credit,
        tipoPersona: values.tipoPersona,
        addresses: [...arrFinalAddresses],
        contacts: [...arrFinalContacts],
        cfdiUseId: values.cfdiUseId,
        paymentMethodId: values.paymentMethodId,
        regimenFiscalId: values.regimenFiscalId,
        ivaRetention: values.ivaRetention,
        sendInvoice: values.sendInvoice,
        internalNotes: values.internalNotes,
        formaPagoId: values.formaPagoId,
      };
      mutation.mutate(requestObject);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const handleShowAddressInMapDrawer = (key: number) => {
    setSelectedAddressKey(key);
    setShowAddressInMapDrawer(!showAddressInMapDrawer);
    let coords: [number, number] | undefined;
    if (coordinatesArray[key]) {
      coords = [coordinatesArray[key].lat, coordinatesArray[key].lng];
    } else {
      coords = undefined;
    }
    setSelectedCoords(coords);
  };

  const handleSelectZipCode = (key: React.Key, zipCode: components['schemas']['ZipcodeCondensed']) => {
    setSelectedZipCodes((zipCodes) => {
      const tmpState = zipCodes.filter((zip) => zip.key !== key);
      return [...tmpState, { key, zipCode }];
    });

    const arrZipCodes = [...originalZipCodes];
    arrZipCodes[key as number] = zipCode.id;
  };

  const handleSendObject = (p: { id: number; latLng: { lat: number; lng: number } }) => {
    const arr = [...coordinatesArray];
    if (arr[p.id] !== undefined) {
      arr[p.id] = p.latLng;
      setCoordinatesArray([...arr]);
    } else {
      setCoordinatesArray([...coordinatesArray.slice(0, p.id), p.latLng, ...coordinatesArray.slice(p.id)]);
    }
  };

  // Remove address
  const removeItemInArray = (index: number) => {
    const arrAddress = [...originalAddresses];
    if (arrAddress[index]) {
      arrAddress[index].shouldDelete = true;
    }
    setOriginalAddresses([...arrAddress]);

    const arrCoordinates = [...coordinatesArray];

    for (let i: number = index; i < arrCoordinates.length; i++) {
      if (i === arrCoordinates.length - 1) {
        arrCoordinates[i] = { lat: 20.67617816749366, lng: -103.34739059437757 };
      } else {
        arrCoordinates[i] = arrCoordinates[i + 1];
      }
    }

    setCoordinatesArray(arrCoordinates);

    const arrZipCodes = [...originalZipCodes];
    if (arrZipCodes[index]) {
      arrZipCodes.splice(index, 1);
    }
  };

  // Remove contact
  const removeContactInArray = (index: number) => {
    const arr = [...originalContacts];
    if (arr[index]) {
      arr[index].shouldDelete = true;
    }
    setOriginalContacts([...arr]);
  };

  const handleCancel = () => {
    setOpen(false);
    remove();
  };

  const rfcWatch = Form.useWatch('rfc', form);
  const rfcString: string = rfcWatch ? (rfcWatch as string) : '';

  useEffect(() => {
    switch (rfcString.length) {
      case 12:
        form.setFieldValue('tipoPersona', 'MORAL');
        break;
      case 13:
        form.setFieldValue('tipoPersona', 'FISICA');
        break;
    }

    if (rfcString === 'XAXX010101000') {
      setDisabledByRFCPublico(true);
      form.setFieldValue('tipoPersona', 'FISICA');
      form.setFieldValue('cfdiUseId', data2?.cfdiUseId);
      form.setFieldValue('regimenFiscalId', data2?.regimenFiscalId);
      form.setFieldValue('paymentMethodId', data2?.paymentMethodId);
    } else {
      setDisabledByRFCPublico(false);
      form.setFieldValue('cfdiUseId', '');
      form.setFieldValue('regimenFiscalId', '');
      form.setFieldValue('paymentMethodId', '');
    }
  }, [rfcWatch]);

  const handleClickRFCPublico = () => {
    if (rfcString === 'XAXX010101000') {
      form.setFieldValue('rfc', '');
      form.validateFields(['rfc']);
    } else {
      form.setFieldValue('rfc', 'XAXX010101000');
      form.validateFields(['rfc']);
    }
  };

  const tipoPersonaWatch = Form.useWatch('tipoPersona', form);
  const tipoPersonaString: string = tipoPersonaWatch ? (tipoPersonaWatch as string) : '';

  useEffect(() => {
    switch (tipoPersonaString) {
      case 'FISICA':
        setDisabledByPersonaFisica(true);
        // setCheckedByPersonaFisica(false);
        form.setFieldValue('ivaRetention', false);

        break;
      case 'MORAL':
        setDisabledByPersonaFisica(false);
        // setCheckedByPersonaFisica(true);

        break;
    }
  }, [tipoPersonaWatch]);

  return (
    <>
      <Modal
        open={open}
        title="Editar cliente"
        style={{ top: 20 }}
        width={800}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setSelectedZipCodes([]);
              handleCancel();
            }}
          >
            Cancelar
          </Button>,
          <Button key="submit" htmlType="submit" type="primary" loading={mutation.isLoading} onClick={handleSubmit}>
            Guardar
          </Button>,
        ]}
      >
        {!data && data2 ? (
          <Spin size="large" />
        ) : (
          <Form form={form} layout="vertical" name="create-client-form" scrollToFirstError>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Ingresa el nombre' }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="comercialName" label="Nombre comercial">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  label="Teléfono"
                  rules={[{ required: true, message: 'Ingresa el teléfono' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Correo electrónico"
                  name="emailAddress"
                  rules={[
                    {
                      required: true,
                      message: 'Ingresa un correo electrónico',
                      type: 'email',
                    },
                  ]}
                >
                  <Input placeholder="ejemplo@correo.com" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item name="officeHours" label="Horario de oficina">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={2}>
                <Form.Item name="credit" label="Crédito" valuePropName="checked">
                  <Checkbox>Sí</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Divider>Fiscal</Divider>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="rfc"
                  label="RFC"
                  normalize={(value) => (value || '').toUpperCase()}
                  rules={[
                    {
                      required: true,
                      message: 'Ingresa un RFC válido',
                      pattern:
                        /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/i,
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Input />
                </Form.Item>
                <Button type="link" onClick={handleClickRFCPublico}>
                  RFC público en general.
                </Button>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="cfdiUseId"
                  label="Uso de CFDI"
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona un uso de CFDI',
                    },
                  ]}
                >
                  <SearchCFDIUse disabled={disabledByRFCPublico} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="regimenFiscalId"
                  label="Régimen Fiscal"
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona un régimen fiscal',
                    },
                  ]}
                >
                  <SearchRegimenFiscal disabled={disabledByRFCPublico} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="paymentMethodId"
                  label="Método de pago"
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona un método de pago',
                    },
                  ]}
                >
                  <SearchPaymentMethod disabled={disabledByRFCPublico} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="formaPagoId"
                  label="Forma de Pago"
                  rules={[
                    {
                      required: false,
                      message: 'Selecciona una forma de pago',
                    },
                  ]}
                >
                  <SearchFormaPago />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="tipoPersona"
                  label="Tipo de persona"
                  rules={[{ required: true, message: 'Selecciona el tipo de persona' }]}
                >
                  <Radio.Group disabled={disabledByRFCPublico}>
                    <Radio value="FISICA">Física</Radio>
                    <Radio value="MORAL">Moral</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="ivaRetention" label="Retener IVA" valuePropName="checked">
                  <Checkbox disabled={disabledByPersonaFisica}>Sí</Checkbox>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="sendInvoice" label="Envíar factura" valuePropName="checked">
                  <Checkbox>Sí</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Divider>Direcciones</Divider>
            <Row>
              <Col span={24}>
                {data?.addresses.length ? (
                  <Form.List name="addresses">
                    {(fields, { remove }) => {
                      return (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <div key={key}>
                              <Row gutter={16} align="middle">
                                <Col span={23}>
                                  <Row gutter={16}>
                                    <Col span={8}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'addressType']}
                                        label="Tipo de dirección"
                                        rules={[{ required: true, message: 'Selecciona el tipo de dirección' }]}
                                      >
                                        <Select
                                          disabled
                                          style={{ width: '100%' }}
                                          options={[
                                            { value: 'PICKUP', label: 'Recolección' },
                                            { value: 'DELIVERY', label: 'Entrega' },
                                            { value: 'DELIVERY_AND_PICKUP', label: 'Recolección y entrega' },
                                            { value: 'FISCAL ', label: 'Fiscal' },
                                            { value: 'PICKUP_AND_FISCAL', label: 'Recolección y fiscal' },
                                          ]}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        name={[name, 'zipCodeId']}
                                        label="Código postal"
                                        // rules={[{ required: true, message: 'Selecciona un código postal' }]}
                                      >
                                        <SelectZipCode
                                          disabled={true}
                                          preselectedZipCodeId={originalZipCodes[key]}
                                          onSelected={(selectedZipCode) => handleSelectZipCode(key, selectedZipCode)}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item {...restField} name={[name, 'rfc']} label="RFC">
                                        <Input disabled />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        label="Nombre de la dirección"
                                        rules={[{ required: true, message: 'Ingresa el nombre de la dirección' }]}
                                      >
                                        <Input placeholder="Casa..." disabled />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item {...restField} name={[name, 'reference']} label="Referencia">
                                        <Input disabled />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={6}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'street']}
                                        label="Calle"
                                        rules={[{ required: true, message: 'Ingresa el nombre de la calle' }]}
                                        style={{ marginBottom: 0 }}
                                      >
                                        <Input disabled />
                                      </Form.Item>
                                      <Form.Item {...restField} name={[name, 'id']} hidden>
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        {...restField}
                                        rules={[{ required: true, message: 'Ingresa el número exterior' }]}
                                        name={[name, 'exteriorNumber']}
                                        label="Número exterior"
                                      >
                                        <Input disabled />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item {...restField} name={[name, 'interiorNumber']} label="Número interior">
                                        <Input disabled />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item {...restField} name={[name, 'betweenStreets']} label="Entre calles">
                                        <Input disabled />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16} style={{ marginBottom: 20 }}>
                                    <Col span={6}>
                                      <ReadonlyInput
                                        value={
                                          selectedZipCodes.find((zipCode) => zipCode.key === key)?.zipCode.district ??
                                          ''
                                        }
                                        label="Colonia"
                                        removeBoldLabel
                                      />
                                    </Col>
                                    <Col span={6}>
                                      <ReadonlyInput
                                        value={
                                          selectedZipCodes.find((zipCode) => zipCode.key === key)?.zipCode.city ?? ''
                                        }
                                        label="Ciudad"
                                        removeBoldLabel
                                      />
                                    </Col>
                                    <Col span={6}>
                                      <ReadonlyInput
                                        value={
                                          selectedZipCodes.find((zipCode) => zipCode.key === key)?.zipCode.municipio ??
                                          ''
                                        }
                                        label="Municipio"
                                        removeBoldLabel
                                      />
                                    </Col>
                                    <Col span={6}>
                                      <ReadonlyInput
                                        value={
                                          selectedZipCodes.find((zipCode) => zipCode.key === key)?.zipCode.state ?? ''
                                        }
                                        label="Estado"
                                        removeBoldLabel
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={1}>
                                  {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                      onClick={() => {
                                        removeItemInArray(name);
                                        remove(name);
                                      }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </Col>
                              </Row>
                              <Divider />
                            </div>
                          ))}
                          {/* <Form.Item>
                          <Button
                            disabled={true}
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar dirección
                          </Button>
                        </Form.Item> */}
                        </>
                      );
                    }}
                  </Form.List>
                ) : (
                  <div>No se encontraron direcciones</div>
                )}
              </Col>
            </Row>
            <Divider>Contactos</Divider>
            <Row>
              <Col span={24}>
                {data?.contacts.length ? (
                  <Form.List name="contacts">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <div key={key}>
                            <Row gutter={16} align="middle">
                              <Col span={23}>
                                <Row gutter={16}>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'name']}
                                      label="Nombre"
                                      rules={[{ required: true, message: 'Ingresa el nombre' }]}
                                    >
                                      <Input disabled />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'phoneNumber']}
                                      label="Teléfono"
                                      rules={[{ required: true, message: 'Ingresa el teléfono' }]}
                                    >
                                      <Input disabled />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={1}>
                                {fields.length > 1 ? (
                                  <MinusCircleOutlined
                                    onClick={() => {
                                      remove(name);
                                      removeContactInArray(name);
                                    }}
                                  />
                                ) : (
                                  ''
                                )}
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <Form.Item>
                          <Button disabled type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Agregar contacto
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                ) : (
                  <div>No se encontraron contactos</div>
                )}
              </Col>
            </Row>
          </Form>
        )}
        <AddressInMapDrawer
          selectedAddressKey={selectedAddressKey}
          open={showAddressInMapDrawer}
          onCancel={() => setShowAddressInMapDrawer(false)}
          onSendObject={handleSendObject}
          centerCoords={selectedCoords}
        />
      </Modal>
      <EditOutlined onClick={() => setOpen(true)} />
    </>
  );
};
