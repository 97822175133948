import { LoadingOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Input, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { getClient, getClients } from '../../api';
import { components, paths } from '../../api/schema';
import useDebounce from '../hooks/useDebounce';

const renderItem = (client: components['schemas']['ClientCondensed']) => ({
  key: `${client.id}`,
  value: client.id,
  client,
  label: (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {client.name}
      <span>
        {client.rfc} / {client.tipoPersona}
      </span>
    </div>
  ),
});

interface SelectClientProps {
  value?: string;
  onChange?: (value: string) => void;
  onSelected: (client: components['schemas']['ClientCondensed']) => void;
  preselectedClientId?: string;
}
const PAGE_SIZE = 30;
export default function SearchClient({ onChange, onSelected, preselectedClientId }: SelectClientProps) {
  const [options, setOptions] = useState<
    Array<{ value: string; label: JSX.Element; client: components['schemas']['ClientCondensed'] }>
  >([]);
  const [search, setSearch] = useState<string>('');
  const [inputVal, setInputVal] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const debouncedSearch = useDebounce<string>(search, 300);
  const [params, setParams] = useState<paths['/client/list']['get']['parameters']['query']>({
    search: undefined,
    limit: PAGE_SIZE,
    page: 1,
    showDisabled: false,
    order: JSON.stringify([]),
    filter: JSON.stringify([]),
  });
  const { data: client, isFetching: isFetchingSingleClient } = useQuery({
    queryKey: ['getClient', preselectedClientId],
    queryFn: () => getClient(preselectedClientId),
    enabled: preselectedClientId !== undefined,
  });
  const { data, isFetching } = useQuery({
    queryKey: ['getClients', params],
    queryFn: () => getClients(params),
    enabled: debouncedSearch.length > 0,
  });

  useEffect(() => {
    if (data?.results.length) {
      setOptions(data.results.map((client) => renderItem(client)));
    } else {
      setOptions([]);
    }
    setOpen(true);
  }, [data]);

  useEffect(() => {
    if (preselectedClientId && client) {
      setInputVal(client.name);
      onChange?.(client.id);
      onSelected({
        id: client.id,
        name: client.name,
        rfc: client.rfc,
        officeHours: client.officeHours,
        phoneNumber: client.phoneNumber,
        emailAddress: client.emailAddress ?? '',
        credit: client.credit ? 'Crédito' : 'Contado',
        tipoPersona: client.tipoPersona === 'FISICA' ? 'Física' : 'Moral',
        hasActivePrepaidShipment: client.hasActivePrepaidShipment,
        enabled: client.enabled,
      });
    }
  }, [client, preselectedClientId]);

  useEffect(() => {
    if (debouncedSearch.length) {
      setParams((state) => ({ ...state, search: debouncedSearch }));
      setOpen(false);
    } else {
      setOpen(false);
      setOptions([]);
    }
  }, [debouncedSearch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setInputVal(e.target.value);
  };

  const handleSelected = (client: components['schemas']['ClientCondensed']) => {
    setInputVal(client.name);
    onSelected(client);
    onChange?.(client.id);
    setOpen(false);
  };

  const content = (
    <div style={{ width: 350, maxHeight: 350, overflowY: 'auto' }}>
      {options.map((option, k) => (
        <div className="custom-popover-item" key={k} onClick={() => handleSelected(option.client)}>
          {option.label}
        </div>
      ))}
    </div>
  );
  return (
    <Popover content={content} open={options.length > 0 && open} arrow={false}>
      <Input
        style={{ width: '100%' }}
        placeholder="Buscar cliente"
        onChange={handleInputChange}
        value={inputVal}
        suffix={isFetching || isFetchingSingleClient ? <LoadingOutlined /> : <span />}
      />
    </Popover>
  );
}
